import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { isEmpty } from 'lodash'

import { ReactComponent as ImagePdf } from 'assets/images/pdf-empty.svg'

import useActionSteps from 'utils/hooks/useActionSteps'
import { getFileLink } from 'utils/helpers/file'
import { getPercentage } from 'utils/helpers'

import memberStyles from 'pages/Member/styles.module.scss'
import Card from 'components/Card'
import PdfViewer from 'components/PdfViewer'

import ProgressCard from 'components/ProgressCard'
import { actionSteps } from '../data'
import { usePersonality } from './hooks'
import { DEFAULT_STEPS } from './constants'

const PLP = () => {
  const { data, editSteps } = usePersonality()
  useActionSteps(actionSteps)

  const [mutableSteps, setMutableSteps] = useState([])

  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const hasFile = data && data.file

  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
    editSteps({ steps: sortedSteps })
  }

  useEffect(() => {
    const steps =
      data && !isEmpty(data.steps) ? data.steps : DEFAULT_STEPS

    setMutableSteps(steps)
  }, [data])

  return (
    <div>
      <Row className="mb-40">
        <Col
          xs={12}
          md={6}
          className={`${memberStyles.courseProgress}`}
        >
          <ProgressCard
            steps={mutableSteps}
            progress={progress}
            onChange={stepOnChange}
          />
        </Col>
        <Col xs={12} md={6}>
          <Card outlined className="h-100">
            This is a personality test which measures 42
            dimensions of personality, predicts a 360, identifies
            personality traits and patterns which impact
            management and leadership, and provides management
            and leadership advice.
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className={memberStyles.sectionTitle}>
            Personality and Leadership Profile
          </p>
          {hasFile ? (
            <PdfViewer src={getFileLink(data.file)} />
          ) : (
            <Card
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ minHeight: 350 }}
            >
              <ImagePdf className="mb-20" />
              <div
                style={{ maxWidth: 350 }}
                className="text-gray-dark text-center"
              >
                Once your coach has sent through your PLP
                results. You will find it here.
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  )
}

PLP.propTypes = {}

export default PLP
