import React from 'react'

import { GRADIENTS } from './constants'

const Gradients = () => {
  const { BLUE, ORANGE, GREEN, PURPLE, YELLOW } = GRADIENTS
  return (
    <svg style={{ maxHeight: 0 }}>
      <defs>
        <linearGradient
          id="blue"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={BLUE[0]} />
          <stop offset="100%" stopColor={BLUE[1]} />
        </linearGradient>
        <linearGradient
          id="orange"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={ORANGE[0]} />
          <stop offset="100%" stopColor={ORANGE[1]} />
        </linearGradient>
        <linearGradient
          id="green"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={GREEN[0]} />
          <stop offset="100%" stopColor={GREEN[1]} />
        </linearGradient>
        <linearGradient
          id="purple"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={PURPLE[0]} />
          <stop offset="100%" stopColor={PURPLE[1]} />
        </linearGradient>
        <linearGradient
          id="yellow"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={YELLOW[0]} />
          <stop offset="100%" stopColor={YELLOW[1]} />
        </linearGradient>
      </defs>
    </svg>
  )
}

Gradients.propTypes = {}

export default Gradients
