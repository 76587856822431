import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'

import { $grayDark, $secondary } from 'utils/helpers/colors'

const CircularProgress = ({ value, ...rest }) => {
  return (
    <CircularProgressbar
      styles={{
        path: {
          stroke: $secondary
        },
        text: {
          fontWeight: 500,
          fontSize: '24px',
          fill: Number(value) ? $secondary : $grayDark,
          opacity: Number(value) ? 1 : 0.5
        }
      }}
      value={value}
      {...rest}
    />
  )
}

export default CircularProgress
