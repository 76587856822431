import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import {
  getUserPillarApi,
  updatePillarApi,
  updatePillarPdfApi
} from './api'
import { GET_USER_PILLAR } from './constants'

export const usePillar = userId => {
  const { data: userPillar, refetch: getUserPillar } = useQuery(
    GET_USER_PILLAR,
    () => getUserPillarApi(userId),
    {
      enabled: false
    }
  )

  const {
    mutate: updatePillarPdf,
    isLoading: updatePillarPdfLoading
  } = useMutation(updatePillarPdfApi, {
    onSuccess: () =>
      queryClient.invalidateQueries(GET_USER_PILLAR, {
        refetchInactive: true
      })
  })

  const { mutate: updatePillar } = useMutation(updatePillarApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_USER_PILLAR, {
        refetchInactive: true
      })
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  return {
    userPillar,
    getUserPillar,

    updatePillarPdf,
    updatePillarPdfLoading,

    updatePillar
  }
}
