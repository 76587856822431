import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import FormControl from 'components/Form/FormControl'

import tableStyles from 'global/table.module.scss'

const CollapseHeader = ({
  title,
  date,
  inputErrors,
  inputRegister
}) => {
  return (
    <>
      <div className="d-flex flex-column flex-md-row w-100">
        <Col
          xs={12}
          md={9}
          className="font-weight-medium p-0 pr-md-20 mb-10 mb-md-0"
        >
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.title}
            as="textarea"
            rows={1}
            name="title"
            type="text"
            formGroupClass="mb-0"
            defaultValue={title}
            placeholder="Session title"
          />
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex justify-content-end align-items-center p-0"
        >
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.date}
            name="date"
            as="datepicker"
            formGroupClass={`mb-0 ${tableStyles.date}`}
            icon={<IconCalendar />}
            value={date}
            placeholder="Session date"
          />
        </Col>
      </div>
    </>
  )
}

CollapseHeader.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string
}

export default CollapseHeader
