import React from 'react'
import RcSelect from 'react-select'

import { $primary } from 'utils/helpers/colors'

import styles from './styles.module.scss'

const Select = ({
  disabled,
  loading,
  options,
  clearable,
  dropdown,
  name,
  searchable = false,
  filterValue,
  ...rest
}) => {
  const classes = [styles.select]
  if (dropdown) classes.push(styles.dropdown)

  return (
    <RcSelect
      isSearchable={searchable}
      className={classes.join(' ')}
      classNamePrefix="select"
      defaultValue={options.filter(
        option => option.value === filterValue
      )}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={clearable}
      name={name}
      options={options}
      components={{
        IndicatorSeparator: () => null
      }}
      styles={{
        control: styles => ({
          ...styles,
          ...(dropdown
            ? {
                background: 'transparent',
                border: 'none'
              }
            : {})
        }),
        container: (styles, ...rest) => {
          const {
            selectProps: { width }
          } = rest[0]

          return {
            ...styles,
            minWidth: 150,
            width
          }
        },
        option: (styles, { isSelected, isFocused }) => ({
          ...styles,
          ...(isFocused || isSelected
            ? {
                backgroundColor: '#e9ecef',
                color: 'black'
              }
            : {}),
          ':active': {
            ...styles[':active'],
            backgroundColor: '#e9ecef'
          }
        }),
        singleValue: styles => ({
          ...styles,
          color: $primary,
          fontWeight: 600
        })
      }}
      {...rest}
    />
  )
}

export default Select
