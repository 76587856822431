import React from 'react'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import { useUserWorkshop } from 'global/hooks/useUserWorkshop'

import WorkshopCard from 'components/WorkshopCard'
import DashboardWrapper from 'components/DashboardWrapper'

const Workshops = () => {
  useHeaderTitle('Workshops')
  const { workshopData } = useUserWorkshop()

  return (
    <DashboardWrapper>
      {workshopData?.map(
        ({
          id,
          title,
          date,
          Company,
          agenda,
          notes,
          files,
          UserWorkshops
        }) => {
          const company = Company.name
          const attendees = Company.Users.map(user => ({
            image: user.profileImage,
            name: `${user.firstName} ${user.lastName}`
          }))
          return (
            <WorkshopCard
              workshopId={id}
              title={title}
              date={date}
              company={company}
              agendas={agenda}
              notes={notes}
              actionSteps={UserWorkshops[0].todoStatus}
              attendees={attendees}
              files={files}
            />
          )
        }
      )}
    </DashboardWrapper>
  )
}

Workshops.propTypes = {}

export default Workshops
