import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import {
  addStakeholderApi,
  deleteStakeholderApi,
  editStakeholderApi,
  editStepsApi,
  getStakeholderEngagement
} from './api'
import { GET_STAKEHOLDER_ENGAGEMENT } from './constants'

export const useStakeholderEngagement = () => {
  const [message, setMessage] = useState('')

  const { data, isLoading, isSuccess, error } = useQuery(
    GET_STAKEHOLDER_ENGAGEMENT,
    getStakeholderEngagement
  )

  const { mutate: editSteps } = useMutation(editStepsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_STAKEHOLDER_ENGAGEMENT)
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  const {
    mutate: addStakeholder,
    isLoading: addStakeholderLoading,
    isSuccess: addStakeholderSuccess,
    isError: addStakeholderError
  } = useMutation(addStakeholderApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_STAKEHOLDER_ENGAGEMENT)
      setMessage('')
    },
    onError: error => {
      setMessage(error.message)
    }
  })

  const {
    mutate: editStakeholder,
    isLoading: editStakeholderLoading,
    isSuccess: editStakeholderSuccess,
    isError: editStakeholderError
  } = useMutation(editStakeholderApi, {
    onSuccess: () => {
      setMessage('')
      queryClient.invalidateQueries(GET_STAKEHOLDER_ENGAGEMENT)
    },
    onError: error => {
      setMessage(error.message)
    }
  })

  const { mutate: deleteStakeholder } = useMutation(
    deleteStakeholderApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_STAKEHOLDER_ENGAGEMENT)
      }
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,

    editSteps,

    addStakeholder,
    addStakeholderLoading,
    addStakeholderSuccess,
    addStakeholderError,

    editStakeholder,
    editStakeholderLoading,
    editStakeholderError,
    editStakeholderSuccess,

    deleteStakeholder,

    message,
    setMessage
  }
}
