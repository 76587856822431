import React from 'react'

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import Button from 'components/Button'

import styles from './styles.module.scss'

const DropdownButton = ({
  disabled,
  isOpen,
  onClick,
  className
}) => {
  const classes = [`${styles.dropdownIcon}`]
  if (className) classes.push(className)
  if (isOpen) classes.push(`${styles.isOpen}`)

  return (
    <Button
      variant="default"
      disabled={disabled}
      onClick={onClick}
      className={classes.join(' ')}
    >
      <DropdownIcon />
    </Button>
  )
}

export default DropdownButton
