import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import Card from 'components/Card'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'
import useMutationSuccess from 'utils/hooks/useMutationSuccess'
import useMutationError from 'utils/hooks/useMutationError'
import { resolver } from './changePassSchema'
import { useProfile } from './hooks'

const ChangePassword = () => {
  const [isDirty, setIsDirty] = useState(false)
  const {
    editProfile,
    editProfileSuccess,
    editProfileLoading,
    editProfileError,
    setEditProfileError
  } = useProfile()
  const [SuccessToaster] = useMutationSuccess(editProfileSuccess)
  const [ErrorToaster] = useMutationError({
    error: editProfileError,
    callback: () => {
      setEditProfileError(false)
    }
  })
  const {
    register,
    formState,
    handleSubmit,
    errors,
    reset
  } = useForm({
    resolver,
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  function formSubmit({ newPassword, oldPassword }) {
    if (!isDirty) return null
    const payload = {
      password: newPassword,
      currentPassword: oldPassword
    }

    return editProfile({ payload })
  }

  useEffect(() => {
    if (!isDirty) setIsDirty(formState.isDirty)
  }, [formState, isDirty])

  useEffect(() => {
    if (editProfileSuccess) reset()
  }, [editProfileSuccess, reset])

  return (
    <>
      <div>
        <Card>
          <Form onSubmit={handleSubmit(formSubmit)}>
            <fieldset disabled={editProfileLoading}>
              <Col className="p-0 px-lg-20" xs={12} lg={6}>
                <Row className="d-flex align-items-center mb-10">
                  <Col
                    xs={12}
                    lg={4}
                    className="text-gray-dark mb-5 mb-lg-0"
                  >
                    Old Password
                  </Col>
                  <Col xs={12} lg={8}>
                    <FormControl
                      type="password"
                      formGroupClass="mb-0"
                      name="oldPassword"
                      placeholder="Old Password"
                      inputRef={register}
                      error={errors.oldPassword}
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mb-10">
                  <Col
                    xs={12}
                    lg={4}
                    className="text-gray-dark mb-5 mb-lg-0"
                  >
                    New Password
                  </Col>
                  <Col xs={12} lg={8}>
                    <FormControl
                      type="password"
                      formGroupClass="mb-0"
                      placeholder="New Password"
                      name="newPassword"
                      inputRef={register}
                      error={errors.newPassword}
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mb-20">
                  <Col
                    xs={12}
                    lg={4}
                    className="text-gray-dark mb-5 mb-lg-0"
                  >
                    Confirm Password
                  </Col>
                  <Col xs={12} lg={8}>
                    <FormControl
                      type="password"
                      formGroupClass="mb-0"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      inputRef={register}
                      error={errors.confirmPassword}
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mb-20">
                  <Col xs={12} lg={4} />
                  <Col xs={12} lg={8}>
                    <Button
                      smallPadding
                      variant="secondary"
                      type="submit"
                      disabled={!isDirty || editProfileLoading}
                    >
                      Sav{editProfileLoading ? 'ing' : 'e'}{' '}
                      Changes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </fieldset>
          </Form>
        </Card>
      </div>
      <SuccessToaster message="Password has been changed!" />
      <ErrorToaster message={editProfileError} />
    </>
  )
}

ChangePassword.propTypes = {}

export default ChangePassword
