import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const H1 = ({ children, className, ...rest }) => {
  return (
    <h1 className={`${styles.h1} ${className || ''}`} {...rest}>
      {children}
    </h1>
  )
}

H1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default H1
