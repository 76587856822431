import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import FormControl from 'components/Form/FormControl'

const CollapseHeader = ({
  title,
  companyId,
  inputErrors,
  inputRegister,
  companies,
  date
}) => {
  return (
    <>
      <div className="d-flex flex-column flex-md-row w-100">
        <Col
          xs={12}
          md={6}
          className="font-weight-medium p-0 pr-md-20 mb-10 mb-md-0"
        >
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.title}
            name="title"
            type="text"
            as="textarea"
            rows={1}
            formGroupClass="mb-0"
            defaultValue={title}
            placeholder="Session title"
          />
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex justify-content-end "
        >
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.company}
            name="company"
            as="select"
            formGroupClass="mb-0"
            defaultValue={companyId}
          >
            <option value={-1}>Select Company</option>
            {companies?.map(company => (
              <option value={company.id}>{company.name}</option>
            ))}
          </FormControl>
        </Col>
        <Col xs={3} className="font-weight-medium pr-0">
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.date}
            name="date"
            as="datepicker"
            formGroupClass="mb-0"
            icon={<IconCalendar />}
            placeholder="Session date"
            value={date}
          />
        </Col>
      </div>
    </>
  )
}

CollapseHeader.propTypes = {
  title: PropTypes.string
}

export default CollapseHeader
