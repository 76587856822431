import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { getLocationPathname } from 'utils/html/window'

import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'

const SimpleTopNavbar = ({ routes, data, targetIndex }) => {
  const history = useHistory()
  const currentLocation = getLocationPathname()

  const changeRoute = to => history.push(to)

  const onMouseOver = () => {}
  return (
    <Col
      xs={12}
      md={7}
      className={[
        styles.simpleTopNavbarWrapper,
        styles.overflow
      ].join(' ')}
    >
      <div className={`${styles.simpleTopNavbar}`}>
        {routes.map((route, routeIndex) => {
          const isActive = currentLocation === route.to
          const titleClasses = [`${styles.navTitle}`]
          if (isActive) titleClasses.push(`${styles.active}`)

          return (
            <TouchableOpacity
              key={route.to}
              className={titleClasses.join(' ')}
              onClick={() => changeRoute(route.to)}
              data={data}
              onMouseOver={
                targetIndex >= 0 &&
                routeIndex === targetIndex &&
                data &&
                data.length
                  ? onMouseOver
                  : null
              }
            >
              {route.title}
            </TouchableOpacity>
          )
        })}
      </div>
    </Col>
  )
}

SimpleTopNavbar.propTypes = {
  routes: PropTypes.array.isRequired
}

export default SimpleTopNavbar
