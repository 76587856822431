import React, { useState } from 'react'
import { orderBy } from 'lodash'

import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import DashboardWrapper from 'components/DashboardWrapper'
import Modal from 'components/Modal'
import AddButton from 'components/Button/AddButton'
import ConfirmModal from 'components/ConfirmModal'

import { Spinner } from 'react-bootstrap'
import memberStyles from '../styles.module.scss'

import styles from './styles.module.scss'
import SessionCard from './SessionCard'
import { useCoachingDocuments, useSession } from './hooks'

import AddSessionCard from './SessionCard/Add'
import CoachingDocuments from './CoachingDocuments'
import AddLink from './CoachingDocuments/AddLink'
import EditLink from './CoachingDocuments/EditLink'

const CoachingSessions = () => {
  useHeaderTitle('Coaching Session')
  const [isAdding, setIsAdding] = useState(false)
  const [showAddLinkModal, setShowAddLinkModal] = useState(false)
  const [showEditLinkModal, setShowEditLinkModal] = useState(
    false
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [selectedItemVal, setSelectedItemVal] = useState(null)

  const { isAtLeastSmallScreen } = useDeviceSize()
  const {
    data: sessionData,
    isLoading,
    createSession,
    editSession,
    deleteSession
  } = useSession()
  const {
    data: coachingDocsData,
    Loading,
    createCoachingDoc,
    editCoachingDoc,
    deleteCoachingDoc
  } = useCoachingDocuments()

  const handleCreateSession = payload => {
    createSession({ payload })
  }

  const handleEditSession = (id, payload) => {
    editSession({ id, payload })
  }

  const handleDeleteSession = id => {
    deleteSession({ id })
  }

  const handleCreateCoachingDoc = payload => {
    createCoachingDoc({ payload })
  }

  const handleEditCoachingDoc = (id, payload) => {
    editCoachingDoc({ id, payload })
  }

  const handleDeleteCoachingDoc = () => {
    deleteCoachingDoc({ id: selectedItemVal.id })
  }

  const toggleAddLinkModal = () => {
    setShowAddLinkModal(show => !show)
  }

  const toggleDeleteModal = async currItemValue => {
    if (currItemValue) await setSelectedItemVal(currItemValue)
    setShowDeleteModal(show => !show)
  }

  const toggleEditLinkModal = async currItemValue => {
    if (currItemValue) await setSelectedItemVal(currItemValue)
    setShowEditLinkModal(show => !show)
  }
  const orderBySessionData = orderBy(sessionData, 'date', 'desc')

  return (
    <>
      <DashboardWrapper>
        <div className={styles.coachingSession}>
          <div className="d-flex aling-items-center justify-content-between mb-10">
            <p className={`${memberStyles.sectionTitle} mb-0`}>
              Coaching Documents
            </p>
            <AddButton
              variant="outline-secondary"
              onClick={toggleAddLinkModal}
              label="Link"
            />
          </div>

          <CoachingDocuments
            data={coachingDocsData}
            isLoading={Loading}
            onEdit={toggleEditLinkModal}
            onDelete={toggleDeleteModal}
          />
          <div className={`${memberStyles.titleWrapper} mb-10`}>
            <p className={`${memberStyles.sectionTitle} mb-0`}>
              All Sessions
            </p>

            <AddButton
              label="Session"
              disabled={isAdding}
              onClick={() => setIsAdding(true)}
            />
          </div>
          {isAdding && isAtLeastSmallScreen ? (
            <div className="mb-10">
              <AddSessionCard
                handleCreateSession={handleCreateSession}
                addToggle={() => setIsAdding(!isAdding)}
              />
            </div>
          ) : (
            <Modal
              show={isAdding}
              closeModal={() => setIsAdding(false)}
              mimicNative
              title="Add Session"
            >
              <AddSessionCard
                handleCreateSession={handleCreateSession}
                addToggle={() => setIsAdding(!isAdding)}
              />
            </Modal>
          )}
          {!isLoading ? (
            orderBySessionData ? (
              orderBySessionData?.map(session => (
                <div key={session.id}>
                  <SessionCard
                    session={session}
                    handleEditSession={handleEditSession}
                    handleDeleteSession={handleDeleteSession}
                  />
                </div>
              ))
            ) : (
              <div>No Session</div>
            )
          ) : (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </DashboardWrapper>

      <AddLink
        onSubmit={handleCreateCoachingDoc}
        show={showAddLinkModal}
        closeModal={toggleAddLinkModal}
      />

      <EditLink
        onSubmit={handleEditCoachingDoc}
        show={showEditLinkModal}
        closeModal={() => toggleEditLinkModal()}
        initialValues={selectedItemVal}
      />

      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDeleteModal}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDeleteCoachingDoc}
        toggleModal={() => toggleDeleteModal()}
        title="Are you sure you want to delete this link?"
        // description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. "
      />
    </>
  )
}

CoachingSessions.propTypes = {}

export default CoachingSessions
