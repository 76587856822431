import { useEffect } from 'react'

import { useGlobalContext } from 'global/Context'

const useActionSteps = steps => {
  const { actionSteps, setActionSteps } = useGlobalContext()

  useEffect(() => {
    if (steps) setActionSteps(steps)
    return () => setActionSteps(null)
  }, [setActionSteps, steps])

  return [actionSteps]
}

export default useActionSteps
