import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ReactComponent as GiveAccessIcon } from 'assets/icons/give-access-icon.svg'
import { ReactComponent as ContextMenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'
import { ReactComponent as RemoveAccessIcon } from 'assets/icons/remove-access-icon.svg'

import { formatDate } from 'utils/helpers/date'
import { getUserManagementCourseComponentLink } from 'utils/helpers/course-component'
import { getFileLink } from 'utils/helpers/file'

import { useCompany } from 'global/hooks/useCompany'

import Filters from 'components/Filters'
import Table from 'components/Table'
import Dropdown from 'components/Dropdown'
import TouchableOpacity from 'components/TouchableOpacity'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import Toast from 'components/Toast'
import {
  AccessRenderer,
  NameWithThumb,
  StageRenderer
} from 'components/TableRenderers'

import InvitationModal from './InvitationModal'
import EditUserModal from './EditUserModal'
import styles from './styles.module.scss'

const Clients = ({
  data,
  editUserCompany,
  giveUserAccess,
  removeUserAccess,
  inviteUser,
  inviteUserError,
  setInviteUserError,
  deleteUser
}) => {
  const [currUser, setCurrUser] = useState(null)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showAccessModal, setShowAccessModal] = useState(false)
  const [filterValues, setFilterValues] = useState({
    company: 'all',
    clientStatus: 'all'
  })

  const history = useHistory()
  const { companies } = useCompany()

  const selectedUserHasAccess = currUser?.hasAccess
  const filterCompanies =
    companies?.map(company => ({
      value: company.id,
      label: company.name
    })) || []
  const filteredData = data
    ?.filter(client => {
      if (filterValues.company !== 'all') {
        return client?.Company?.id === filterValues?.company
      }
      return true
    })
    .filter(client => {
      if (filterValues.clientStatus !== 'all') {
        return client.hasAccess === filterValues.clientStatus
      }
      return true
    })

  const toggleModal = setter => {
    return () => setter(show => !show)
  }

  const handleInvite = payload => {
    inviteUser({ ...payload, isCoach: false })
  }

  const handleEdit = payload => {
    editUserCompany({ userId: currUser.id, payload })
  }

  const handleAccessToggle = () => {
    if (selectedUserHasAccess) {
      return removeUserAccess(currUser.id)
    }

    return giveUserAccess(currUser.id)
  }

  const handleStageClick = async user => {
    const { id, stage } = user
    const stageLink = await getUserManagementCourseComponentLink(
      stage,
      id
    )

    history.push(stageLink)
  }

  const handleDelete = () => {
    deleteUser(currUser.id)
  }

  const handleFilterChange = (key, value) => {
    setFilterValues({
      ...filterValues,
      [key]: value
    })
  }

  const getContextMenu = signedUp => {
    const menu = [
      {
        component: (
          <TouchableOpacity onClick={toggleModal(setShowDelete)}>
            Delete User
          </TouchableOpacity>
        )
      }
    ]
    const contextMenu = signedUp
      ? [
          {
            component: (
              <TouchableOpacity
                onClick={toggleModal(setShowEditModal)}
              >
                Edit
              </TouchableOpacity>
            )
          },
          {
            component: (
              <TouchableOpacity
                onClick={toggleModal(setShowAccessModal)}
              >
                {selectedUserHasAccess
                  ? 'Remove Access'
                  : 'Give Access'}
              </TouchableOpacity>
            )
          },
          ...menu
        ]
      : menu
    return contextMenu
  }

  const filters = [
    {
      name: 'company',
      width: 100,
      options: [
        {
          value: 'all',
          label: 'All Company'
        },
        ...filterCompanies
      ]
    },
    {
      name: 'clientStatus',
      options: [
        {
          value: 'all',
          label: 'All Clients'
        },
        {
          value: false,
          label: 'No Access'
        },
        {
          value: true,
          label: 'With Access'
        }
      ]
    }
  ]

  const accessModalProps = selectedUserHasAccess
    ? {
        icon: <RemoveAccessIcon width={77} />,
        confirmText: 'Remove',
        cancelText: 'Keep Access',
        title:
          'Are you sure you want to remove access for this client?',
        description:
          'Their account will remain in the system but they will no longer be able to login.'
      }
    : {
        icon: <GiveAccessIcon width={77} />,
        confirmText: 'Give Access',
        cancelText: "Don't Give",
        title:
          'Are you sure you want to give access for this client?'
        // description:
        //   'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'
      }

  return (
    <>
      <div>
        <div className="mb-20 d-flex justify-content-between">
          <Filters
            filters={filters}
            onFilterChange={handleFilterChange}
          />
          <Button
            variant="secondary"
            onClick={toggleModal(setShowInviteModal)}
          >
            Invite Client
          </Button>
        </div>

        <Table className={styles.customTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Company</th>
              <th>Stage</th>
              <th width={90} className="text-center">
                Access
              </th>
              <th>Last Login</th>
              <th>Date Added</th>
              <th width={75} />
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((item, index) => {
              const profileImage = item.profileImage
                ? getFileLink(item.profileImage)
                : null
              return (
                <tr key={index}>
                  <td>
                    {item.signedUp && (
                      <NameWithThumb
                        name={`${item.firstName} ${item.lastName}`}
                        thumb={profileImage}
                      />
                    )}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.Company?.name}</td>
                  <td>
                    {item.signedUp && (
                      <StageRenderer
                        onClick={() => handleStageClick(item)}
                        stage={item.stage}
                      />
                    )}
                  </td>
                  <td>
                    <AccessRenderer access={item.hasAccess} />
                  </td>
                  <td>
                    {item.signedUp &&
                      formatDate({
                        format: 'MMM DD, YYYY',
                        date: new Date()
                      })}
                  </td>
                  <td>
                    {formatDate({
                      format: 'MMM DD, YYYY',
                      date: item.createdAt
                    })}
                  </td>
                  <td style={{ overflow: 'visible' }}>
                    <Dropdown
                      label={
                        <TouchableOpacity
                          onClick={() => setCurrUser(item)}
                        >
                          <ContextMenuIcon width={30} />
                        </TouchableOpacity>
                      }
                      menu={getContextMenu(item.signedUp)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Toast
        show={inviteUserError}
        title="Error"
        variant="danger"
        toggle={() => setInviteUserError(null)}
      >
        {inviteUserError}
      </Toast>

      <InvitationModal
        show={showInviteModal}
        toggleModal={toggleModal(setShowInviteModal)}
        onSubmit={handleInvite}
        companies={companies}
      />
      <EditUserModal
        show={showEditModal}
        toggleModal={toggleModal(setShowEditModal)}
        onSubmit={handleEdit}
        currUser={currUser}
        companies={companies}
      />
      <ConfirmModal
        show={showAccessModal}
        toggleModal={toggleModal(setShowAccessModal)}
        onConfirm={handleAccessToggle}
        {...accessModalProps}
      />
      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDelete}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={toggleModal(setShowDelete)}
        title="Are you sure you want to delete this user?"
        description='This action is irreversible. To remove access without deleting the user, select the three dots and choose "Remove Access"'
        onConfirm={handleDelete}
      />
    </>
  )
}

export default Clients
