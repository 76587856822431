import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import { isEmpty } from 'lodash'

import { DEFAULT_STAKEHOLDERS_STEPS } from 'utils/helpers/course-component'
import { getPercentage } from 'utils/helpers'

import Card from 'components/Card'
import SimpleTopNavbar from 'components/SimpleTopNavbar'
import ProgressCard from 'components/ProgressCard'

import singleUserStyles from 'pages/Coach/UserManagement/SingleUser/styles.module.scss'

import Stakeholders from './Stakeholders'
import MonthlyCheckIns from './MonthlyCheckIns'
import ProgressCheck from './ProgressCheck'

import {
  BASE_URL,
  defaultStakeholderContextValue,
  StakeholderContext
} from './constants'
import { useStakeholderEngagement } from './hooks'

import AddStakeholder from './Stakeholders/AddStakeholder'
import AddCheckIn from './MonthlyCheckIns/AddCheckIn'
import SendSurvey from './ProgressCheck/SendSurvey'

const StakeholderEngagement = () => {
  const { path } = useRouteMatch()
  const { userId } = useParams()
  const {
    data: stakeholderData,
    getStakeholders,
    deleteStakeholder,
    editStakeholder,
    getStackhodlerSurveys,
    surveysData
  } = useStakeholderEngagement(userId)
  const [
    showAddStakeholderModal,
    setShowAddStakeholderModal
  ] = useState(false)
  const [showCheckinError, setShowCheckinError] = useState(false)
  const [fetched, setFetched] = useState(false)

  const [showAddCheckinModal, setShowAddCheckinModal] = useState(
    false
  )
  const { data } = defaultStakeholderContextValue
  const [progressCheckData, setProgressCheckData] = useState(
    data.progressCheckData
  )

  const headerProviderValue = {
    data: {
      progressCheckData
    },
    setters: {
      setProgressCheckData: data => setProgressCheckData(data)
    }
  }

  const [stackHolderRoutes, setStackHolderRoutes] = useState([
    {
      title: 'Stakeholders',
      to: `${BASE_URL}/${userId}/stakeholder-engagement`
    },
    {
      title: 'Monthly Check-ins',
      to: `${BASE_URL}/${userId}/stakeholder-engagement/monthly-checkins`
    },
    {
      title: 'Progress Check',
      to: `${BASE_URL}/${userId}/stakeholder-engagement/progress-check`
    },
    {
      title: `0/0`
    }
  ])

  const [mutableSteps, setMutableSteps] = useState([])

  const [__data, setSurveysData] = useState([])

  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
  }

  useEffect(() => {
    const steps =
      stakeholderData && !isEmpty(stakeholderData.steps)
        ? stakeholderData.steps
        : DEFAULT_STAKEHOLDERS_STEPS

    setMutableSteps(steps)
  }, [stakeholderData])

  useEffect(() => {
    getStakeholders()
    if (!surveysData) {
      getStackhodlerSurveys(userId)
      setFetched(true)
    }
  }, [userId, getStakeholders])

  useEffect(() => {
    if (fetched && surveysData) {
      setSurveysData(surveysData?.data)
      const clonedStkHolder = [...stackHolderRoutes]
      const data = clonedStkHolder.find(
        ({ title }) => title === '0/0'
      )
      const completedSurveys = surveysData?.data?.filter(
        survey => survey?.isCompleted
      )
      data.title = `${completedSurveys?.length}/${surveysData?.data?.length}`
      setStackHolderRoutes(clonedStkHolder)
    }
  }, [surveysData, fetched])

  return (
    <div>
      <StakeholderContext.Provider value={headerProviderValue}>
        <Row className="mb-40">
          <Col
            xs={6}
            className={singleUserStyles.courseProgress}
          >
            <ProgressCard
              steps={mutableSteps}
              progress={progress}
              onChange={stepOnChange}
            />
          </Col>
          <Col xs={6}>
            <Card outlined className="h-100">
              The purpose of engaging stakeholders as part of the
              leadership development process is to create a
              supportive environment for growth. The people we
              work with on a regular basis are the most directly
              affected by our behavior and therefore best
              positioned to provide useful feedback and
              suggestions.
            </Card>
          </Col>
        </Row>
        <Row className="mb-10 justify-content-between">
          <SimpleTopNavbar
            routes={stackHolderRoutes}
            data={__data}
            targetIndex={stackHolderRoutes.length - 1}
          />
          <Col
            xs={5}
            className="d-flex align-items-center justify-content-end"
          >
            <Switch>
              <Route exact path={path}>
                <AddStakeholder
                  showModal={showAddStakeholderModal}
                  setShowModal={setShowAddStakeholderModal}
                />
              </Route>
              <Route exact path={`${path}/monthly-checkins`}>
                <AddCheckIn
                  showModal={showAddCheckinModal}
                  setShowModal={setShowAddCheckinModal}
                  setShowCheckinError={setShowCheckinError}
                />
              </Route>
              <Route exact path={`${path}/progress-check`}>
                <SendSurvey stakeholderData={stakeholderData} />
              </Route>
            </Switch>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Switch>
              <Route exact path={path}>
                <Stakeholders
                  stakeholderData={stakeholderData}
                  deleteStakeholder={deleteStakeholder}
                  editStakeholder={editStakeholder}
                  setShowAddStakeholderModal={
                    setShowAddStakeholderModal
                  }
                />
              </Route>
              <Route exact path={`${path}/monthly-checkins`}>
                <MonthlyCheckIns
                  setShowAddCheckinModal={setShowAddCheckinModal}
                  showCheckinError={showCheckinError}
                  setShowCheckinError={setShowCheckinError}
                />
              </Route>
              <Route
                exact
                path={`${path}/progress-check`}
                component={ProgressCheck}
              />
            </Switch>
          </Col>
        </Row>
      </StakeholderContext.Provider>
    </div>
  )
}

StakeholderEngagement.propTypes = {}

export default StakeholderEngagement
