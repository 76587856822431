import { useMutation } from 'react-query'

import { useGlobalState } from 'store/state'

import { loginAPI } from './api'

export const useUser = () => {
  const { user } = useGlobalState()

  const { mutate, isLoading, isSuccess, error } = useMutation(
    loginAPI,
    {
      onSuccess: async userData => {
        await user.set(userData)
      }
    }
  )

  return {
    login: mutate,
    isLoading,
    isSuccess,
    error
  }
}
