import React, { useState } from 'react'
import Select, { components } from 'react-select'

import { NameWithThumb } from 'components/TableRenderers'

import styles from './styles.module.scss'

const customStyles = {
  option: provided => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: 'black',
    backgroundColor: 'none'
  })
}

const Option = props => {
  const { data, isSelected } = props
  return (
    <components.Option
      className={styles.selectComponent}
      {...props}
    >
      <div style={{ paddingBottom: '10px' }}>
        <div style={{ textAlign: 'left' }}>
          <NameWithThumb name={`${data.label}`} />{' '}
        </div>
        <div style={{ textAlign: 'right', marginTop: '-30px' }}>
          <input type="checkbox" checked={isSelected} />
        </div>
      </div>
    </components.Option>
  )
}

const SelectCustom = ({
  disabled,
  loading,
  options,
  clearable,
  name,
  searchable = false,
  ...rest
}) => {
  const [menu, setMenu] = useState(false)

  return (
    <Select
      isMulti
      components={{
        IndicatorSeparator: () => null,
        Option
      }}
      {...rest}
      openMenuOnClick={() => setMenu(!menu)}
      onFocus={() => setMenu(!menu)}
      onBlur={() => setMenu(!menu)}
      menuIsOpen={menu}
      isSearchable={searchable}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={clearable}
      name={name}
      options={options}
      controlShouldRenderValue={false}
      styles={customStyles}
    />
  )
}

export default SelectCustom
