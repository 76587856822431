import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import LI from './LI'

const UL = ({
  className,
  listItemBottomMargin = 8,
  items = []
}) => {
  return (
    <div
      className={`${styles.unorderedList} ${className || ''}`}
    >
      {items.map((item, index) => (
        <LI
          key={`list-item-${index}`}
          bottomMargin={listItemBottomMargin}
        >
          {item}
        </LI>
      ))}
    </div>
  )
}

UL.propTypes = {
  className: PropTypes.string,
  listItemBottomMargin: PropTypes.number,
  items: PropTypes.array
}

export default UL
