import React from 'react'
import { isEmpty } from 'lodash'

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import { openLink } from 'utils/html/window'

import Table from 'components/Table'
import TouchableOpacity from 'components/TouchableOpacity'
import { Spinner } from 'react-bootstrap'

const DesktopTable = ({ data, isLoading, onEdit, onDelete }) => {
  const emptyData = isEmpty(data)
  return (
    <Table className="mb-30">
      <thead>
        <tr>
          <th>Name</th>
          <th>URL</th>
          <th>Date Added</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data?.map(doc => {
          return (
            <tr key={doc.id}>
              <td className="position-relative">
                <span>{doc.title}</span>
              </td>
              <td>
                <TouchableOpacity
                  onClick={() =>
                    openLink({
                      url: doc.url,
                      isExternalLink: true,
                      newTab: true
                    })
                  }
                  style={{ maxWidth: 200 }}
                  className="link"
                >
                  {doc.url}
                </TouchableOpacity>
              </td>
              <td>{doc.date}</td>
              <td>
                <div className="d-flex justify-content-center">
                  <TouchableOpacity className="mr-10">
                    <IconEdit onClick={() => onEdit(doc)} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onClick={() => onDelete(doc)}
                  >
                    <IconDelete />
                  </TouchableOpacity>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
      {emptyData && (
        <tr>
          <td colSpan={4} className="text-center">
            {isLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              'No data'
            )}
          </td>
        </tr>
      )}
    </Table>
  )
}

export default DesktopTable
