export const BOTTOM_RIGHT = 'bottom-right'
export const TOP_RIGHT = 'top-right'

export const BOTTOM_LEFT = 'bottom-left'
export const TOP_LEFT = 'top-left'

export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const DANGER = 'danger'
export const INFO = 'info'
export const WARNING = 'warning'
