import React from 'react'
import PropTypes from 'prop-types'
import { VictoryLegend, VictoryPie } from 'victory'
import { includes, random, toPairs } from 'lodash'

import {
  $gradientBlue,
  $gradientOrange,
  $gradientGreen,
  $gradientPurple,
  $gradientYellow,
  $white
} from 'utils/helpers/colors'
import { getPercentage } from 'utils/helpers'

import styles from './styles.module.scss'

const gradientColorsArray = [
  $gradientBlue,
  $gradientOrange,
  $gradientGreen,
  $gradientPurple,
  $gradientYellow
]

const PieChart = ({ data }) => {
  const dataPairs = toPairs(data)
  const filteredDataPairs = dataPairs.filter(
    dataPair => dataPair[1] !== 0
  )

  const getColorScale = () => {
    const randomizeColor = () => {
      const randomIndex = random(gradientColorsArray.length - 1)

      return gradientColorsArray[randomIndex]
    }
    const colorScale = filteredDataPairs.reduce(acc => {
      let color = randomizeColor()
      do {
        color = randomizeColor()
      } while (includes(acc, color))

      return [...acc, color]
    }, [])

    return colorScale
  }

  const transformData = data => {
    const dataSum = data.reduce(
      (acc, pair) => Number(acc) + Number(pair[1]),
      0
    )
    const transformedData = data.map(pair => {
      const percentage = getPercentage({
        a: pair[1],
        b: dataSum,
        decimalCount: 1
      })
      return {
        x: `${percentage}%`,
        y: pair[1],
        name: pair[0]
      }
    })

    return transformedData
  }

  const colorScale = getColorScale()

  return (
    <div className="d-flex flex-column flex-md-row">
      <div className={styles.pieChartWrapper}>
        <VictoryPie
          style={{
            labels: { fontSize: 20, fill: $white }
          }}
          colorScale={colorScale}
          data={transformData(filteredDataPairs)}
          labelRadius={80}
        />
      </div>
      <div className={styles.legendWrapper}>
        <VictoryLegend
          gutter={20}
          colorScale={colorScale}
          data={transformData(filteredDataPairs)}
          style={{
            labels: { fontSize: 35, fontFamily: 'helvetica' }
          }}
          symbolSpacer={20}
        />
      </div>
    </div>
  )
}

PieChart.propTypes = {
  data: PropTypes.object
}

export default PieChart
