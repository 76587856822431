import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { capitalize } from 'lodash'

import { getFileLink } from 'utils/helpers/file'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import FormCheck from 'components/Form/FormCheck'
import Modal from 'components/Modal'
import TouchableOpacity from 'components/TouchableOpacity'
import Upload from 'components/Upload'
import { resourceIsDocs, resourceIsLink } from '../typeValidator'
import { schema } from '../schema'
import styles from './styles.module.scss'
import InputWithDropdown from './InputWithDropdown'

const EditResourceModal = ({
  currResource,
  editResource,
  show,
  toggleModal,
  resourceCategories,
  createCategory
}) => {
  const categoryInputRef = useRef(null)
  const addCategoryRef = useRef(null)
  const uploadDataRef = useRef(null)
  const [originalType, setOriginalType] = useState(null)
  const [type, setType] = useState(null)
  const [categoryValue, setCatgoryValue] = useState('')
  const [categoryError, setCategoryError] = useState(null)
  const [uploadedData, setUploadedData] = useState(null)
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(schema)
  })
  const categoryOptions = resourceCategories?.map(
    category => category.name
  )

  const onFileUpload = e => {
    if (e.target.files.length) {
      return setUploadedData(e.target.files[0])
    }

    return setUploadedData(null)
  }

  const formSubmit = data => {
    const selectedCategory = resourceCategories.find(
      x => x.name === categoryInputRef.current?.value
    )
    const payload = {
      ...data,
      category: selectedCategory.id,
      type: data.type.toLowerCase()
    }
    editResource({
      resourceId: currResource.id,
      payload: {
        ...payload,
        file: uploadedData
      }
    })
    toggleModal()
  }

  const handleAddCategory = () => {
    if (categoryValue.length) {
      createCategory({ name: categoryValue })
      setCategoryError(null)
    } else {
      setCategoryError({ message: 'Category is empty' })
    }
  }

  const categoryOnChange = e => {
    setCatgoryValue(e.target.value)
    if (e.target.value.length) {
      return setCategoryError(null)
    }

    return setCategoryError({ message: 'Category is empty' })
  }

  useEffect(() => {
    setType(capitalize(currResource?.type))
    setOriginalType(capitalize(currResource?.type))
  }, [currResource])

  const {
    title,
    ResourceCategory,
    url,
    source,
    sourceUrl,
    description
  } = currResource || {}

  const isOriginallyLink = useMemo(
    () => resourceIsLink(originalType),
    [originalType]
  )

  const isOriginallyDocs = useMemo(
    () => resourceIsDocs(originalType),
    [originalType]
  )

  return (
    <Modal show={show} closeModal={toggleModal}>
      <fieldset>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <p className={styles.modalTitle}>Edit Content</p>
              </Col>
            </div>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <FormControl
                  placeholder="Content Title"
                  formGroupClass="mb-8"
                  name="title"
                  as="textarea"
                  rows={1}
                  inputRef={register}
                  error={errors.title}
                  defaultValue={title}
                />
              </Col>
            </div>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <InputWithDropdown
                  inputRef={categoryInputRef}
                  placeholder="Select Category"
                  options={categoryOptions}
                  defaultValue={ResourceCategory?.name}
                >
                  <div className="d-flex px-20 py-15 align-items-center">
                    <TouchableOpacity
                      className="text-secondary mr-10"
                      onClick={handleAddCategory}
                    >
                      + Category
                    </TouchableOpacity>
                    <FormControl
                      inputRef={addCategoryRef}
                      error={categoryError}
                      name="addCategory"
                      formGroupClass="mb-0"
                      className={styles.categoryInput}
                      onChange={categoryOnChange}
                      value={categoryValue}
                    />
                  </div>
                </InputWithDropdown>
              </Col>
            </div>
            <Col lg={12}>
              <hr />
            </Col>
            <div className="d-flex align-items-center mb-10 ">
              <Col lg={4}>
                <h6>Content Type: </h6>
              </Col>
              {['Link', 'Document'].map(val => {
                return (
                  <Col lg="2" key={val}>
                    <FormCheck
                      inputRef={register}
                      error={errors.type}
                      custom
                      controlId={val}
                      type="radio"
                      name="type"
                      checked={val === type}
                      onChange={e => {
                        setType(e.target.id)
                        setValue('url', '')
                      }}
                      id={val}
                      label={val}
                      value={type}
                    />
                  </Col>
                )
              })}
            </div>
            {resourceIsLink(type) && (
              <>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Paste URL here"
                      formGroupClass="mb-8"
                      name="url"
                      type="url"
                      inputRef={register}
                      error={errors.url}
                      defaultValue={isOriginallyDocs ? '' : url}
                    />
                  </Col>
                </div>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Source name"
                      formGroupClass="mb-8"
                      name="source"
                      type="url"
                      inputRef={register}
                      error={errors.c}
                      defaultValue={source}
                    />
                  </Col>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Description"
                      formGroupClass="mb-8"
                      as="textarea"
                      rows={3}
                      name="description"
                      inputRef={register}
                      error={errors.description}
                      defaultValue={description}
                    />
                  </Col>
                </div>
              </>
            )}
            {resourceIsDocs(type) && (
              <>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Paste URL here"
                      formGroupClass="mb-8"
                      name="sourceUrl"
                      type="url"
                      inputRef={register}
                      error={errors.sourceUrl}
                      defaultValue={sourceUrl}
                    />
                  </Col>
                </div>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Source"
                      formGroupClass="mb-8"
                      name="source"
                      type="url"
                      inputRef={register}
                      defaultValue={source}
                      error={errors.source}
                    />
                  </Col>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Description"
                      formGroupClass="mb-8"
                      as="textarea"
                      rows={3}
                      name="description"
                      inputRef={register}
                      defaultValue={description}
                      error={errors.description}
                    />
                  </Col>
                </div>

                <Col xs={12}>
                  <Upload
                    fileRestriction="application/pdf"
                    inputRef={uploadDataRef}
                    onFileChange={onFileUpload}
                  />
                  <Button
                    className={styles.seeMoreBtn}
                    variant="outline-secondary"
                    id="document"
                    onClick={() =>
                      uploadDataRef?.current.click()
                    }
                  >
                    Upload File
                  </Button>
                  <label className={styles.label}>
                    Must not exceed 5 MB
                  </label>
                </Col>
                {url && !uploadedData && !isOriginallyLink && (
                  <Col
                    xs={12}
                    className={`${styles.fileName} mt-10 text-gray-dark`}
                  >
                    Current File: {getFileLink(url)}
                  </Col>
                )}
                {uploadedData && (
                  <Col
                    xs={12}
                    className={`${styles.fileName} mt-10 text-gray-dark`}
                  >
                    New File: {uploadedData.name}
                  </Col>
                )}
              </>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="link"
                smallPadding
                onClick={toggleModal}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                smallPadding
                type="submit"
                onClick={handleSubmit(formSubmit)}
              >
                Save Changes
              </Button>
            </div>
          </Col>
        </Row>
      </fieldset>
    </Modal>
  )
}

export default EditResourceModal
