import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { ReactComponent as IconHelp } from 'assets/icons/help.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import { dateToUTCISO } from 'utils/helpers/date'

import Button from 'components/Button'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'
import Tooltip from 'components/Tooltip'
import AddButton from 'components/Button/AddButton'

import { useStakeholderEngagement } from '../hooks'

import styles from './styles.module.scss'
import { CHECK_IN_TOOLTIP } from './messages'
import { useCheckin } from './hooks'
import { questionOne, questionTwo } from './constants'

const addCheckInFormSchema = yup.object().shape({
  date: yup.string().required(`Please enter stakeholder's date`),
  stakeholder: yup
    .number()
    .required(`Please select a stakeholder`),
  questionOne: yup.string().required('Please enter an answer'),
  questionTwo: yup.string().required('Please enter an answer')
})

const AddCheckIn = ({
  showModal,
  setShowModal,
  setShowCheckinError
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addCheckInFormSchema)
  })

  const { createCheckin, createCheckinError } = useCheckin()
  const { data } = useStakeholderEngagement()
  const stakeholders = data?.stakeholderProfiles

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  const addCheckIn = async data => {
    const date = dateToUTCISO({
      date: data.date,
      format: 'MMM DD, YYYY'
    })

    await createCheckin({
      id: data.stakeholder,
      payload: {
        date,
        data: [
          {
            question: questionOne,
            answer: data.questionOne
          },
          {
            question: questionTwo,
            answer: data.questionTwo
          }
        ]
      }
    })
    closeModal()
  }

  useEffect(() => {
    if (createCheckinError) {
      setShowCheckinError(createCheckinError?.message)
    }
  }, [createCheckinError, setShowCheckinError])
  return (
    <>
      <div className="d-flex justify-content-between justify-content-md-end align-items-center w-100">
        <div className="d-flex d-md-none align-items-center">
          <p className="mb-0 mr-10">
            What are{' '}
            <span className="font-weight-bold">
              monthly checkins
            </span>
            ?
          </p>
          <Tooltip tip={CHECK_IN_TOOLTIP}>
            <IconHelp />
          </Tooltip>
        </div>
        <AddButton label="Check-in" onClick={openModal} />
      </div>

      <Modal
        mimicNative
        title="Add Monthly Checkin"
        bodyClassName={styles.addCheckIn}
        show={showModal}
        closeModal={closeModal}
      >
        <p className={styles.modalTitle}>Add Monthly Check-in</p>
        <p className="text-gray-dark mb-20">
          Include notes from your conversation below.
        </p>
        <div className="mb-40">
          <FormControl
            inputRef={register}
            type="string"
            name="stakeholder"
            placeholder="Stakeholder"
            error={errors.stakeholder}
            as="select"
          >
            <option>Select Stakeholder</option>
            {stakeholders?.map(stakeholder => (
              <option
                key={stakeholder.id}
                value={stakeholder.id}
              >
                {stakeholder.name}
              </option>
            ))}
          </FormControl>

          <FormControl
            disablePreviousMonths
            disableFutureDates
            inputRef={register}
            icon={<IconCalendar />}
            name="date"
            as="datepicker"
            placeholder="Date"
            error={errors.date}
          />

          <FormControl
            inputRef={register}
            type="string"
            name="questionOne"
            placeholder="Answer"
            error={errors.questionOne}
            labelClassName="font-weight-medium"
            label={questionOne}
            as="textarea"
            rows={3}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="questionTwo"
            placeholder="Answer"
            error={errors.questionTwo}
            labelClassName="font-weight-medium"
            label={questionTwo}
            as="textarea"
            rows={3}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end mb-20 ">
          <Button
            variant="link"
            className={`${styles.cancelBtn} p-0 mr-20`}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            smallPadding
            type="submit"
            onClick={handleSubmit(addCheckIn)}
          >
            Add Check-in
          </Button>
        </div>
      </Modal>
    </>
  )
}

AddCheckIn.propTypes = {}

export default AddCheckIn
