import { getRequest, postRequest, putRequest } from 'utils/http'

export const getCheckin = () => {
  return getRequest(`/stakeholders/profiles/checkin`)
}

export const createCheckin = ({ id, payload }) => {
  return postRequest(
    `/stakeholders/profiles/${id}/checkin`,
    payload
  )
}

export const editCheckin = ({ id, payload }) => {
  return putRequest(`/stakeholders/checkin/${id}`, payload)
}

export const deleteCheckinApi = payload => {
  return postRequest(`/stakeholders/delete/checkin`, payload)
}
