import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'

import IconBullet from 'assets/icons/bullet-point.svg'

import styles from './styles.module.scss'

const LI = ({ bottomMargin = 8, children, className }) => {
  return (
    <div
      className={`${styles.listItem} ${className || ''}`}
      style={{ marginBottom: bottomMargin }}
    >
      <Image src={IconBullet} className={styles.bullet} />
      {children}
    </div>
  )
}

LI.propTypes = {
  bottomMargin: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string
}

export default LI
