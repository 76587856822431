import * as yup from 'yup'

export const GET_DEV_PLAN =
  'pages/coach/userManagement/singleUser/developmentPlan/getDevPlan'

export const GET_DEV_STATS =
  'pages/coach/userManagement/singleUser/developmentPlan/getDevChart'

export const goalSchema = yup.object().shape({
  title: yup
    .string()
    .required('Please enter goal title')
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    )
})

export const chartYTickValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
