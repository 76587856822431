export const ROUTES = [
  {
    title: 'Dashboard',
    to: '/dashboard'
  },
  {
    title: 'Coaching Sessions',
    to: '/coaching-sessions'
  },
  {
    title: 'Objectives and Key Results',
    to: '/objectives-and-key-results'
  },
  {
    title: 'Workshops',
    to: '/workshops'
  },
  {
    title: 'Resource Library',
    to: '/resource-library'
  }
]

export const COURSE_COMPONENTS = [
  {
    title: 'Personality and Leadership Profile',
    to: '/dashboard/plp'
  },
  {
    title: 'Three Pillars of Leadership 360',
    to: '/dashboard/three-pillars-of-leadership'
  },
  {
    title: 'Development Plan',
    to: '/dashboard/development-plan'
  },
  {
    title: 'Stakeholder Engagement',
    to: '/dashboard/stakeholder-engagement'
  }
]

export const DASHBOARD_BASE_URLS = ['/', '/dashboard']
