import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import {
  getUserPersonalityApi,
  updatePersonalityApi,
  updatePersonalityPdfApi
} from './api'
import { GET_USER_PERSONALITY } from './constants'

export const usePersonality = userId => {
  const {
    data: userPersonality,
    refetch: getUserPersonality
  } = useQuery(
    GET_USER_PERSONALITY,
    () => getUserPersonalityApi(userId),
    { enabled: false }
  )

  const {
    mutate: updatePersonalityPdf,
    isLoading: updatePersonalityPdfLoading
  } = useMutation(updatePersonalityPdfApi, {
    onSuccess: () =>
      queryClient.invalidateQueries(GET_USER_PERSONALITY, {
        refetchInactive: true
      })
  })

  const { mutate: updatePersonality } = useMutation(
    updatePersonalityApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_PERSONALITY, {
          refetchInactive: true
        })
        queryClient.invalidateQueries(GET_COURSE_STATUS)
      }
    }
  )

  return {
    userPersonality,
    getUserPersonality,

    updatePersonalityPdf,
    updatePersonalityPdfLoading,

    updatePersonality
  }
}
