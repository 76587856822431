import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Image,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

import IconEdit from 'assets/icons/edit.svg'

import { useUserWorkshop } from 'global/hooks/useUserWorkshop'

import { getUserImage } from 'utils/helpers/component'
import { openLink } from 'utils/html/window'
import { getFileLink } from 'utils/helpers/file'

import HtmlParser from 'react-html-parser/lib/HtmlParser'
import CardCollapse from 'components/CardCollapse'
import TouchableOpacity from 'components/TouchableOpacity'
import FormCheck from 'components/Form/FormCheck'
import FileDisplay from 'components/FileDisplay'
import { UnorderedList } from 'components/UnorderedList'

import styles from './styles.module.scss'

const WorkshopCard = ({
  title,
  date,
  agendas,
  notes,
  actionSteps,
  attendees,
  files,
  hasEdit,
  workshopId
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { editUserWorkshop } = useUserWorkshop()

  const handleEditCheck = ({ index }) => {
    const newSteps = actionSteps.map((step, stepIndex) => ({
      ...step,
      status: stepIndex === index ? !step.status : step.status
    }))

    return editUserWorkshop({
      workshopId,
      payload: {
        actionSteps: newSteps
      }
    })
  }

  const toggle = () => setIsOpen(!isOpen)
  const CollapseHeader = () => {
    return (
      <>
        <Col xs={8} className="font-weight-medium pl-0">
          <TouchableOpacity onClick={toggle}>
            {title}
          </TouchableOpacity>
        </Col>
        <Col
          xs={4}
          className="d-flex justify-content-end align-items-center pr-0"
        >
          <div className="text-gray-dark ">{date}</div>
          <TouchableOpacity>
            {hasEdit && (
              <Image src={IconEdit} className="ml-15" />
            )}
          </TouchableOpacity>
        </Col>
      </>
    )
  }

  return (
    <CardCollapse
      headerComponent={<CollapseHeader />}
      isOpen={isOpen}
      className="mb-20"
    >
      <div className="agendas mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Agenda:
        </div>
        <UnorderedList items={agendas} />
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <div>{HtmlParser(notes)}</div>
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Action Steps:
        </div>
        {actionSteps.map((step, index) => (
          <FormCheck
            onChange={() => handleEditCheck({ index })}
            key={`actionStep-${workshopId}-${step.id}`}
            controlId={`actionStep-${workshopId}-${step.id}`}
            label={step.description}
            defaultChecked={step.status}
          />
        ))}
      </div>
      <div className={styles.attendees}>
        <div className="font-weight-medium text-primary mb-10">
          Attendees:
        </div>
        <div className="attendess-wrapper d-flex">
          {attendees.map(attendee => {
            const tooltip = props => (
              <Tooltip
                id={`button-tooltip-${attendee.name}`}
                {...props}
                className="bg-primary"
              >
                {attendee.name}
              </Tooltip>
            )

            const userImage = {
              name: attendee.name,
              image: attendee.image
                ? getFileLink(attendee.image)
                : null
            }

            return (
              <OverlayTrigger placement="top" overlay={tooltip}>
                <div className="mr-5">
                  {getUserImage(userImage, 30)}
                </div>
              </OverlayTrigger>
            )
          })}
        </div>
      </div>
      <div className={styles.files}>
        {files.map(file => {
          const splitUrl = file.split('/')
          const fileName = splitUrl[splitUrl.length - 1]
          const fileLink = getFileLink(file)

          return (
            <FileDisplay
              onClick={() => {
                openLink({ url: fileLink, newTab: true })
              }}
              url={file}
              fileName={fileName}
            />
          )
        })}
      </div>
    </CardCollapse>
  )
}

WorkshopCard.propTypes = {
  agendas: PropTypes.array,
  notes: PropTypes.string,
  actionSteps: PropTypes.array,
  attendees: PropTypes.array,
  files: PropTypes.array
}

export default WorkshopCard
