import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getAllClientsApi = () => {
  return getRequest('/users/client')
}

export const getAllCompanyApi = () => {
  return getRequest('/company')
}

export const getAllCoachesApi = () => {
  return getRequest('/users/coach')
}

export const deleteUserApi = id => {
  return deleteRequest(`/users/${id}`)
}

export const editUserCompanyApi = ({ userId, payload }) => {
  return putRequest(`/users/company/${userId}`, payload)
}

export const giveUserAccessApi = userId => {
  return patchRequest(`/users/access/enable/${userId}`)
}

export const removeUserAccessApi = userId => {
  return patchRequest(`/users/access/disable/${userId}`)
}

export const inviteUserApi = payload => {
  return postRequest('/users/invite', payload)
}
