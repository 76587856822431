import React from 'react'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import { openLink } from 'utils/html/window'

import Button from 'components/Button'

import styles from './styles.module.scss'

const PdfViewer = ({ src, ...rest }) => {
  const { isSmallOnlyScreen } = useDeviceSize()

  if (isSmallOnlyScreen) {
    return (
      <div className={styles.pdfViewerWrapperMobile} {...rest}>
        <Button
          variant="secondary"
          onClick={() =>
            openLink({
              url: src,
              newTab: true,
              isExternalLink: true
            })
          }
        >
          Click here to view PDF
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.pdfViewerWrapper} {...rest}>
      <object
        className={styles.pdfViewer}
        data={src}
        type="application/pdf"
      >
        <embed src={src} type="application/pdf" />
      </object>
    </div>
  )
}

export default PdfViewer
