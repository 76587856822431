import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_SIDEBAR_CONTENT } from 'global/hooks/useRightSidebar/constants'

import {
  createSession as createSessionAPI,
  editSession as editSessionAPI,
  deleteSession as deleteSessionAPI,
  getSessions,
  createCoachingDoc as createCoachingDocAPI,
  editCoachingDoc as editCoachingDocAPI,
  deleteCoachingDoc as deleteCoachingDocAPI,
  getCoachingDocs
} from './api'
import {
  GET_COACHING_DOCUMENTS,
  GET_SESSIONS
} from './constants'

export const useSession = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_SESSIONS,
    getSessions
  )

  const { mutate: createSession } = useMutation(
    createSessionAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_SESSIONS)
    }
  )

  const { mutate: editSession } = useMutation(editSessionAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_SESSIONS)
      queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
    }
  })

  const { mutate: deleteSession } = useMutation(
    deleteSessionAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SESSIONS)
        queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
      }
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,

    createSession,
    editSession,
    deleteSession
  }
}

export const useCoachingDocuments = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_COACHING_DOCUMENTS,
    getCoachingDocs
  )

  const { mutate: createCoachingDoc } = useMutation(
    createCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  const { mutate: editCoachingDoc } = useMutation(
    editCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  const { mutate: deleteCoachingDoc } = useMutation(
    deleteCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  return {
    data,
    Loading: isLoading,
    isSuccess,
    error,

    createCoachingDoc,
    editCoachingDoc,
    deleteCoachingDoc
  }
}
