import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { find, includes, isNil } from 'lodash'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'

import Card from 'components/Card'
import TouchableOpacity from 'components/TouchableOpacity'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'

import memberStyles from 'pages/Member/styles.module.scss'

import styles from '../styles.module.scss'
import { goalSchema } from '../constants'
import { useDevPlan } from '../hooks'

const Edit = ({
  planId,
  actionSteps,
  title,
  toggleEditMode
}) => {
  const [mutableActionSteps, setMutableActionSteps] = useState(
    actionSteps.map(step => ({ ...step }))
  )
  const [actionStepError, setActionStepError] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(goalSchema)
  })
  const { editPlanGoal } = useDevPlan()

  const addActionStep = () => {
    setActionStepError(null)
    const lastStepId = mutableActionSteps.length
      ? mutableActionSteps[mutableActionSteps.length - 1].id
      : 0
    setMutableActionSteps([
      ...mutableActionSteps,
      { id: lastStepId + 1, description: '' }
    ])
  }
  const removeActionStep = id => {
    const hasOneActionStep = mutableActionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = mutableActionSteps.filter(
        step => step.id !== id
      )
      setMutableActionSteps(output)
    } else {
      setActionStepError('At least one action step is required')
    }
  }
  const actionStepOnChange = (id, newValue) => {
    const index = mutableActionSteps.findIndex(
      step => step.id === id
    )
    mutableActionSteps[index].description = newValue
  }

  const validateData = async (addSteps, editedSteps) => {
    const addedValid = addSteps.length
      ? !includes(
          addSteps.map(addStep => !!addStep.description.length),
          false
        )
      : true
    const editedValid = editedSteps.length
      ? !includes(
          editedSteps.map(
            editedStep => !!editedStep.description.length
          ),
          false
        )
      : true

    return addedValid && editedValid
  }

  const saveChanges = async ({ title }) => {
    const addedSteps = []
    const editedSteps = []
    const deletedSteps = []

    // FIND ADDED AND EDITED
    const findAddedAndEdited = mutableActionSteps.forEach(
      mutedStep => {
        const originalStep = find(
          actionSteps,
          step => step.id === mutedStep.id
        )
        const isAdded = isNil(originalStep)
        if (isAdded) {
          return addedSteps.push(mutedStep)
        }
        if (mutedStep.description !== originalStep.description) {
          return editedSteps.push(mutedStep)
        }
        return null
      }
    )

    // FIND DELETED
    const findDeleted = actionSteps.forEach(originalStep => {
      const mutedSteps = find(
        mutableActionSteps,
        step => step.id === originalStep.id
      )
      const isDeleted = isNil(mutedSteps)
      if (isDeleted) {
        return deletedSteps.push(originalStep)
      }
      return null
    })

    await Promise.all([findAddedAndEdited, findDeleted])

    const isValid = await validateData(addedSteps, editedSteps)

    if (isValid) {
      editPlanGoal({
        title,
        planId,
        addedSteps,
        editedSteps,
        deletedSteps
      })
      setActionStepError(null)
    } else {
      setActionStepError(
        'All action steps require a description'
      )
    }

    toggleEditMode()
  }

  return (
    <Card className={`${styles.devPlanCard} mb-20`}>
      <Row>
        <Col md={12}>
          <FormControl
            defaultValue={title}
            placeholder="Goal"
            as="textarea"
            rows={1}
            name="title"
            formGroupClass="mb-8"
            inputRef={register}
            error={errors.title}
          />
        </Col>
      </Row>
      <div className="mb-20">
        <Col xs={12} className={memberStyles.dividerLine} />
      </div>
      <div className="d-flex justify-content-between mb-10">
        <div className="font-weight-medium text-primary">
          Action items:
        </div>
        <TouchableOpacity
          onClick={addActionStep}
          className="text-secondary"
        >
          + Add action item
        </TouchableOpacity>
      </div>
      <div className="mb-30">
        {mutableActionSteps?.map(step => (
          <Row
            key={`add-plan-action-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                placeholder="Action step description"
                formGroupClass={`${styles.actionItemInput} mb-0 mr-20`}
                as="textarea"
                rows={1}
                defaultValue={step.description}
                onChange={e =>
                  actionStepOnChange(step.id, e.target.value)
                }
              />
              <TouchableOpacity
                onClick={() => removeActionStep(step.id)}
                className="d-flex align-items-center"
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
        {actionStepError && (
          <small className="text-danger">
            {actionStepError}
          </small>
        )}
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button
          className="d-none d-sm-block"
          variant="link"
          smallPadding
          onClick={toggleEditMode}
        >
          Cancel
        </Button>
        <Button
          className="form__button--submit"
          variant="secondary"
          smallPadding
          onClick={handleSubmit(saveChanges)}
        >
          Save Changes
        </Button>
      </div>
    </Card>
  )
}

Edit.propTypes = {
  actionSteps: PropTypes.array,
  planId: PropTypes.number,
  title: PropTypes.string,
  toggleEditMode: PropTypes.func
}

export default Edit
