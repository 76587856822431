import React from 'react'
import PropTypes from 'prop-types'
import { Table as BSTable } from 'react-bootstrap'

import styles from './styles.module.scss'

const Table = ({ children, className, ...rest }) => {
  return (
    <div className={`${styles.table} ${className}`}>
      <BSTable {...rest}>{children}</BSTable>
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.node
}

export default Table
