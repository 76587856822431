import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const Nav = ({
  children,
  containerClassName,
  className,
  courseRef
}) => {
  return (
    <div
      className={`${styles.courseComponentNavWrapper} ${
        containerClassName || ''
      }`}
      ref={courseRef}
    >
      <div
        className={`${styles.courseComponentNav} ${
          className || ''
        } d-flex`}
      >
        {children}
      </div>
    </div>
  )
}

Nav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Nav
