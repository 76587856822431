import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'

import CardCollapse from 'components/CardCollapse'
import FormCheck from 'components/Form/FormCheck'
import FormControl from 'components/Form/FormControl'
import TouchableOpacity from 'components/TouchableOpacity'
import Button from 'components/Button'

import { coachingSessionSchema } from 'pages/Member/CoachingSessions/constants'

import TextEditor from 'components/Form/TextEditor/TextEditor'
import CollapseHeader from './CollapseHeader'

const Add = ({ addToggle, handleCreateSession }) => {
  const [agendas, setAgendas] = useState([
    {
      id: 1,
      description: ''
    }
  ])
  const [actionSteps, setActionSteps] = useState([
    {
      id: 1,
      description: '',
      status: false
    }
  ])
  const [formatedNotes, setFormatedNotes] = useState('')
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(coachingSessionSchema)
  })

  const addActionStep = () => {
    const lastStepId = actionSteps.length
      ? actionSteps[actionSteps.length - 1].id
      : 0
    setActionSteps([
      ...actionSteps,
      { id: lastStepId + 1, description: '', status: false }
    ])
  }

  const removeActionStep = id => {
    const hasOneActionStep = actionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = actionSteps.filter(step => step.id !== id)
      setActionSteps(output)
    }
  }

  const actionStepOnChange = (id, newValue) => {
    const index = actionSteps.findIndex(step => step.id === id)
    actionSteps[index].description = newValue
    setActionSteps([...actionSteps])
  }

  const actionStepToggle = editStep => {
    const { id, description, status } = editStep
    const filteredActionSteps = actionSteps.filter(
      step => step.id !== id
    )
    const newActionSteps = [
      ...filteredActionSteps,
      {
        id,
        description,
        status: !status
      }
    ].sort((a, b) => a.id - b.id)
    setActionSteps(newActionSteps)
  }

  const addAgenda = () => {
    const lastStepId = agendas.length
      ? agendas[agendas.length - 1].id
      : 0
    setAgendas([
      ...agendas,
      { id: lastStepId + 1, description: '' }
    ])
  }

  const removeAgenda = id => {
    const hasOneAgenda = agendas?.length === 1

    if (!hasOneAgenda) {
      const output = agendas.filter(step => step.id !== id)
      setAgendas(output)
    }
  }

  const agendaOnChange = (id, newValue) => {
    const index = agendas.findIndex(step => step.id === id)
    agendas[index].description = newValue
    setAgendas(agendas)
  }

  const saveHandler = async ({ date, title }) => {
    const validSteps = actionSteps.filter(
      step => !isEmpty(step.description)
    )
    const validAgendas = agendas.filter(
      agenda => !isEmpty(agenda.description)
    )
    const isValid =
      actionSteps.length === validSteps.length &&
      agendas.length === validAgendas.length
    if (isValid) {
      const payload = {
        date,
        title,
        notes: formatedNotes,
        agenda: agendas.map(x => x.description),
        steps: actionSteps
      }
      await handleCreateSession(payload)
      addToggle()
    }
  }

  return (
    <CardCollapse
      headerComponent={
        <CollapseHeader
          inputRegister={register}
          inputErrors={errors}
        />
      }
      isOpen
      className="py-10"
    >
      <div className="agendas mb-20">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Agenda:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addAgenda}
          >
            + Add agenda item
          </TouchableOpacity>
        </div>
        {agendas.map(agenda => (
          <Row key={`add-agenda-${agenda.id}`} className="mb-10">
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                name="agenda"
                type="text"
                placeholder="Agenda description"
                formGroupClass="flex-1 mb-0 mr-20"
                as="textarea"
                rows={1}
                defaultValue={agenda.description}
                onChange={e =>
                  agendaOnChange(agenda.id, e.target.value)
                }
                inputRef={register}
                error={errors.agenda}
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeAgenda(agenda.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <TextEditor setFormatedNotes={setFormatedNotes} />
      </div>
      <div className="action-steps mb-40">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Action Steps:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addActionStep}
          >
            + Add step
          </TouchableOpacity>
        </div>
        {actionSteps.map(step => (
          <Row
            key={`add-actionStep-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <FormCheck
                controlId={`add-actionStep-${step.id}`}
                checked={step.status}
                onChange={() => actionStepToggle(step)}
              />
              <FormControl
                name="step"
                type="text"
                placeholder="Action step description"
                formGroupClass="flex-1 mb-0 mr-20"
                as="textarea"
                rows={1}
                defaultValue={step.description}
                onChange={e => {
                  actionStepOnChange(step.id, e.target.value)
                }}
                inputRef={register}
                error={errors.step}
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeActionStep(step.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button variant="link" smallPadding onClick={addToggle}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          smallPadding
          onClick={handleSubmit(saveHandler)}
        >
          Save Changes
        </Button>
      </div>
    </CardCollapse>
  )
}

Add.propTypes = {
  addToggle: PropTypes.func
}

export default Add
