import localForage from 'localforage'

export function handleHttpError(error) {
  const genericErrorMessage = 'Something Failed. Try again?'

  if (error.response && error.response.data) {
    if (error.response.status === 401) {
      const currentGlobalState = localForage.getItem(
        'globalState'
      )
      localForage.setItem('globalState', {
        ...currentGlobalState,
        user: null
      })
      window.location.href = '/'
    }
    return {
      statusCode: error.response.status,
      message:
        error.response.data.message ||
        error.response.data ||
        genericErrorMessage
    }
  }

  return {
    statusCode: 500,
    message: genericErrorMessage
  }
}
