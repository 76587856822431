import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconActive } from 'assets/icons/course-active.svg'
import { ReactComponent as IconDone } from 'assets/icons/course-done.svg'

import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'

const Item = ({
  children,
  isActive,
  onClick,
  style,
  isDone,
  showStatus,
  isCoach
}) => {
  return (
    <TouchableOpacity
      className={`${styles.courseComponentItem} ${
        isActive && styles.active
      } ${
        isCoach && styles.coach
      } d-flex justify-content-center py-20 bg-primary`}
      onClick={onClick}
      style={style}
    >
      <div className={styles.itemWrapper}>
        {showStatus && (
          <div
            className={`${styles.statusWrapper} d-flex justify-content-center align-items-center`}
          >
            {isDone ? <IconDone /> : <IconActive />}
          </div>
        )}
        <div className={`${styles.titleWrapper}`}>
          <div className={`${styles.title}`}>{children}</div>
        </div>
      </div>
    </TouchableOpacity>
  )
}

Item.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool
}

export default Item
