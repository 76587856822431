import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

// import { ReactComponent as IconTrash } from 'assets/icons/trash-icon.svg'
// import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import { formatDate } from 'utils/helpers/date'

import TouchableOpacity from 'components/TouchableOpacity'
import DropdownButton from 'components/Button/DropdownButton'
// import ConfirmModal from 'components/ConfirmModal'

const CollapseHeader = ({
  title,
  isOpen,
  date,
  // onDelete,
  onEdit,
  toggle
}) => {
  // const [
  //   initializedDeleteModal,
  //   setInitializedDeleteModal
  // ] = useState(false)
  // const [showDelete, setShowDelete] = useState(false)

  // const showDeleteModal = () => {
  //   if (!initializedDeleteModal) {
  //     setInitializedDeleteModal(true)
  //   }
  //   setShowDelete(true)
  // }

  // const hideDeleteModal = () => {
  //   setShowDelete(false)
  // }

  return (
    <>
      <Col xs={8} className="font-weight-medium pl-0">
        <TouchableOpacity onClick={toggle}>
          {title}
        </TouchableOpacity>
        <div className="text-gray-dark mr-20 d-md-none">
          {formatDate({
            format: 'MMM DD, YYYY',
            date
          })}
        </div>
      </Col>
      <Col
        xs={4}
        className="d-flex justify-content-end align-items-center pr-0"
      >
        <div className="text-gray-dark mr-20 d-none d-md-block">
          {formatDate({
            format: 'MMM DD, YYYY',
            date
          })}
        </div>
        <TouchableOpacity
          onClick={onEdit}
          className="d-none d-md-block mr-10"
        >
          <IconEdit />
        </TouchableOpacity>
        {/* <TouchableOpacity
          onClick={showDeleteModal}
          className="d-none d-md-block"
        >
          <IconDelete />
        </TouchableOpacity> */}
        <DropdownButton
          onClick={toggle}
          isOpen={isOpen}
          className="d-md-none"
        />
      </Col>
      {/* {initializedDeleteModal && (
        <ConfirmModal
          icon={<IconTrash width={77} />}
          show={showDelete}
          confirmText="Delete"
          cancelText="Cancel"
          toggleModal={hideDeleteModal}
          title="Are you sure you want to delete this content?"
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. "
          onConfirm={onDelete}
        />
      )} */}
    </>
  )
}

CollapseHeader.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  onEdit: PropTypes.func,
  toggle: PropTypes.func
}

export default CollapseHeader
