import { switchFn } from './index'

export const DEFAULT_PLP_STEPS = [
  {
    id: 1,
    step: 'Complete the PLP survey',
    status: false
  },
  {
    id: 2,
    step:
      'Take part in a coaching session to review your profile',
    status: false
  },
  {
    id: 3,
    step:
      'Review PLP on your own and note potential development themes',
    status: false
  }
]

export const DEFAULT_360_STEPS = [
  {
    id: 1,
    step: 'Complete the 360 survey',
    status: false
  },
  {
    id: 2,
    step:
      'Take part in a coaching session to review your profile',
    status: false
  },
  {
    id: 3,
    step:
      'Review 360 on your own and note potential development themes',
    status: false
  }
]

export const DEFAULT_DEV_PLAN_STEPS = [
  {
    id: 1,
    step: 'Create your first goal',
    status: false
  },
  {
    id: 2,
    step: 'Rate yourself on your goal',
    status: false
  },
  {
    id: 3,
    step:
      'Rate yourself on your goal again and review progress below',
    status: false
  }
]

export const DEFAULT_STAKEHOLDERS_STEPS = [
  {
    id: 1,
    step: 'Add your Stakeholders',
    status: false
  },
  {
    id: 2,
    step:
      'Meet with each colleague to ‘enroll’ them as a stakeholder',
    status: false
  },
  {
    id: 3,
    step: 'Add your monthly check-ins with each stakeholder',
    status: false
  },
  {
    id: 4,
    step:
      'Take part in a coaching session to review your survey results',
    status: false
  }
]

export const COURSE_COMPONENTS = {
  PLP: 'Personality and Leadership Profile',
  PILLARS: 'Three Pillars of Leadership 360',
  DEV_PLAN: 'Development Plan',
  STAKEHOLDER: 'Stakeholder Engagement'
}

export const COACH_BASE_URL = '/coach/user-management/clients'

export const getCoachCourseComponents = userId => {
  const courseComponents = [
    {
      title: 'Personality and Leadership Profile',
      to: `${COACH_BASE_URL}/${userId}/plp`
    },
    {
      title: 'Three Pillars of Leadership 360',
      to: `${COACH_BASE_URL}/${userId}/three-pillars-of-leadership`
    },
    {
      title: 'Development Plan',
      to: `${COACH_BASE_URL}/${userId}/development-plan`
    },
    {
      title: 'Stakeholder Engagement',
      to: `${COACH_BASE_URL}/${userId}/stakeholder-engagement`
    }
  ]

  return courseComponents
}

export const getUserManagementCourseComponentLink = (
  courseComponent,
  userId
) => {
  const {
    PLP,
    PILLARS,
    DEV_PLAN,
    STAKEHOLDER
  } = COURSE_COMPONENTS

  return switchFn({
    [PLP]: `${COACH_BASE_URL}/${userId}/plp`,
    [PILLARS]: `${COACH_BASE_URL}/${userId}/three-pillars-of-leadership`,
    [DEV_PLAN]: `${COACH_BASE_URL}/${userId}/development-plan`,
    [STAKEHOLDER]: `${COACH_BASE_URL}/${userId}/stakeholder-engagement`
  })(null)(courseComponent)
}
