import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required(`Please enter email`),
  firstName: yup.string().required(`Please enter first name`),

  lastName: yup.string().required('Please enter last name')
})

const resolver = yupResolver(schema)

export { schema, resolver }
