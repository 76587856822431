import React from 'react'
import PropTypes from 'prop-types'
import { Col, Image } from 'react-bootstrap'

import { openLink } from 'utils/html/window'

import Card from 'components/Card'

import { $gray, $white } from 'utils/helpers/colors'
import styles from './styles.module.scss'
import { resourceTypes } from './constant'

const isLong = text => text?.length >= 100

const ResourceCard = ({
  title,
  content,
  description,
  url,
  siteName,
  siteIcon,
  type,
  sourceName,
  recommended,
  siteImage
}) => {
  const contentClasses = [`${styles.resourceContent} mb-5`]
  const recommendedWrapper = [
    'd-flex flex-column justify-content-between h-100'
  ]
  const recommendedContent = [
    'd-flex flex-column justify-content-between h-100'
  ]
  const isDocument = resourceTypes.DOCUMENT === type

  const cardDescription = content || description
  const checkTextLength = () => {
    if (isDocument) return isLong(description)
    return isLong(cardDescription)
  }

  const isLongText = checkTextLength()

  if (isLongText) {
    contentClasses.push(`${styles.floatReadMore}`)
  }

  if (recommended && siteImage) {
    recommendedContent.push(`${styles.recommendedContainer} `)
    recommendedWrapper.push('flex-xl-row')
  }

  return (
    <Col xs={12} md={4} className={`${styles.resourceCard}`}>
      <div className="h-100">
        <Card
          className={`${styles.card} p-20 h-100 `}
          style={{
            boxShadow: ' 4.6px 4.8px 10px rgba(0, 0, 0, 0.05)'
          }}
          onClick={() =>
            openLink({
              url,
              isExternalLink: true,
              newTab: true
            })
          }
        >
          <div className={recommendedWrapper.join(' ')}>
            {recommended && siteImage && (
              <Image
                src={siteImage}
                alt={`${title} image`}
                className={`${styles.siteImage} mr-xl-20 mb-20 mb-xl-0`}
              />
            )}
            <div className={recommendedContent.join(' ')}>
              <div>
                <div>
                  <p
                    className={`${styles.title} font-weight-bold mb-5`}
                  >
                    {title}
                  </p>
                  <p className={contentClasses.join(' ')}>
                    {cardDescription && (
                      <>
                        {isDocument
                          ? description
                          : cardDescription}
                        {isLongText && (
                          <span
                            style={{
                              background: `linear-gradient(90deg, rgba(2,0,36,0) 0%, ${
                                recommended ? $gray : $white
                              } 20%)`
                            }}
                          >
                            <br />
                          </span>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.siteDetails}>
                {siteIcon && (
                  <Image
                    src={siteIcon}
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: '10px'
                    }}
                  />
                )}
                <div className={styles.siteName}>
                  {sourceName || siteName}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Col>
  )
}

ResourceCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  content: PropTypes.string,
  siteName: PropTypes.string,
  siteIcon: PropTypes.string,
  recommended: PropTypes.bool,
  siteImage: PropTypes.string
}

export default ResourceCard
