import { COACH_ROLE, MEMBER_ROLE } from 'global/constants'
import { useGlobalState } from 'store/state'

const useUser = () => {
  const { user: userProxy } = useGlobalState()
  const user = userProxy.get()

  const { role, ...rest } = user
  const isMember = role === MEMBER_ROLE
  const isCoach = role === COACH_ROLE

  return {
    isMember,
    isCoach,
    role,
    user,
    userProxy,
    ...rest
  }
}

export { useUser }
