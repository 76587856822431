import React from 'react'
import { Col, Row, Card } from 'react-bootstrap'

import Container from 'components/Container'
import H2 from 'components/H2'

import { useQueryParams } from 'utils/html/queryParams' // test
import Logo from '../logo'
import styles from './styles.module.scss'

const Survey = () => {
  const queryParams = useQueryParams()
  const name = queryParams.get('name')

  return (
    <Container className={styles.surveyWrapper}>
      <Row className="justify-content-md-center">
        <Col xs lg="12" className="mb-20">
          <Card>
            <div className={styles.contentWrapper}>
              <Logo />
              <H2 className="text-primary font-weight-normal">
                Survey successfully submitted!
              </H2>
              <p>
                Thank you for sharing your feedback and
                supporting {name} in their development.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Survey.propTypes = {}

export default Survey
