import { useHistory } from 'react-router-dom'
import { useUser } from './useUser'

const useLogout = () => {
  const { userProxy } = useUser()
  const { push } = useHistory()

  function logout() {
    userProxy.set(null)
    return push('/')
  }

  return [logout]
}

export { useLogout }
