import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { queryClient } from 'store/state'

import { postSurvey, getSurveyDetails } from './api'
import { POST_SURVEY, GET_SURVEY_DETAILS } from './constants'

export const useSurvey = token => {
  const history = useHistory()

  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState(false)

  const {
    data,
    refetch,
    isLoading: getSurveyDetailsIsLoading
  } = useQuery(
    GET_SURVEY_DETAILS,
    () => getSurveyDetails(token),
    {
      onError: error => {
        if (error.statusCode === 500) {
          history.push('/stakeholders/survey/error')
        }
      },
      retry: false
    }
  )

  const {
    mutate: postsurvey,
    isSuccess: surveySuccess,
    isLoading: surveyLoading,
    isError: surveyError
  } = useMutation(postSurvey, {
    onSuccess: () => {
      queryClient.invalidateQueries(POST_SURVEY)
    },
    onError: error => {
      if (error.statusCode === 422) {
        history.push('/stakeholders/survey/error')
      } else {
        setShowToast(true)
        setMessage(error.message)
      }
    }
  })

  return {
    postsurvey,
    surveySuccess,
    surveyLoading,
    surveyError,

    showToast,
    setShowToast,
    message,

    surveyDetails: data,
    getName: refetch,
    getSurveyDetailsIsLoading
  }
}
