import { useQuery } from 'react-query'

import { GET_SIDEBAR_CONTENT } from './constants'
import {
  getCoachRightSidebarApi,
  getMemberRightSidebarApi
} from './api'

export const useRightSidebar = userId => {
  const hasUserId = !!userId

  const {
    data: sideBarData,
    refetch: getRightSideBarData
  } = useQuery(
    GET_SIDEBAR_CONTENT,
    hasUserId
      ? () => getCoachRightSidebarApi(userId)
      : getMemberRightSidebarApi
  )

  return {
    sideBarData,
    getRightSideBarData
  }
}
