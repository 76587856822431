import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getStakeholderEngagement = userId => {
  return getRequest(`/stakeholders/user/${userId}`)
}

export const editStepsApi = ({ userId, payload }) => {
  return putRequest(`/stakeholders/user/${userId}`, payload)
}

export const addStakeholderApi = ({ userId, payload }) => {
  return postRequest(
    `/stakeholders/user/${userId}/profiles`,
    payload
  )
}

export const editStakeholderApi = ({
  userId,
  stakeholderId,
  payload
}) => {
  return putRequest(
    `/stakeholders/user/${userId}/profiles/${stakeholderId}`,
    payload
  )
}

export const deleteStakeholderApi = ({
  userId,
  stakeholderId
}) => {
  return deleteRequest(
    `/stakeholders/user/${userId}/profiles/${stakeholderId}`
  )
}
