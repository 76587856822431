import React, { useEffect, useState } from 'react'
import {
  useHistory,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import { Col } from 'react-bootstrap'

import { ReactComponent as IconBack } from 'assets/icons/back.svg'
import { ReactComponent as IconEmail } from 'assets/icons/email.svg'
import { ReactComponent as IconCoachingDefault } from 'assets/icons/coaching-default.svg'
import { ReactComponent as IconCoachingActive } from 'assets/icons/coaching-active.svg'

import { useUserManagement } from 'global/hooks/useUserManagement'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import { getUserImage } from 'utils/helpers/component'
import {
  COACH_BASE_URL,
  getCoachCourseComponents
} from 'utils/helpers/course-component'
import { getLocationPathname } from 'utils/html/window'
import { getFileLink } from 'utils/helpers/file'

import DashboardWrapper from 'components/DashboardWrapper'
import TouchableOpacity from 'components/TouchableOpacity'

import CourseNav from './CourseNav'
import styles from './styles.module.scss'
import PLP from './PLP'
import ThreeSixty from './360'
import DevelopmentPlan from './DevelopmentPlan'
import RightSidebar from './RightSidebar'
import StakeholdersEngagement from './StakeholderEngagement'
import CoachingSessions from './CoachingSessions'
import { useUserById } from './hooks'
import ChangeClientModal from './ChangeClientModal'

const SingleUser = () => {
  useHeaderTitle('User Management')
  const history = useHistory()
  const { path } = useRouteMatch()
  const { userId } = useParams()
  const { user, userRefetch, addUserOkr } = useUserById(userId)
  const { allClients } = useUserManagement()
  const { email, firstName, lastName, profileImage } = user || {}
  const name = `${firstName} ${lastName}`
  const [showChangeModal, setShowChangeModal] = useState(false)

  const filteredClients = allClients?.filter(
    client => client.signedUp
  )

  const pathname = getLocationPathname()
  const coachingSessionUrl = `${COACH_BASE_URL}/${userId}/coaching-sessions`
  const coachingSessionIsActive = pathname === coachingSessionUrl

  const courseComponents = getCoachCourseComponents(userId)

  const backButtonClick = () => history.push(COACH_BASE_URL)

  const closeChangeModal = () => {
    setShowChangeModal(false)
  }

  const addOkr = payload => {
    addUserOkr({ userId, payload })
  }

  useEffect(() => {
    closeChangeModal()
    userRefetch()
  }, [userId, userRefetch])

  return (
    <div className={styles.singleUser}>
      <Col xs={12} md="9-5">
        <DashboardWrapper>
          <div className="d-flex justify-content-between mb-40">
            <div className="d-flex align-items-center">
              <TouchableOpacity onClick={backButtonClick}>
                <IconBack className="mr-15" />
              </TouchableOpacity>
              <TouchableOpacity
                onClick={() => setShowChangeModal(true)}
                className="d-flex align-items-center"
              >
                <div className="mr-10">
                  {getUserImage({
                    name,
                    image: profileImage
                      ? getFileLink(profileImage)
                      : null
                  })}
                </div>
                <div>
                  <div>{name}</div>
                  <div className="d-flex align-items-center">
                    <IconEmail className="mr-10" />
                    <div className="text-gray-dark">{email}</div>
                  </div>
                </div>
              </TouchableOpacity>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <CourseNav
              baseUrl={`${COACH_BASE_URL}/`}
              courseComponents={courseComponents}
            />
            <TouchableOpacity
              onClick={() => history.push(coachingSessionUrl)}
              className={`${styles.coachingSessionButton} ${
                coachingSessionIsActive ? styles.active : ''
              } d-flex align-items-center ml-20 p-20`}
            >
              <div className="mr-10">
                {coachingSessionIsActive ? (
                  <IconCoachingActive />
                ) : (
                  <IconCoachingDefault />
                )}
              </div>
              <div className="font-weight-bold">
                Coaching Sessions
              </div>
            </TouchableOpacity>
          </div>
          <Switch>
            <Route exact path={`${path}/plp`} component={PLP} />
            <Route
              exact
              path={`${path}/three-pillars-of-leadership`}
              component={ThreeSixty}
            />
            <Route
              exact
              path={`${path}/development-plan`}
              component={DevelopmentPlan}
            />
            <Route
              path={`${path}/stakeholder-engagement`}
              component={StakeholdersEngagement}
            />
            <Route path={`${path}/coaching-sessions`}>
              <CoachingSessions />
            </Route>
          </Switch>
        </DashboardWrapper>
      </Col>
      <Col
        xs={12}
        md="2-5"
        className={`${styles.rightSidebar} p-15 px-25 py-sm-40`}
      >
        <RightSidebar userId={userId} addOkr={addOkr} />
      </Col>
      <ChangeClientModal
        clients={filteredClients}
        currentUserId={userId}
        show={showChangeModal}
        closeModal={closeChangeModal}
      />
    </div>
  )
}

SingleUser.propTypes = {}

export default SingleUser
