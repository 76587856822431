import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { Col, Row } from 'react-bootstrap'
import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import Container from 'components/Container'
import memberStyles from 'pages/Member/styles.module.scss'
import PLP from './PLP'
import ThreeSixty from './360'
import DevelopmentPlan from './DevelopmentPlan'
import StakeholdersEngagement from './StakeholderEngagement'
import CourseNav from './CourseNav'
import RightSidebar from './RightSidebar'
import styles from './styles.module.scss'

const MemberDashboard = () => {
  const { path } = useRouteMatch()
  useHeaderTitle('Dashboard')

  return (
    <Container>
      <Row>
        <Col xs={12} xl="9-5" className="p-0">
          <div
            className={`${memberStyles.mainContent} pb-0 px-0 px-md-40`}
          >
            <CourseNav />
          </div>
          <div className={`${memberStyles.mainContent} pt-0`}>
            <Switch>
              <Route
                exact
                path={`${path}/plp`}
                component={PLP}
              />
              <Route
                exact
                path={`${path}/three-pillars-of-leadership`}
                component={ThreeSixty}
              />
              <Route
                exact
                path={`${path}/development-plan`}
                component={DevelopmentPlan}
              />
              <Route
                path={`${path}/stakeholder-engagement`}
                component={StakeholdersEngagement}
              />
            </Switch>
          </div>
        </Col>
        <Col
          xs={12}
          xl="2-5"
          className={`${styles.rightSidebar} px-25 py-sm-40`}
        >
          <RightSidebar />
        </Col>
      </Row>
    </Container>
  )
}

MemberDashboard.propTypes = {}

export default MemberDashboard
