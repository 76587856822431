import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { editProfile as editProfileAPI, getProfile } from './api'
import { GET_PROFILE } from './constants'

export const useProfile = () => {
  const [editProfileError, setEditProfileError] = useState(false)
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_PROFILE,
    getProfile
  )

  const {
    mutate: editProfile,
    isSuccess: editProfileSuccess,
    isLoading: editProfileLoading
  } = useMutation(editProfileAPI, {
    onSuccess: () => {
      setEditProfileError(false)
      return queryClient.invalidateQueries(GET_PROFILE)
    },
    onError: error => setEditProfileError(error.message)
  })

  return {
    data,
    isLoading,
    isSuccess,
    error,

    editProfile,
    editProfileSuccess,
    editProfileLoading,
    editProfileError,

    setEditProfileError
  }
}
