import React from 'react'
import PropTypes from 'prop-types'
import ReactSwitch from 'react-switch'

import { $grayDark, $primaryLight } from 'utils/helpers/colors'

const Switch = ({ checked, onChange, ...rest }) => {
  return (
    <ReactSwitch
      checked={checked}
      onChange={onChange}
      width={35}
      height={16}
      onColor={$primaryLight}
      offColor={$grayDark}
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={8}
      {...rest}
    />
  )
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Switch
