import React from 'react'
import * as ReactMultiSelect from 'react-multi-select-component'

const MultiSelect = ({
  options,
  value,
  onChange,
  labelledBy,
  ...rest
}) => {
  return (
    <ReactMultiSelect
      options={options}
      value={value}
      onChange={onChange}
      labelledBy={labelledBy}
      {...rest}
    />
  )
}

export default MultiSelect
