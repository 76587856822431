import React, { useRef, useState } from 'react'

import { MAX_FILE_SIZE } from './constants'

const Upload = ({
  id,
  inputRef,
  onFileChange,
  fileRestriction
}) => {
  return (
    <div className="d-none">
      <input
        id={id}
        type="file"
        ref={inputRef}
        onChange={onFileChange}
        accept={fileRestriction}
      />
    </div>
  )
}

export const useUpload = ({
  multiple = false,
  value,
  id,
  onFileChangeCallback
} = {}) => {
  const defaultValue = multiple ? [] : ''
  const defaultState = value?.length ? value : defaultValue

  const [uploadedData, setUploadedData] = useState(defaultState)
  const inputRef = useRef(null)

  const onFileChange = e => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      /* eslint-disable no-alert */
      /* eslint-disable no-undef */
      return alert('File must not exceed 5mb')
    }

    if (multiple) {
      if (e.target.files.length) {
        const fileArray = [
          ...uploadedData,
          {
            id: uploadedData.length + 1,
            file: e.target.files[0]
          }
        ]
        setUploadedData(fileArray)
        return onFileChangeCallback(fileArray)
      }
    }

    if (e.target.files.length) {
      setUploadedData(e.target.files[0])
      return onFileChangeCallback(e.target.files[0])
    }

    setUploadedData(null)
    return onFileChangeCallback(null)
  }

  const removeFile = (fileId = null) => {
    if (multiple) {
      const filteredData = uploadedData.filter(
        x => x.id !== fileId
      )
      return setUploadedData(filteredData)
    }

    return setUploadedData(null)
  }

  const openFileExplorer = () => inputRef?.current.click()
  const FileInput = () => (
    <Upload
      inputRef={inputRef}
      id={id}
      onFileChange={onFileChange}
    />
  )

  return {
    uploadedData,
    FileInput,
    openFileExplorer,
    removeFile
  }
}

export default Upload
