import { useQuery } from 'react-query'

import { GET_COURSE_STATUS } from './constants'
import {
  getMemberCourseStatusApi,
  getCoachCourseStatusApi
} from './api'

export const useCourseStatus = userId => {
  const hasUserId = !!userId

  const {
    data: courseStatusData,
    refetch: getCourseStatusData
  } = useQuery(
    GET_COURSE_STATUS,
    hasUserId
      ? () => getCoachCourseStatusApi(userId)
      : getMemberCourseStatusApi
  )

  return {
    courseStatusData,
    getCourseStatusData
  }
}
