import React, { useState } from 'react'
import { head } from 'lodash'

import { ReactComponent as IconHelp } from 'assets/icons/help.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import { formatDate } from 'utils/helpers/date'

import Table from 'components/Table'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'
import StyledCheckbox from 'components/StyledCheckbox'
import TouchableOpacity from 'components/TouchableOpacity'
import Tooltip from 'components/Tooltip'

import EditStakeholder from './EditStakeholder'
import styles from './styles.module.scss'
import { ENROLLED_TOOLTIP } from './messages'

const DesktopTable = ({
  data,
  handleDeleteStakeholder,
  handleToggleEnrolled
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [modalData, setModalData] = useState({
    id: null,
    name: '',
    email: '',
    relationship: ''
  })
  return (
    <Table>
      <thead>
        <tr>
          <th className={styles.stakeholderNameColumn}>Name</th>
          <th className={styles.stakeholderEmailColumn}>
            Email Address
          </th>
          <th className={styles.stakeholderRelationshipColumn}>
            Relationship
          </th>
          <th>
            <span className="mr-8">Enrolled</span>
            <Tooltip tip={ENROLLED_TOOLTIP}>
              <IconHelp />
            </Tooltip>
          </th>
          <th style={{ minWidth: 150 }}>Date Added</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data?.map(stakeholder => {
          const initial = head(stakeholder.name)

          return (
            <tr key={stakeholder.id}>
              <td className={styles.stakeholderNameColumn}>
                <div className="d-flex align-items-center">
                  <div className="mr-10">
                    <ThumbnailPlaceholder initial={initial} />
                  </div>
                  <span className={styles.stakeholderName}>
                    {stakeholder.name}
                  </span>
                </div>
              </td>
              <td className={styles.stakeholderEmailColumn}>
                {stakeholder.email}
              </td>
              <td
                className={styles.stakeholderRelationshipColumn}
              >
                {stakeholder.relationship}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <StyledCheckbox
                    className="mb-0"
                    controlId={`keyEnrolledId${stakeholder.id}`}
                    checked={stakeholder.enrolled}
                    onChange={() =>
                      handleToggleEnrolled(stakeholder.id, {
                        ...stakeholder,
                        enrolled: !stakeholder.enrolled
                      })
                    }
                  />
                </div>
              </td>
              <td>
                {formatDate({
                  format: 'MMM DD, YYYY',
                  date: stakeholder.dateAdded
                })}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <TouchableOpacity
                    className="mr-10"
                    onClick={() => {
                      setModalData(stakeholder)
                      setShowEditModal(true)
                    }}
                  >
                    <IconEdit />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onClick={() =>
                      handleDeleteStakeholder(stakeholder.id)
                    }
                  >
                    <IconDelete />
                  </TouchableOpacity>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
      <EditStakeholder
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modalData={modalData}
      />
    </Table>
  )
}

export default DesktopTable
