import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

const isExternalLink = url => {
  function checkDomain(url) {
    const newUrl =
      url.indexOf('//') === 0
        ? `${window.location.protocol}${url}`
        : url

    return newUrl
      .toLowerCase()
      .replace(/([a-z])?:\/\//, '$1')
      .split('/')[0]
  }
  if (!url) return url
  return (
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    checkDomain(window.location.href) !== checkDomain(url)
  )
}

const DynamicLink = ({ to, children, newTab, ...rest }) => {
  if (!to) return null
  const target = newTab ? '_blank' : '_self'
  return isExternalLink(to) ? (
    <a href={to} {...rest} target={target}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} target={target} {...rest}>
      {children}
    </RouterLink>
  )
}

DynamicLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired
}

export default DynamicLink
