import { ReactComponent as ActivityFeedIcon } from 'assets/icons/coach-sidebar/activity-icon.svg'
import { ReactComponent as ActivityFeedActiveIcon } from 'assets/icons/coach-sidebar/activity-active.svg'
import { ReactComponent as UserManagementIcon } from 'assets/icons/coach-sidebar/user-icon.svg'
import { ReactComponent as UserManagementActiveIcon } from 'assets/icons/coach-sidebar/user-active.svg'
import { ReactComponent as LibraryIcon } from 'assets/icons/member-sidebar/articles-icon.svg'
import { ReactComponent as WorkshopsIcon } from 'assets/icons/member-sidebar/workshops-icon.svg'
import { ReactComponent as LibraryActiveIcon } from 'assets/icons/member-sidebar/articles-active.svg'
import { ReactComponent as WorkshopsActiveIcon } from 'assets/icons/member-sidebar/workshops-active.svg'
import { Routes } from 'global/routes'

import { ROUTES } from 'pages/Coach/constants'

const iconLibrary = {
  [Routes.COACH_ACTIVITY_FEED]: {
    inactive: ActivityFeedIcon,
    active: ActivityFeedActiveIcon
  },
  [Routes.COACH_USER_MANAGEMENT]: {
    inactive: UserManagementIcon,
    active: UserManagementActiveIcon
  },
  [Routes.COACH_RESOURCE_LIBRARY]: {
    inactive: LibraryIcon,
    active: LibraryActiveIcon
  },
  [Routes.COACH_WORKSHOPS]: {
    inactive: WorkshopsIcon,
    active: WorkshopsActiveIcon
  }
}

function withIcon(data) {
  return data.map(item => ({
    ...item,
    icon: {
      inactive: iconLibrary[item.to].inactive,
      active: iconLibrary[item.to].active
    }
  }))
}

export default withIcon(ROUTES)
