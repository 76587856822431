import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import DashboardWrapper from 'components/DashboardWrapper'
import SimpleTopNavbar from 'components/SimpleTopNavbar'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import PersonalInformation from './PersonalInformation'
import ChangePassword from './ChangePassword'

const Profile = () => {
  const { path } = useRouteMatch()
  useHeaderTitle('Profile')

  const PROFILE_ROUTES = [
    {
      title: 'Personal Information',
      to: '/my-profile'
    },
    {
      title: 'Change Password',
      to: '/my-profile/change-password'
    }
  ]

  return (
    <DashboardWrapper>
      <div className="mb-20">
        <SimpleTopNavbar routes={PROFILE_ROUTES} />
      </div>

      <Switch>
        <Route
          exact
          path={path}
          component={PersonalInformation}
        />
        <Route
          exact
          path={`${path}/change-password`}
          component={ChangePassword}
        />
      </Switch>
    </DashboardWrapper>
  )
}

Profile.propTypes = {}

export default Profile
