export const Routes = {
  HOME_PAGE: '/',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD: '/dashboard',
  COACHING_SESSIONS: '/coaching-sessions',
  OBJECTIVES_AND_KEY_RESULTS: '/objectives-and-key-results',
  WORKSHOPS: '/workshops',
  RESOURCE_LIBRARY: '/resource-library',
  MY_PROFILE: '/my-profile',
  SETTINGS: '/settings',

  // Coach
  COACH_ACTIVITY_FEED: '/coach/activity-feed',
  COACH_USER_MANAGEMENT: '/coach/user-management',
  COACH_WORKSHOPS: '/coach/workshops',
  COACH_RESOURCE_LIBRARY: '/coach/resource-library',
  COACH_SETTINGS: '/coach/settings',

  // Survey
  SURVEY: '/stakeholders/survey',
  SURVEY_SUCCESS: '/stakeholders/survey/success',
  SURVEY_ERROR: '/stakeholders/survey/error'
}
