import moment from 'moment'
import momentTimezone from 'moment-timezone'

export const deviceTimezone = Intl.DateTimeFormat().resolvedOptions()
  .timeZone

export const formatDate = ({ format = 'MM-DD-YYYY', date }) => {
  return moment(date).format(format)
}

export const getFirstDayOfCurrentMonth = () => {
  return moment().clone().startOf('month').format()
}

export const dateToISO = ({ date, formatFrom }) =>
  moment(date, formatFrom).format()

export const dateToRelativeTime = ({ date }) =>
  moment(date).fromNow()

export const dateToUTCISO = ({ date, format }) => {
  const dateformateIs = momentTimezone
    .tz(date, deviceTimezone)
    .utcOffset(0, true)
    .format(format)

  return dateformateIs
}
