import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import AddButton from 'components/Button/AddButton'
import SelectCustom from 'components/SelectCustom'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Switch from 'components/Switch'
import { NameWithThumb } from 'components/TableRenderers'

import { useStakeholderContext } from '../constants'

import styles from './styles.module.scss'
import { useProgressCheckin } from './hooks'

const SendSurvey = ({ stakeholderData }) => {
  const { userId } = useParams()
  const {
    stakeholderRefetch,
    postSurvey,
    editProgressCheck
  } = useProgressCheckin()

  const { progressCheckData } = useStakeholderContext()

  const { handleSubmit } = useForm()

  const [showModal, setShowModal] = useState(false)
  const [shareToClient, setShareToClient] = useState(null)
  const [clients, setClients] = useState([])
  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  const { stakeholderProfiles = [] } = stakeholderData || {}

  const stakeholderList = stakeholderProfiles?.map(x => ({
    label: x.name,
    value: x.email
  }))

  const submit = async () => {
    const profilesArr = {
      id: userId,
      data: { stakeholderEmails: [] }
    }
    for (let i = 0; i < clients.length; i++) {
      profilesArr.data.stakeholderEmails.push(clients[i].value)
    }
    await postSurvey(profilesArr)
    setShowModal(false)
  }

  const onSwitchChange = value => {
    setShareToClient(value)
    editProgressCheck({
      userId,
      miniSurveyId: progressCheckData.id
    })
  }

  useEffect(() => {
    setShareToClient(progressCheckData?.sharedToClient)
  }, [progressCheckData])

  useEffect(() => {
    stakeholderRefetch()
  }, [stakeholderRefetch])

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="d-flex mr-30 align-items-center">
          {progressCheckData?.surveyAnswers && (
            <>
              <p className="mr-10 mb-0">Share to Client</p>
              <Switch
                checked={shareToClient || false}
                onChange={onSwitchChange}
              />
            </>
          )}
        </div>
        <AddButton label="Send Survey" onClick={openModal} />
      </div>
      <Modal show={showModal} closeModal={closeModal}>
        <Form>
          <fieldset>
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <p className={styles.modalTitle}>
                      Send mini survey to the stakeholders below.
                    </p>
                    {/* <p className="text-gray-dark mb-20">
                      Sed ut perspiciatis unde omnis iste natus
                      error sit voluptatem
                    </p> */}
                  </Col>
                </div>
                <div className="d-flex align-items-start">
                  {clients.map((selected, i) => {
                    return (
                      <Col xs={2} key={i}>
                        <div
                          className={styles.avatar}
                          onClick={() => {
                            setClients(
                              clients.filter(
                                item =>
                                  item.value !== selected.value
                              )
                            )
                          }}
                        >
                          <span
                            type="submit"
                            className={styles.close}
                          >
                            <span>&times;</span>
                          </span>
                          <NameWithThumb
                            className={styles.selectedClient}
                            imageWrapperClass={
                              styles.imageWrapper
                            }
                            name={`${selected.label}`}
                          />
                        </div>
                      </Col>
                    )
                  })}
                </div>
                <div className="d-flex align-items-center mt-40 mb-10">
                  <Col xs={12}>
                    <SelectCustom
                      isMulti
                      placeholder="Select Stakeholders"
                      menuIsOpen
                      closeMenuOnSelect={false}
                      options={stakeholderList}
                      searchable
                      value={clients}
                      controlShouldRenderValue={false}
                      onChange={e => setClients(e)}
                    />
                  </Col>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    variant="link"
                    smallPadding
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    smallPadding
                    type="submit"
                    onClick={handleSubmit(submit)}
                  >
                    Send Survey
                  </Button>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </Modal>
    </>
  )
}

SendSurvey.propTypes = {}

export default SendSurvey
