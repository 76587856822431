import React from 'react'
import { head, isEmpty } from 'lodash'

import Thumbnail from 'components/Thumbnail'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'

export const getUserImage = ({ name, image }, size = 40) => {
  const initial = head(name)
  return !isEmpty(image) ? (
    <Thumbnail src={image} size={size} />
  ) : (
    <ThumbnailPlaceholder initial={initial} size={size} />
  )
}
