import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { ReactComponent as IconEmail } from 'assets/icons/email.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import TouchableOpacity from 'components/TouchableOpacity'
import CardCollapse from 'components/CardCollapse'
import FormCheck from 'components/Form/FormCheck'
import { UnorderedList } from 'components/UnorderedList'

import { useSession } from 'pages/Coach/UserManagement/SingleUser/CoachingSessions/hooks'

import ReactHtmlParser from 'react-html-parser'
import CollapseHeader from './CollapseHeader'

const Plain = ({
  title,
  coachNotes,
  date,
  agendas,
  notes,
  actionSteps,
  userImage,
  isOpen,
  toggle,
  editToggle,
  sessionId,
  handleDeleteSession,
  creator
}) => {
  const { userId } = useParams()
  const hasAgendas = !!agendas.length
  const hasActionSteps = !!actionSteps.length

  const { editSession } = useSession(userId)

  const handleEditCheck = ({ index }) => {
    const newSteps = actionSteps.map((step, stepIndex) => ({
      ...step,
      status: stepIndex === index ? !step.status : step.status
    }))

    return editSession({
      userId,
      id: sessionId,
      payload: {
        steps: newSteps
      }
    })
  }

  const handleDelete = () => {
    handleDeleteSession(sessionId)
  }
  return (
    <CardCollapse
      className="mb-10 mb-md-20"
      headerComponent={
        <CollapseHeader
          title={title}
          date={date}
          onDelete={handleDelete}
          onEdit={editToggle}
          toggle={toggle}
          isOpen={isOpen}
        />
      }
      isOpen={isOpen}
    >
      <div className="agendas mb-20">
        {hasAgendas && (
          <>
            <div className="font-weight-medium text-primary mb-10">
              Agenda:
            </div>
            <UnorderedList items={agendas} />
          </>
        )}
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <div>{ReactHtmlParser(notes)}</div>
      </div>
      <div className="notes mb-20">
        {hasActionSteps && (
          <>
            <div className="font-weight-medium text-primary mb-10">
              Action Steps:
            </div>
            {actionSteps.map((step, index) => (
              <FormCheck
                onChange={() => handleEditCheck({ index })}
                key={`actionStep-${sessionId}-${index}`}
                controlId={`actionStep-${sessionId}-${index}`}
                label={step.description}
                defaultChecked={step.status}
              />
            ))}
          </>
        )}
      </div>
      <div className="user-wrapper d-flex mb-20">
        <div className="mr-15">{userImage}</div>
        <div className="name-wrapper d-flex justify-content-between w-100">
          <div>
            <div>{creator.name}</div>
            <div>
              <IconEmail className="mr-10" />
              <span>{creator.email}</span>
            </div>
          </div>
          <TouchableOpacity
            onClick={editToggle}
            className="d-md-none"
          >
            <IconEdit />
          </TouchableOpacity>
        </div>
      </div>
      <div className="coach-notes mb-40">
        <div className="font-weight-medium text-primary mb-10">
          Coach Notes:
        </div>
        <div>{ReactHtmlParser(coachNotes)}</div>
      </div>
    </CardCollapse>
  )
}

Plain.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  agendas: PropTypes.array,
  notes: PropTypes.string,
  actionSteps: PropTypes.array,
  userImage: PropTypes.node,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default Plain
