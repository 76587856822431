import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import { REMINDER_DEFAULT_SETTING } from 'global/constants'
import { useUserSettings } from 'global/hooks/useUserSettings'

import DashboardWrapper from 'components/DashboardWrapper'
import Card from 'components/Card'
import Switch from 'components/Switch'

import coachStyles from '../styles.module.scss'

const validateAndTransformPayload = async (
  data,
  toggledReminderType
) => {
  const {
    email,
    clientAddsGoal,
    clientAddsStakeholder,
    clientAddsCheckin,
    clientAddsOrUpdateCoachingSession
  } = data

  const hasNotifActive = !!email
  const hasCoachNotif =
    clientAddsGoal ||
    clientAddsStakeholder ||
    clientAddsCheckin ||
    clientAddsOrUpdateCoachingSession

  if (hasNotifActive && !hasCoachNotif && toggledReminderType) {
    return REMINDER_DEFAULT_SETTING
  }

  if (!hasNotifActive && !toggledReminderType) {
    return REMINDER_DEFAULT_SETTING
  }

  if (!hasNotifActive && hasCoachNotif && toggledReminderType) {
    return { ...data, email: true }
  }

  if (hasNotifActive && !hasCoachNotif) {
    return { ...data, clientAddsGoal: true }
  }

  return data
}

const Settings = () => {
  const {
    fetchSettings,
    data,
    updateSettings
  } = useUserSettings()

  const {
    email,
    clientAddsGoal,
    clientAddsStakeholder,
    clientAddsCheckin,
    clientAddsOrUpdateCoachingSession
  } = data || {}

  const onSubmit = async (name, value, toggledReminderType) => {
    const temp = data || REMINDER_DEFAULT_SETTING
    delete temp.id
    temp[name] = value

    const payload = await validateAndTransformPayload(
      temp,
      toggledReminderType
    )
    updateSettings(payload)
  }

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])
  return (
    <DashboardWrapper>
      <div className={`${coachStyles.titleWrapper} mb-10`}>
        <p className={`${coachStyles.sectionTitle} mb-0`}>
          Notification Settings
        </p>
      </div>
      <Card className="p-30 mb-2">
        <div className="font-weight-medium text-primary mb-15">
          Receive Reminders:
        </div>
        <Row>
          <Col xs={6}>Email Notifications</Col>
          <Col xs={6}>
            <Switch
              checked={email}
              onChange={e => onSubmit('email', e)}
            />
          </Col>
        </Row>
      </Card>
      <Card className="p-30 mb-2">
        <div className="font-weight-medium text-primary mb-15">
          Reminder Types:
        </div>
        <Row className="mb-20">
          <Col xs={6}>
            <div>Client adds a goal</div>
          </Col>
          <Col xs={6}>
            <Switch
              checked={clientAddsGoal}
              onChange={e => onSubmit('clientAddsGoal', e, true)}
            />
          </Col>
        </Row>
        <Row className="mb-20">
          <Col xs={6}>
            <div>Client adds one or more stakeholders</div>
          </Col>
          <Col xs={6}>
            <Switch
              checked={clientAddsStakeholder}
              onChange={e =>
                onSubmit('clientAddsStakeholder', e, true)
              }
            />
          </Col>
        </Row>
        <Row className="mb-20">
          <Col xs={6}>
            <div>Clients add a stakeholder check-in</div>
          </Col>
          <Col xs={6}>
            <Switch
              checked={clientAddsCheckin}
              onChange={e =>
                onSubmit('clientAddsCheckin', e, true)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div>Clients add/update a coaching session</div>
          </Col>
          <Col xs={6}>
            <Switch
              checked={clientAddsOrUpdateCoachingSession}
              onChange={e =>
                onSubmit(
                  'clientAddsOrUpdateCoachingSession',
                  e,
                  true
                )
              }
            />
          </Col>
        </Row>
      </Card>
    </DashboardWrapper>
  )
}

Settings.propTypes = {}

export default Settings
