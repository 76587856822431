import React, { useEffect } from 'react'
import * as yup from 'yup'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import BottomRightPeakLogo from 'assets/images/peak-logo-bottom-right.png'
import PeakLogo from 'assets/images/peak-logo-black.png'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import Container from 'components/Container'
import H1 from 'components/H1'
import H2 from 'components/H2'
import PageLoader from 'components/PageLoader'

import styles from './styles.module.scss'
import { useSendEmail } from './hooks'

const sendMailFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required('Please enter your email')
})

const SendEmail = () => {
  const history = useHistory()

  const {
    sendMail,
    isSuccess: sendMailSuccess,
    isLoading: sendMailLoading,
    error
  } = useSendEmail()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(sendMailFormSchema)
  })

  const sendMailFormSubmit = data => {
    const { email } = data
    const payload = {
      email
    }
    sendMail(payload)
  }

  useEffect(() => {
    if (sendMailSuccess) {
      history.push('/')
    }
  }, [sendMailSuccess, history])

  return (
    <PageLoader>
      <Container className={styles.sendMailWrapper}>
        <Row className={styles.row}>
          <Col xs="7-5" className={styles.leftWrapper}>
            <div className={styles.contentWrapper}>
              <Image src={PeakLogo} className="mb-15" />
              <H1 className="text-primary font-weight-normal mb-40">
                Leadership Development Portal
              </H1>
              <H2 className="mb-20">Forgot Password?</H2>
              <Form
                className={styles.form}
                onSubmit={handleSubmit(sendMailFormSubmit)}
              >
                <FormControl
                  inputRef={register}
                  type="email"
                  name="email"
                  placeholder="Email"
                  error={errors.email}
                />
                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    type="submit"
                    variant="secondary"
                    className="text-white"
                  >
                    {sendMailLoading ? 'Loading' : 'Send'}
                  </Button>
                </div>
                {error && (
                  <div className="text-danger mt-10">
                    {error.message}
                  </div>
                )}
              </Form>
            </div>
          </Col>
          <Col
            xs="4-5"
            className={`${styles.rightWrapper} bg-primary-dark text-white`}
          >
            <Image
              src={BottomRightPeakLogo}
              className={styles.bottomRightImage}
            />
            <div className={styles.contentWrapper}>
              <H1 className="mb-10">
                Let&apos;s start your journey
              </H1>
              <div className={styles.divider} />
              <p>
                Leadership Effectiveness Drives Every Measure of
                Organizational Success
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLoader>
  )
}

SendEmail.propTypes = {}

export default SendEmail
