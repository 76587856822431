import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { isEmpty } from 'lodash'

import getRawData from 'utils/hookstate/getRawData'

import Login from 'pages/Login'
import Member from 'pages/Member'
import Coach from 'pages/Coach'
import Survey from 'pages/Survey'
import SurveySuccess from 'pages/Survey/Success'
import SurveyError from 'pages/Survey/Error'
import Signup from 'pages/Signup'
import ForgotPassword from 'pages/ForgotPassword'
import ChangePassword from 'pages/ForgotPassword/ChangePassword'

import { Routes } from 'global/routes'

import { queryClient, useGlobalState } from 'store/state'

const App = () => {
  const { user: proxyUser } = useGlobalState()
  const user = getRawData(proxyUser)
  const isLoggedIn = !isEmpty(user)

  const renderRoleBasedSwitches = () => {
    if (!isLoggedIn)
      return (
        <>
          <Switch>
            <Route
              exact
              path={Routes.HOME_PAGE}
              component={Login}
            />
            <Route
              exact
              path={Routes.SURVEY}
              component={Survey}
            />
            <Route
              exact
              path={Routes.SURVEY_SUCCESS}
              component={SurveySuccess}
            />
            <Route
              exact
              path={Routes.SURVEY_ERROR}
              component={SurveyError}
            />
            <Route
              exact
              path={Routes.SIGNUP}
              component={Signup}
            />
            <Route
              exact
              path={Routes.FORGOT_PASSWORD}
              component={ForgotPassword}
            />
            <Route
              exact
              path={Routes.CHANGE_PASSWORD}
              component={ChangePassword}
            />
            <Route
              path={Routes.CHANGE_PASSWORD}
              component={ChangePassword}
            />
            <Redirect to={Routes.HOME_PAGE} />
          </Switch>
        </>
      )

    return {
      member: <Member />,
      coach: <Coach />
    }[user.role]
  }

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route
            path={Routes.HOME_PAGE}
            render={renderRoleBasedSwitches}
          />
        </Switch>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
