import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { ReactComponent as ImagePdf } from 'assets/images/pdf-empty.svg'

import { getPercentage } from 'utils/helpers'
import { getFileLink } from 'utils/helpers/file'
import { DEFAULT_PLP_STEPS } from 'utils/helpers/course-component'

import Card from 'components/Card'
import PdfViewer from 'components/PdfViewer'
import Switch from 'components/Switch'
import Button from 'components/Button'
import Upload from 'components/Upload'
import ProgressCard from 'components/ProgressCard'

import singleUserStyles from 'pages/Coach/UserManagement/SingleUser/styles.module.scss'

import { usePersonality } from './hooks'

const PLP = () => {
  const { userId } = useParams()
  const {
    userPersonality,
    getUserPersonality,
    updatePersonalityPdf,
    updatePersonalityPdfLoading,
    updatePersonality
  } = usePersonality(userId)
  const [mutableSteps, setMutableSteps] = useState([])
  const [shareToClient, setShareToClient] = useState(null)
  const fileRef = useRef(null)

  const hasFile = userPersonality && userPersonality.file

  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
    updatePersonality({
      userId,
      payload: { steps: sortedSteps }
    })
  }

  const onFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      const formData = new FormData()

      formData.append('pdfDocument', file)
      updatePersonalityPdf({
        userId,
        filePayload: formData,
        isDelete: false
      })
    } else {
      updatePersonalityPdf({
        userId,
        isDelete: true
      })
    }
  }

  const onSwitchChange = value => {
    setShareToClient(value)
    updatePersonality({
      userId,
      payload: {
        sharedToClient: value
      }
    })
  }

  const removeFile = () =>
    updatePersonalityPdf({
      userId,
      editPayload: {
        sharedToClient: false
      },
      isDelete: true
    })

  useEffect(() => {
    const steps =
      userPersonality && !isEmpty(userPersonality.steps)
        ? userPersonality.steps
        : DEFAULT_PLP_STEPS

    setMutableSteps(steps)
    setShareToClient(userPersonality?.sharedToClient)
  }, [userPersonality])

  useEffect(() => {
    getUserPersonality()
  }, [getUserPersonality])

  useEffect(() => {
    getUserPersonality()
  }, [userId, getUserPersonality])

  return (
    <div className={singleUserStyles.userManagement}>
      <Row className="mb-40">
        <Col xs={6} className={singleUserStyles.courseProgress}>
          <ProgressCard
            steps={mutableSteps}
            progress={progress}
            onChange={stepOnChange}
          />
        </Col>
        <Col xs={6}>
          <Card outlined className="h-100">
            This is a personality test which measures 42
            dimensions of personality, predicts a 360, identifies
            personality traits and patterns which impact
            management and leadership, and provides management
            and leadership advice.
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-between align-items-center mb-10">
            <p
              className={`${singleUserStyles.sectionTitle} mb-0`}
            >
              Personality and Leadership Profile
            </p>
            {hasFile && (
              <div className="d-flex align-items-center">
                <div className="d-flex mr-30 align-items-center">
                  <p className="mr-10 mb-0">Share to Client</p>
                  <Switch
                    checked={shareToClient}
                    onChange={onSwitchChange}
                  />
                </div>
                <Button variant="secondary" onClick={removeFile}>
                  Remove File
                </Button>
              </div>
            )}
          </div>
          {hasFile ? (
            <PdfViewer src={getFileLink(userPersonality.file)} />
          ) : (
            <Card
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ minHeight: 350 }}
            >
              <ImagePdf className="mb-20" />
              <div className="text-gray-dark">
                Looks like you have not sent through the
                Personality and Leadership Guide Profile
              </div>
              <div className="text-gray-dark mb-20">
                PDF. Upload and send the file here to help the
                user get started on their journey.
              </div>
              <Upload
                inputRef={fileRef}
                onFileChange={onFileChange}
              />
              <Button
                variant="secondary"
                smallPadding
                onClick={() => fileRef?.current.click()}
                disabled={updatePersonalityPdfLoading}
              >
                {updatePersonalityPdfLoading
                  ? 'Loading'
                  : 'Upload PDF'}
              </Button>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  )
}

PLP.propTypes = {}

export default PLP
