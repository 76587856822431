import React from 'react'
import PropTypes from 'prop-types'
import { Modal as BSModal } from 'react-bootstrap'

import { ReactComponent as IconClose } from 'assets/icons/delete-modal.svg'
import { ReactComponent as BackIcon } from 'assets/icons/back.svg'

import TouchableOpacity from 'components/TouchableOpacity'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import { noop } from 'lodash'
import styles from './styles.module.scss'

const Modal = ({
  children,
  bodyClassName,
  closeModal,
  className,
  show,
  title,
  mimicNative,
  closeOnBlur = true
}) => {
  const { isSmallOnlyScreen } = useDeviceSize()
  const classes = [`${styles.modal}`]
  if (className) classes.push(`${className}`)
  if (mimicNative) classes.push(`${styles.mimicNative}`)

  const bodyClasses = ['position-relative', 'p-sm-40']
  if (bodyClassName) bodyClasses.push(`${bodyClassName}`)
  if (mimicNative) bodyClasses.push(`${styles.nativeBody}`)

  /**
   * Due to the complexity of the design we're only going to use <Modal.Header />
   * with @param mimicNative property and is only visible on mobile devices.
   *
   * */

  return (
    <BSModal
      className={classes.join(' ')}
      centered
      show={show}
      onHide={closeModal}
    >
      {title && mimicNative && isSmallOnlyScreen && (
        <BSModal.Header className={styles.header}>
          <TouchableOpacity
            className={styles.back}
            onClick={closeOnBlur ? closeModal : noop}
          >
            <BackIcon />
          </TouchableOpacity>
          <BSModal.Title className={styles.headerTitle}>
            {title}
          </BSModal.Title>
        </BSModal.Header>
      )}

      <BSModal.Body className={bodyClasses.join(' ')}>
        {!title && (
          <TouchableOpacity
            className={styles.close}
            onClick={closeModal}
          >
            <IconClose />
          </TouchableOpacity>
        )}
        {children}
      </BSModal.Body>
    </BSModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  show: PropTypes.bool
}

export default Modal
