import { getRequest, postRequest, putRequest } from 'utils/http'

export const getUserPillarApi = id => {
  return getRequest(`/pillars/user/${id}`)
}

export const updatePillarPdfApi = async ({
  userId,
  filePayload,
  editPayload,
  isDelete
}) => {
  if (!isDelete) {
    const uploadedFile = await postRequest(
      `/pillars/${userId}/file`,
      filePayload
    )

    return putRequest(`/pillars/user/${userId}`, {
      ...editPayload,
      file: uploadedFile.filepath
    })
  }

  return putRequest(`/pillars/user/${userId}`, {
    ...editPayload,
    file: null
  })
}

export const updatePillarApi = ({ userId, payload }) => {
  return putRequest(`/pillars/user/${userId}`, payload)
}
