/* eslint-disable radix */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce, toPairs } from 'lodash'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel
} from 'victory'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import {
  axisStyle,
  axisStroke,
  victoryBarStyle
} from './chartStyles'

import styles from './styles.module.scss'

function transformData(data) {
  const result = toPairs(data).map(([x, y]) => {
    return { x, y, label: y || null }
  })
  result.sort((a, b) => {
    return a.x - b.x
  })
  return result
}

// Screen size by domain padding ratio - 320x20 or 16:1
// 16:1 ratio is 6.25%
const DOMAIN_SCALE = 6.25 / 100

const ColumnChart = ({ data, needSpacing = false }) => {
  const { isMediumOnlyScreen, isXLScreen } = useDeviceSize()
  const chartContainerRef = useRef()
  const [chartWidth, setChartWidth] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  function getPaddingProp() {
    return isMediumOnlyScreen
      ? {
          padding: { left: 20, top: 30, bottom: 40 },
          domainPadding: { x: windowWidth * DOMAIN_SCALE }
        }
      : { domainPadding: { x: 50 } }
  }

  function getBarProps() {
    return isXLScreen ? { barWidth: 50 } : { barRatio: 0.8 }
  }

  useEffect(() => {
    // Set initial window width
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartWidth(chartContainerRef.current.offsetWidth)
    }

    const debounced = debounce(
      () => {
        setWindowWidth(window.innerWidth)
      },
      250,
      { maxWait: 500 }
    )

    window.addEventListener('resize', debounced)

    return () => {
      window.removeEventListener('resize', debounced)
    }
  }, [windowWidth])

  return (
    <div
      ref={chartContainerRef}
      style={{ marginTop: needSpacing && '-30px' }}
    >
      <VictoryChart
        {...getPaddingProp()}
        width={chartWidth}
        containerComponent={<VictoryContainer responsive />}
      >
        <VictoryAxis
          dependentAxis
          style={{ ...axisStyle, grid: axisStroke }}
          tickFormat={t => Number(t)}
        />
        <VictoryAxis style={axisStyle} />
        <VictoryBar
          data={transformData(data)}
          style={victoryBarStyle}
          labelComponent={<VictoryLabel dy={20} />}
          {...getBarProps()}
        />
      </VictoryChart>
      <div
        className={`${styles.subLabel} d-flex justify-content-between px-md-40 mb-md-10 text-center`}
      >
        <div className="px-10">Less Effective</div>
        <div className="px-10">No Change</div>
        <div className="px-10">More Effective</div>
      </div>
    </div>
  )
}

ColumnChart.propTypes = {
  data: PropTypes.object
}

export default ColumnChart
