import React from 'react'
import { Image } from 'react-bootstrap'
import PeakLogo from 'assets/images/peak-logo-black.png'

export default function logo() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Image
        src={PeakLogo}
        style={{
          width: 113,
          margin: '0px auto 15px'
        }}
      />
    </div>
  )
}
