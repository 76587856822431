import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import {
  addPlanGoalApi,
  deletePlanGoalApi,
  editPlanGoalApi,
  editStepRateApi,
  editStepsApi,
  getDevPlanApi,
  getStats
} from './api'
import { GET_DEV_PLAN, GET_DEV_STATS } from './constants'

export const useDevPlan = userId => {
  const [filterData, setFilterData] = useState('this-week')

  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch: getDevPlan
  } = useQuery(GET_DEV_PLAN, () => getDevPlanApi(userId))

  const {
    data: statistics,
    isLoading: statIsLoading,
    isSuccess: statusIsSuccess,
    error: statError,
    refetch: statFetch
  } = useQuery([GET_DEV_STATS, { filterData, userId }], getStats)

  const { mutate: editSteps } = useMutation(editStepsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_DEV_PLAN)
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  const {
    mutate: addPlanGoal,
    isLoading: addPlanGoalIsLoading
  } = useMutation(addPlanGoalApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const { mutate: editStepRate } = useMutation(editStepRateApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const {
    mutate: editPlanGoal,
    isLoading: editPlanGoalLoading
  } = useMutation(editPlanGoalApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const { mutate: deletePlanGoal } = useMutation(
    deletePlanGoalApi,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_DEV_PLAN)
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,
    getDevPlan,

    editSteps,

    addPlanGoal,
    addPlanGoalIsLoading,

    editStepRate,

    editPlanGoal,
    editPlanGoalLoading,

    deletePlanGoal,
    setFilterData,
    filterData,

    statistics,
    statIsLoading,
    statusIsSuccess,
    statError,
    statFetch
  }
}

export const useStatistics = ({ userId, filterData }) => {
  const {
    data: statistics,
    isLoading: statIsLoading,
    isSuccess: statusIsSuccess,
    error: statError,
    refetch: statFetch
  } = useQuery([GET_DEV_STATS, { filterData, userId }], getStats)

  return {
    statistics,
    statIsLoading,
    statusIsSuccess,
    statError,
    statFetch
  }
}
