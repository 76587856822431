import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { isEmpty } from 'lodash'

import useActionSteps from 'utils/hooks/useActionSteps'
import { getPercentage } from 'utils/helpers'

import memberStyles from 'pages/Member/styles.module.scss'

import Card from 'components/Card'
import SimpleTopNavbar from 'components/SimpleTopNavbar'
import ProgressCard from 'components/ProgressCard'

import { actionSteps } from '../data'

import Stakeholders from './Stakeholders'
import MonthlyCheckIns from './MonthlyCheckIns'
import ProgressCheck from './ProgressCheck'
import { useStakeholderEngagement } from './hooks'
import { DEFAULT_STEPS, STAKEHOLDER_ROUTES } from './constants'

import AddStakeholder from './Stakeholders/AddStakeholder'
import AddCheckIn from './MonthlyCheckIns/AddCheckIn'

const StakeholderEngagement = () => {
  useActionSteps(actionSteps)
  const { path } = useRouteMatch()
  const { data, editSteps } = useStakeholderEngagement()
  const [mutableSteps, setMutableSteps] = useState([])
  const [
    showAddStakeholderModal,
    setShowAddStakeholderModal
  ] = useState(false)
  const [showAddCheckinModal, setShowAddCheckinModal] = useState(
    false
  )
  const [showCheckinError, setShowCheckinError] = useState(false)

  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
    editSteps({ steps: sortedSteps })
  }

  useEffect(() => {
    const steps =
      data && !isEmpty(data.steps) ? data.steps : DEFAULT_STEPS

    setMutableSteps(steps)
  }, [data])

  return (
    <div>
      <Row className="mb-40">
        <Col
          xs={12}
          md={6}
          className={memberStyles.courseProgress}
        >
          <ProgressCard
            steps={mutableSteps}
            progress={progress}
            onChange={stepOnChange}
          />
        </Col>
        <Col xs={12} md={6}>
          <Card outlined className="h-100">
            The purpose of engaging stakeholders as part of the
            leadership development process is to create a
            supportive environment for growth. The people we work
            with on a regular basis are the most directly
            affected by our behavior and therefore best
            positioned to provide useful feedback and
            suggestions.
          </Card>
        </Col>
      </Row>
      <Row className="mb-10">
        <SimpleTopNavbar routes={STAKEHOLDER_ROUTES} />
        <Col
          xs={12}
          md={5}
          className="d-flex align-items-center justify-content-end"
        >
          <Switch>
            <Route exact path={path}>
              <AddStakeholder
                showModal={showAddStakeholderModal}
                setShowModal={setShowAddStakeholderModal}
              />
            </Route>
            <Route exact path={`${path}/monthly-checkins`}>
              <AddCheckIn
                showModal={showAddCheckinModal}
                setShowModal={setShowAddCheckinModal}
                setShowCheckinError={setShowCheckinError}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Switch>
            <Route exact path={path}>
              <Stakeholders
                setShowAddStakeholderModal={
                  setShowAddStakeholderModal
                }
              />
            </Route>
            <Route exact path={`${path}/monthly-checkins`}>
              <MonthlyCheckIns
                setShowAddCheckinModal={setShowAddCheckinModal}
                showCheckinError={showCheckinError}
                setShowCheckinError={setShowCheckinError}
              />
            </Route>
            <Route
              exact
              path={`${path}/progress-check`}
              component={ProgressCheck}
            />
          </Switch>
        </Col>
      </Row>
    </div>
  )
}

StakeholderEngagement.propTypes = {}

export default StakeholderEngagement
