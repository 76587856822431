import { isEmpty } from 'lodash'

export const switchFn = cases => defaultCase => key =>
  key in cases ? cases[key] : defaultCase

export const noop = () => {}

export const getPercentage = ({ a, b, decimalCount = 0 }) => {
  return ((a * 100) / b).toFixed(decimalCount)
}

export const getSyntheticEventRawData = ({
  type = 'input',
  event
}) => {
  if (type === 'input') {
    return event.target.value
  }
  return null
}

export const hasData = val => {
  return !isEmpty(val)
}
