import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'

import CardCollapse from 'components/CardCollapse'
import FormCheck from 'components/Form/FormCheck'
import FormControl from 'components/Form/FormControl'
import TouchableOpacity from 'components/TouchableOpacity'
import Button from 'components/Button'
import FileDisplay from 'components/FileDisplay'
import Upload from 'components/Upload'

import { workshopSchema } from 'pages/Coach/Workshops/constants'

import TextEditor from 'components/Form/TextEditor/TextEditor'
import CollapseHeader from './CollapseHeader'

const Edit = ({
  workshopId,
  title,
  companyId,
  date,
  agenda: originalAgendas,
  notes,
  todo: originalActionSteps,
  isOpen,
  editToggle,
  handleEditWorkshop,
  files,
  companies
}) => {
  const uploadFileRef = useRef(null)
  const [agendas, setAgendas] = useState(
    originalAgendas.map((x, index) => ({
      id: index + 1,
      description: x
    }))
  )
  const [uploadedData, setUploadedData] = useState(null)

  const [actionSteps, setActionSteps] = useState(
    originalActionSteps
  )
  const [actionStepError, setActionStepError] = useState(false)
  const [formatedNotes, setFormatedNotes] = useState('')

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(workshopSchema)
  })

  const addActionStep = () => {
    setActionStepError(null)
    const lastStepId = actionSteps.length
      ? actionSteps[actionSteps.length - 1].id
      : 0
    setActionSteps([
      ...actionSteps,
      { id: lastStepId + 1, description: '', status: false }
    ])
  }

  const removeFile = fileId => {
    const filteredData = uploadedData.filter(
      x => x.id !== fileId
    )
    return setUploadedData(filteredData)
  }

  const addFile = e => {
    if (e.target.files.length) {
      const fileArray = [
        ...uploadedData,
        {
          id: uploadedData.length + 1,
          file: e.target.files[0]
        }
      ]
      setUploadedData(fileArray)
    }
  }

  const removeActionStep = id => {
    const hasOneActionStep = actionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = actionSteps.filter(step => step.id !== id)
      setActionSteps(output)
    } else {
      setActionStepError(
        'At least one agenda and action step is required'
      )
    }
  }

  const actionStepOnChange = (id, newValue) => {
    const index = actionSteps.findIndex(step => step.id === id)
    actionSteps[index].description = newValue
  }

  const actionStepToggle = editStep => {
    const { id, description, status } = editStep
    const filteredActionSteps = actionSteps.filter(
      step => step.id !== id
    )
    const newActionSteps = [
      ...filteredActionSteps,
      {
        id,
        description,
        status: !status
      }
    ].sort((a, b) => a.id - b.id)
    setActionSteps(newActionSteps)
  }

  const addAgenda = () => {
    setActionStepError(null)
    const lastStepId = agendas.length
      ? agendas[agendas.length - 1].id
      : 0
    setAgendas([
      ...agendas,
      { id: lastStepId + 1, description: '' }
    ])
  }

  const removeAgenda = id => {
    const hasOneAgenda = agendas?.length === 1

    if (!hasOneAgenda) {
      const output = agendas.filter(step => step.id !== id)
      setAgendas(output)
    } else {
      setActionStepError(
        'At least one agenda and action step is required'
      )
    }
  }

  const agendaOnChange = (id, newValue) => {
    const index = agendas.findIndex(step => step.id === id)
    agendas[index].description = newValue
  }

  const saveHandler = async ({ company, title, date }) => {
    const validSteps = actionSteps.filter(
      step => !isEmpty(step.description)
    )
    const validAgendas = agendas.filter(
      agenda => !isEmpty(agenda.description)
    )
    const validStepsLength = actionSteps.map(
      step => step.description.length < 256
    )
    const validAgendasLength = agendas.map(
      agenda => agenda.description.length < 256
    )

    const isValid =
      actionSteps.length === validSteps.length &&
      validStepsLength[0] &&
      agendas.length === validAgendas.length &&
      validAgendasLength[0]

    if (isValid) {
      setActionStepError(null)
      const payload = {
        companyId: company,
        title,
        notes: formatedNotes,
        agenda: agendas.map(x => x.description),
        todo: actionSteps,
        files: uploadedData,
        date,
        isCompanyEdited: company !== companyId
      }

      await handleEditWorkshop(workshopId, payload)
      editToggle()
    } else {
      setActionStepError(
        'All agendas and action steps require a description  of max 255 char'
      )
    }
  }

  useEffect(() => {
    const transformedFiles = files?.map((file, index) => ({
      id: index + 1,
      file,
      oldFile: true
    }))
    setUploadedData(transformedFiles)
  }, [files])

  return (
    <CardCollapse
      headerComponent={
        <CollapseHeader
          title={title}
          companyId={companyId}
          date={date}
          inputRegister={register}
          inputErrors={errors}
          companies={companies}
        />
      }
      isOpen={isOpen}
      className="mb-10 mb-md-20"
    >
      <div className="agendas mb-20">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Agenda:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addAgenda}
          >
            + Add agenda item
          </TouchableOpacity>
        </div>
        {agendas.map(agenda => (
          <Row
            key={`edit-agenda-${agenda.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                placeholder="Agenda description"
                formGroupClass="flex-1 mb-0 mr-20"
                defaultValue={agenda.description}
                as="textarea"
                rows={1}
                onChange={e =>
                  agendaOnChange(agenda.id, e.target.value)
                }
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeAgenda(agenda.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <TextEditor
          setFormatedNotes={setFormatedNotes}
          defaultValue={notes}
        />
        {/* <FormControl
          inputRef={register}
          name="notes"
          error={errors.notes}
          as="textarea"
          rows={3}
          placeholder="Notes"
          defaultValue={notes}
        /> */}
      </div>
      <div className="notes mb-40">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Action Steps:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addActionStep}
          >
            + Add step
          </TouchableOpacity>
        </div>
        {actionSteps.map(step => (
          <Row
            key={`edit-actionStep-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <FormCheck
                controlId={`edit-actionStep-${step.id}`}
                onChange={() => actionStepToggle(step)}
                defaultChecked={step.status}
              />
              <FormControl
                placeholder="Action step description"
                formGroupClass="flex-1 mb-0 mr-20"
                defaultValue={step.description}
                as="textarea"
                rows={1}
                onChange={e => {
                  actionStepOnChange(step.id, e.target.value)
                }}
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeActionStep(step.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
        {actionStepError && (
          <small className="text-danger">
            {actionStepError}
          </small>
        )}
      </div>
      <div className="files mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Add files:
        </div>
        <div>
          <Upload
            inputRef={uploadFileRef}
            onFileChange={addFile}
          />
          <div className="d-flex mb-20">
            <Button
              variant="outline-secondary"
              smallPadding
              onClick={() => uploadFileRef?.current.click()}
            >
              Upload Files
            </Button>
            <div className="text-gray-dark align-items-center d-flex ml-20">
              Must not exceed 5 MB
            </div>
          </div>
          <div className="d-flex">
            {uploadedData?.map(x => {
              const oldFile = !!x.oldFile
              const filepath =
                (oldFile && x.file.split('/')) || ''
              const fileName = oldFile
                ? filepath[filepath.length - 1]
                : x.file.name

              return (
                <div key={fileName} className="mr-20">
                  <FileDisplay
                    remove={() => removeFile(x.id)}
                    fileName={fileName}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button
          variant="link"
          className="d-none d-sm-block"
          smallPadding
          onClick={editToggle}
        >
          Cancel
        </Button>
        <Button
          className="form__button--submit"
          variant="secondary"
          smallPadding
          onClick={handleSubmit(saveHandler)}
        >
          Save Changes
        </Button>
      </div>
    </CardCollapse>
  )
}

Edit.propTypes = {
  title: PropTypes.string,
  agenda: PropTypes.array,
  notes: PropTypes.string,
  todo: PropTypes.array,
  isOpen: PropTypes.bool
}

export default Edit
