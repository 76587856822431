import React from 'react'
import { Alert } from 'react-bootstrap'

const ErrorBanner = ({ errorMessage }) => {
  return (
    errorMessage && (
      <Alert variant="danger">{errorMessage}</Alert>
    )
  )
}

export default ErrorBanner
