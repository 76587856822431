import React from 'react'
import PropTypes from 'prop-types'
import { isNil, noop } from 'lodash'
import styles from './styles.module.scss'

const TouchableOpacity = ({
  children,
  className,
  componentRef,
  onClick,
  data,
  onMouseOver,
  ...rest
}) => {
  const clickFunction = isNil(onClick) ? noop : onClick

  const cloneChildren = onMouseOver ? (
    <div className={styles.tooltipContainer}>
      <span className={styles.tooltipTrigger}>{children}</span>
      <div className={styles.toolTip}>
        {data?.map(({ email, isCompleted }, index) => (
          <div key={index}>
            <span
              style={{
                color: `${isCompleted ? 'green' : 'red'}`,
                marginBottom: '10px !important',
                fontSize: 16
              }}
            >
              {email}
            </span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    children
  )
  return (
    <>
      <div
        ref={componentRef}
        tabIndex="0"
        onClick={clickFunction}
        onMouseOver={onMouseOver || null}
        className={`${styles.touchableOpacity} ${
          className || ''
        }`}
        {...rest}
      >
        {cloneChildren}
      </div>
    </>
  )
}

TouchableOpacity.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default TouchableOpacity
