import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getStakeholderEngagement = () => {
  return getRequest('/stakeholders')
}

export const editStepsApi = payload => {
  return putRequest('/stakeholders', payload)
}

export const addStakeholderApi = payload => {
  return postRequest('/stakeholders/profiles', payload)
}

export const editStakeholderApi = ({
  stakeholderId,
  payload
}) => {
  return putRequest(
    `/stakeholders/profiles/${stakeholderId}`,
    payload
  )
}

export const deleteStakeholderApi = stakeholderId => {
  return deleteRequest(`/stakeholders/profiles/${stakeholderId}`)
}
