import React from 'react'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import DashboardWrapper from 'components/DashboardWrapper'

import RLTable from './ResourceLibraryTable'

const ResourceLibrary = () => {
  useHeaderTitle('Resource Library')

  return (
    <DashboardWrapper>
      <RLTable />
    </DashboardWrapper>
  )
}

ResourceLibrary.propTypes = {}

export default ResourceLibrary
