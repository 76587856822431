import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Alert, Col, Form, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isNil } from 'lodash'

import TopLeftPeakLogo from 'assets/images/peak-logo-top-left.png'
import PeakLogo from 'assets/images/peak-logo-black.png'

import { useQueryParams } from 'utils/html/queryParams'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import Container from 'components/Container'
import H1 from 'components/H1'
import H2 from 'components/H2'
import TouchableOpacity from 'components/TouchableOpacity'
import Toast from 'components/Toast'

import styles from './styles.module.scss'
import { useUser } from './hooks'

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password')
})

const Login = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const { login, isLoading, error } = useUser()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginFormSchema)
  })

  const invalidToken = !isNil(queryParams.get('invalidToken'))
  const somethingWentWrong = !isNil(queryParams.get('error'))
  const [showToast, setShowToast] = useState(
    invalidToken || somethingWentWrong
  )
  const [showLoginError, setShowLoginError] = useState(false)

  const loginFormOnSubmit = data => {
    setShowLoginError(false)
    login(data)
  }

  useEffect(() => {
    if (error) setShowLoginError(true)
  }, [error])

  return (
    <Container className={styles.loginWrapper}>
      <Row className={`${styles.row} position-relative`}>
        <Col
          xs={{ order: 'last' }}
          md={{ span: 4, order: 'first' }}
          className={`${styles.leftWrapper} bg-primary-dark text-white`}
        >
          <Image
            src={TopLeftPeakLogo}
            className={styles.topLeftImage}
          />
          <div className={styles.contentWrapper}>
            <H1 className="mb-10">
              Leadership Effectiveness Drives Every Measure of
              Organizational Success
            </H1>
            <div className={styles.divider} />
            <p className={styles.welcomeDescription}>
              Develop yourself
            </p>
            <p className={styles.welcomeDescription}>
              Support your team
            </p>
            <p className={styles.welcomeDescription}>
              Transform your organization
            </p>
          </div>
        </Col>
        <Col md={8} className={styles.rightWrapper}>
          <div className={styles.contentWrapper}>
            <Image
              src={PeakLogo}
              className={`${styles.logo} mb-15`}
            />
            <H1 className="text-primary font-weight-normal mb-20 mb-md-40">
              Leadership Development Portal
            </H1>
            <H2 className="mb-20">Login</H2>
            {showLoginError && (
              <Alert
                variant="danger"
                onClose={() => setShowLoginError(false)}
                dismissible
              >
                {error?.message}
              </Alert>
            )}

            <Form
              className={styles.form}
              onSubmit={handleSubmit(loginFormOnSubmit)}
            >
              <FormControl
                inputRef={register}
                type="string"
                name="email"
                placeholder="Enter email"
                error={errors.email || showLoginError}
              />

              <FormControl
                inputRef={register}
                type="password"
                name="password"
                placeholder="Enter password"
                error={errors.password || showLoginError}
              />
              <div className="d-flex justify-content-between align-items-center mt-20">
                <Button
                  type="submit"
                  variant="secondary"
                  className="text-white"
                >
                  {isLoading ? 'Logging In...' : 'Login'}
                </Button>
                <TouchableOpacity
                  onClick={() =>
                    history.push('/forgot-password')
                  }
                  className="text-primary-light mr-md-30"
                >
                  Forgot Password?
                </TouchableOpacity>
              </div>
            </Form>
          </div>
        </Col>
        <Toast
          show={showToast}
          toggle={() => setShowToast(false)}
          variant="danger"
          position="bottom-right"
          title="Error"
          titleClass="text-white font-weight-bold"
        >
          <div className="text-white">
            {invalidToken
              ? 'Your token is invalid'
              : 'Something went wrong'}
          </div>
        </Toast>
      </Row>
    </Container>
  )
}

Login.propTypes = {}

export default Login
