import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import { editSteps, get360 } from './api'
import { GET_360 } from './constants'

export const use360 = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_360,
    get360
  )

  const { mutate } = useMutation(editSteps, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_360)
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  return {
    data,
    isLoading,
    isSuccess,
    error,

    editSteps: mutate
  }
}
