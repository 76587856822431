import React, { useEffect } from 'react'
import * as yup from 'yup'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { includes } from 'lodash'

import BottomRightPeakLogo from 'assets/images/peak-logo-bottom-right.png'
import PeakLogo from 'assets/images/peak-logo-black.png'

import { useQueryParams } from 'utils/html/queryParams'
import { INVALID_TOKEN } from 'utils/http/constants'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import Container from 'components/Container'
import H1 from 'components/H1'
import H2 from 'components/H2'
import PageLoader from 'components/PageLoader'

import styles from './styles.module.scss'
import { useSignup, useSignupToken } from './hooks'

const signupFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required('Please enter your email'),
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  password: yup
    .string()
    .min(8, 'Password should be at least 8 characters')
})

const Signup = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const {
    verifiedData,
    verifyToken,
    isLoading: verifyLoading,
    error: verifyError
  } = useSignupToken()

  const {
    signup,
    isSuccess: signupSuccess,
    isLoading: signupLoading
  } = useSignup()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(signupFormSchema)
  })

  const inviteToken = queryParams.get('token')

  const sigunUpFormSubmit = data => {
    const { firstName, lastName, password } = data
    const payload = {
      firstName,
      lastName,
      password,
      inviteToken
    }
    signup(payload)
  }

  useEffect(() => {
    verifyToken({
      inviteToken
    })
  }, [verifyToken, inviteToken])

  useEffect(() => {
    if (signupSuccess) {
      history.push('/')
    }
  }, [signupSuccess, history])

  if (verifyError) {
    if (includes(INVALID_TOKEN, verifyError.message)) {
      return <Redirect to="/?invalidToken" />
    }

    return <Redirect to="/?error" />
  }

  return (
    <PageLoader loader={verifyLoading}>
      <Container className={styles.signupWrapper}>
        <Row className={styles.row}>
          <Col xs="7-5" className={styles.leftWrapper}>
            <div className={styles.contentWrapper}>
              <Image src={PeakLogo} className="mb-15" />
              <H1 className="text-primary font-weight-normal mb-40">
                Leadership Development Portal
              </H1>
              <H2 className="mb-20">Set up your account</H2>
              <Form
                className={styles.form}
                onSubmit={handleSubmit(sigunUpFormSubmit)}
              >
                <FormControl
                  inputRef={register}
                  type="string"
                  name="firstName"
                  placeholder="First Name"
                  error={errors.firstName}
                />
                <FormControl
                  inputRef={register}
                  type="string"
                  name="lastName"
                  placeholder="Last Name"
                  error={errors.lastName}
                />
                <FormControl
                  inputRef={register}
                  defaultValue={
                    verifiedData && verifiedData.email
                  }
                  type="string"
                  name="email"
                  placeholder="Email"
                  error={errors.email}
                  readOnly
                />

                <FormControl
                  inputRef={register}
                  type="password"
                  name="password"
                  placeholder="Password"
                  error={errors.password}
                />
                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    type="submit"
                    variant="secondary"
                    className="text-white"
                  >
                    {signupLoading ? 'Loading' : 'Signup'}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col
            xs="4-5"
            className={`${styles.rightWrapper} bg-primary-dark text-white`}
          >
            <Image
              src={BottomRightPeakLogo}
              className={styles.bottomRightImage}
            />
            <div className={styles.contentWrapper}>
              <H1 className="mb-10">
                Let&apos;s start your journey
              </H1>
              <div className={styles.divider} />
              <p>
                Leadership Effectiveness Drives Every Measure of
                Organizational Success
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLoader>
  )
}

Signup.propTypes = {}

export default Signup
