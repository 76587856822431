import React, { useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { head } from 'lodash'

import { ReactComponent as ImageEmptyMonthly } from 'assets/images/monthly-empty.svg'
import { ReactComponent as IconTrash } from 'assets/icons/trash-icon.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import { formatDate } from 'utils/helpers/date'

import AddButton from 'components/Button/AddButton'
import Card from 'components/Card'
import ConfirmModal from 'components/ConfirmModal'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'
import TouchableOpacity from 'components/TouchableOpacity'

import memberStyles from 'pages/Member/styles.module.scss'

import styles from './styles.module.scss'
import EditCheckin from './EditCheckin'
import { useCheckin } from './hooks'

const MonthlyCheckIns = ({
  setShowAddCheckinModal,
  showCheckinError,
  setShowCheckinError
}) => {
  const { data, deleteCheckin } = useCheckin()

  const [editModal, setEditModal] = useState({
    show: false,
    date: '',
    stakeholderId: null,
    answerOne: '',
    answerTwo: ''
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [
    stakeholderToBeDelete,
    setStakeholderToBeDelete
  ] = useState(null)

  const toggleEditModal = show =>
    setEditModal({ ...editModal, show })

  const editCheckIn = data =>
    setEditModal({ show: true, ...data })

  const handleDeleteCheckin = () => {
    const checkinIds = stakeholderToBeDelete.checkins.map(
      x => x.id
    )

    deleteCheckin({
      checkinIds,
      stakeholderName: stakeholderToBeDelete.name
    })
  }

  const transformedData =
    data
      ?.filter(stakeholder => {
        const hasCheckins = stakeholder.checkins.length

        return hasCheckins
      })
      .map(stakeholder => {
        const checkins = stakeholder.checkins.sort(
          (a, b) => a.id - b.id
        )

        return {
          ...stakeholder,
          checkins
        }
      }) || []

  if (!transformedData.length) {
    return (
      <Card
        className="d-flex align-items-center justify-content-center flex-column"
        style={{ minHeight: 350 }}
      >
        <ImageEmptyMonthly className="mb-20" />
        <div className="text-gray-dark mb-25">
          Here you will find your list of monthly check-ins you
          will input from your stakeholders.
        </div>
        <AddButton
          notResponsive
          label="Check-in"
          onClick={() => setShowAddCheckinModal(true)}
        />
      </Card>
    )
  }

  return (
    <div>
      {showCheckinError && (
        <Alert
          variant="danger"
          onClose={() => setShowCheckinError(false)}
          dismissible
        >
          {showCheckinError}
        </Alert>
      )}
      {transformedData?.map(stakeholder => {
        const initial = head(stakeholder.name)
        const formattedDate = formatDate({
          format: 'MMM DD, YYYY',
          date: stakeholder.date
        })

        return (
          <Card
            key={stakeholder.id}
            className={`${styles.checkInCard} mb-20`}
          >
            <Row>
              <Col xs={9} md={10} className="d-flex">
                <ThumbnailPlaceholder
                  initial={initial}
                  className="mr-13"
                />
                <div>
                  <div className={`${styles.name} mb-5`}>
                    {stakeholder.name}
                  </div>
                  <div className="text-gray-dark">
                    {formattedDate}
                  </div>
                </div>
              </Col>
              <Col
                xs={3}
                md={2}
                className="d-flex justify-content-end"
              >
                <TouchableOpacity
                  className="d-flex align-items-center mr-10"
                  onClick={() =>
                    editCheckIn({
                      date: stakeholder.date,
                      stakeholderId: stakeholder.id,
                      answerOne: stakeholder.checkins[0],
                      answerTwo: stakeholder.checkins[1]
                    })
                  }
                >
                  <IconEdit />
                </TouchableOpacity>
                <TouchableOpacity
                  className="d-flex align-items-center"
                  onClick={() => {
                    setStakeholderToBeDelete(stakeholder)
                    setShowDeleteModal(true)
                  }}
                >
                  <IconDelete />
                </TouchableOpacity>
              </Col>
            </Row>
            <div style={{ marginTop: 13 }} className="mb-20">
              <Col
                xs={12}
                className={memberStyles.dividerLine}
              />
            </div>
            <Row>
              <Col xs={12}>
                {stakeholder.checkins.map((checkin, index) => (
                  <div
                    key={`${checkin.id}-checkin-${index}`}
                    className="mb-20"
                  >
                    <div className="mb-8">
                      {checkin.question}
                    </div>
                    <div className="text-gray-dark">
                      {checkin.answer}
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Card>
        )
      })}
      <EditCheckin
        setShowModal={toggleEditModal}
        setShowCheckinError={setShowCheckinError}
        {...editModal}
      />
      <ConfirmModal
        icon={<IconTrash width={77} />}
        show={showDeleteModal}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={() => setShowDeleteModal(false)}
        title="Are you sure you want to delete this Monthly Check-in?"
        description="Once deleted, the notes from the monthly check-in cannot be retrieved."
        onConfirm={handleDeleteCheckin}
      />
    </div>
  )
}

MonthlyCheckIns.propTypes = {}

export default MonthlyCheckIns
