import React from 'react'
import { Col } from 'react-bootstrap'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import FormControl from 'components/Form/FormControl'

const CollapseHeader = ({ inputErrors, inputRegister }) => {
  return (
    <>
      <Col xs={9} className="font-weight-medium pl-0">
        <FormControl
          inputRef={inputRegister}
          error={inputErrors.title}
          as="textarea"
          rows={1}
          type="text"
          name="title"
          formGroupClass="mb-0"
          placeholder="Session title"
        />
      </Col>
      <Col
        xs={3}
        className="d-flex justify-content-end align-items-center pr-0"
      >
        <FormControl
          inputRef={inputRegister}
          error={inputErrors.date}
          name="date"
          as="datepicker"
          formGroupClass="mb-0"
          icon={<IconCalendar />}
          placeholder="Session date"
        />
      </Col>
    </>
  )
}

export default CollapseHeader
