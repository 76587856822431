import {
  $gradientBlue,
  $grayDark,
  $white
} from 'utils/helpers/colors'

export const axisStroke = {
  stroke: $grayDark,
  opacity: 0.5
}

export const axisStyle = {
  axis: axisStroke,
  tickLabels: {
    fontFamily: 'helvetica',
    fontSize: 14,
    fill: $grayDark
  }
}

export const victoryBarStyle = {
  data: { fill: $gradientBlue },
  labels: {
    fontSize: 10,
    fill: $white,
    fontFamily: 'helvetica'
  }
}
