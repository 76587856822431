import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'

import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'

import Card from 'components/Card'
import TouchableOpacity from 'components/TouchableOpacity'
import { ListItem } from 'components/UnorderedList'
import ConfirmModal from 'components/ConfirmModal'

import memberStyles from 'pages/Member/styles.module.scss'

import styles from '../styles.module.scss'
import RateBox from '../RateBox'
import { useDevPlan } from '../hooks'

const Plain = ({
  planId,
  actionSteps,
  title,
  toggleEditMode,
  date
}) => {
  const { editStepRate, deletePlanGoal } = useDevPlan()
  const [showDelete, setShowDelete] = useState(false)
  const stepRateChange = ({ stepId, rate }) => {
    editStepRate({ planId, stepId, rate })
  }

  const toggleModal = setter => {
    return () => setter(show => !show)
  }

  const deleteHandler = () => {
    deletePlanGoal(planId)
  }

  return (
    <Card className={`${styles.devPlanCard} mb-20`}>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-between mb-10">
            <div className="font-weight-bold">{title}</div>
            <div className="d-flex align-items-center">
              <div className="text-gray-dark mr-10">{date}</div>
              <TouchableOpacity
                className="mr-10"
                onClick={toggleEditMode}
              >
                <IconEdit />
              </TouchableOpacity>
              <TouchableOpacity
                onClick={toggleModal(setShowDelete)}
              >
                <IconDelete />
              </TouchableOpacity>
            </div>
          </div>
          <div className="d-flex mb-5 mb-md-15">
            <Col
              xs={8}
              md={10}
              className={memberStyles.dividerLine}
            />
            <Col xs={4} md={2} className="pr-0">
              <div className={styles.rateTitle}>
                Did I do my best today?
              </div>
              <div className={styles.rateSubtitle}>(1-10)</div>
            </Col>
          </div>
          {actionSteps.map(step => (
            <div
              key={step.id}
              className="d-flex mb-10 align-items-center justify-content-between"
            >
              <Col xs={8} className="px-0">
                <ListItem bottomMargin={0}>
                  {step.description}
                </ListItem>
              </Col>
              <Col
                xs={4}
                md={2}
                className="pr-0 d-flex justify-content-center"
              >
                <RateBox
                  editStepRate={stepRateChange}
                  stepId={step.id}
                  value={step.rate}
                />
              </Col>
            </div>
          ))}
        </Col>
      </Row>
      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDelete}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={toggleModal(setShowDelete)}
        title="Are you sure you want to delete this goal?"
        description="Removing a goal also removes any daily ratings."
        onConfirm={deleteHandler}
      />
    </Card>
  )
}

Plain.propTypes = {
  planId: PropTypes.number,
  actionSteps: PropTypes.array,
  title: PropTypes.string,
  toggleEditMode: PropTypes.func
}

export default Plain
