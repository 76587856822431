import { useMediaQuery } from 'react-responsive'

const breakPoints = {
  small: 576,
  medium: 768,
  large: 992,
  xl: 1200
}

const useDeviceSize = () => ({
  // Small
  isSmallOnlyScreen: useMediaQuery({
    maxWidth: breakPoints.small
  }),
  isSmallScreen: useMediaQuery({
    maxWidth: breakPoints.medium - 1
  }),
  isAtLeastSmallScreen: useMediaQuery({
    minWidth: breakPoints.small
  }),

  // Medium
  isMediumOnlyScreen: useMediaQuery({
    maxWidth: breakPoints.medium
  }),
  isMediumScreen: useMediaQuery({
    minWidth: breakPoints.medium,
    maxWidth: breakPoints.large - 1
  }),
  isAtLeastMediumScreen: useMediaQuery({
    minWidth: breakPoints.medium
  }),

  // Large
  isLargeScreen: useMediaQuery({
    minWidth: breakPoints.large,
    maxWidth: breakPoints.xl - 1
  }),
  isAtLeastLargeScreen: useMediaQuery({
    minWidth: breakPoints.large
  }),

  // XL
  isXLScreen: useMediaQuery({ minWidth: breakPoints.xl })
})

const useBreakPoint = () => {
  const bp = useDeviceSize()

  if (bp.isSmallScreen) return 'small'
  if (bp.isMediumScreen) return 'medium'
  if (bp.isLargeScreen) return 'large'

  return 'xl'
}

export { useDeviceSize, useBreakPoint }
