import React from 'react'
import { Image } from 'react-bootstrap'
import { head } from 'lodash'

import { ReactComponent as NoAccessIcon } from 'assets/icons/no-access.svg'
import { ReactComponent as WithAccessIcon } from 'assets/icons/has-access.svg'

import tblStyles from 'global/table.module.scss'

import TouchableOpacity from 'components/TouchableOpacity'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'

import styles from './styles.module.scss'

const NameWithThumb = ({
  name,
  thumb,
  custom,
  className,
  imageWrapperClass
}) => {
  const hasThumb = !!thumb
  const initial = head(name)
  return (
    <div
      className={`${className || ''} d-flex align-items-center`}
    >
      <div
        className={`${tblStyles.imageWrapper} ${
          imageWrapperClass || ''
        } mr-15`}
      >
        {hasThumb ? (
          <Image
            src={thumb}
            className={styles.profileImage}
            roundedCircle
          />
        ) : (
          <ThumbnailPlaceholder initial={initial} />
        )}
        {custom && <span>{name}</span>}
      </div>
      {!custom && <span>{name}</span>}
    </div>
  )
}

const StageRenderer = ({ onClick, stage }) => (
  <TouchableOpacity onClick={onClick} className={styles.stage}>
    <p>{stage}</p>
  </TouchableOpacity>
)

const AccessRenderer = ({ access }) => (
  <div className={styles.access}>
    {access ? (
      <WithAccessIcon width={16} />
    ) : (
      <NoAccessIcon width={16} />
    )}
  </div>
)

export { NameWithThumb, StageRenderer, AccessRenderer }
