import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import { dateToUTCISO } from 'utils/helpers/date'

import Button from 'components/Button'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'

import { useStakeholderEngagement } from '../hooks'

import styles from './styles.module.scss'
import { useCheckin } from './hooks'
import { questionOne, questionTwo } from './constants'

const addCheckInFormSchema = yup.object().shape({
  date: yup.string().required(`Please enter stakeholder's date`),
  stakeholder: yup
    .number()
    .required(`Please select a stakeholder`),
  questionOne: yup.string().required('Please enter an answer'),
  questionTwo: yup.string().required('Please enter an answer')
})

const EditCheckin = ({
  show,
  setShowModal,
  date,
  stakeholderId,
  answerOne,
  answerTwo,
  setShowCheckinError
}) => {
  const { userId } = useParams()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addCheckInFormSchema)
  })

  const { editCheckin, editCheckinError } = useCheckin(userId)
  const { data } = useStakeholderEngagement(userId)
  const stakeholders = data?.stakeholderProfiles

  const closeModal = () => setShowModal(false)

  const editSave = async data => {
    const date = dateToUTCISO({
      date: data.date,
      format: 'MMM DD, YYYY'
    })
    await editCheckin({
      userId,
      id: data.stakeholder,
      payload: {
        date,
        data: [
          {
            id: answerOne.id,
            question: questionOne,
            answer: data.questionOne
          },
          {
            id: answerTwo.id,
            question: questionTwo,
            answer: data.questionTwo
          }
        ]
      }
    })
    closeModal()
  }

  useEffect(() => {
    if (editCheckinError) {
      setShowCheckinError(editCheckinError?.message)
    }
  }, [editCheckinError, setShowCheckinError])
  return (
    <Modal
      mimicNative
      title="Edit Monthly Checkin"
      bodyClassName={styles.addCheckIn}
      show={show}
      closeModal={closeModal}
    >
      <p className={styles.modalTitle}>Edit Monthly Check-in</p>
      <p className="text-gray-dark mb-20">
        Include notes from your conversation below.
      </p>
      <div className="mb-40">
        <FormControl
          readOnly
          className={styles.editStakeholderCheckin}
          inputRef={register}
          type="string"
          name="stakeholder"
          placeholder="Stakeholder"
          error={errors.stakeholder}
          as="select"
          value={stakeholderId}
        >
          <option>Select Stakeholder</option>
          {stakeholders?.map(stakeholder => (
            <option value={stakeholder.id}>
              {stakeholder.name}
            </option>
          ))}
        </FormControl>

        <FormControl
          disablePreviousMonths
          disableFutureDates
          inputRef={register}
          icon={<IconCalendar />}
          name="date"
          as="datepicker"
          placeholder="Date"
          error={errors.date}
          defaultDate={date}
        />

        <FormControl
          inputRef={register}
          type="string"
          name="questionOne"
          placeholder="Answer"
          error={errors.questionOne}
          labelClassName="font-weight-medium"
          label={questionOne}
          as="textarea"
          rows={3}
          defaultValue={answerOne.answer}
        />
        <FormControl
          inputRef={register}
          type="string"
          name="questionTwo"
          placeholder="Answer"
          error={errors.questionTwo}
          labelClassName="font-weight-medium"
          label={questionTwo}
          as="textarea"
          rows={3}
          defaultValue={answerTwo.answer}
        />
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button variant="link" smallPadding onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          smallPadding
          type="submit"
          onClick={handleSubmit(editSave)}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

EditCheckin.propTypes = {}

export default EditCheckin
