import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import {
  createCheckin as createCheckinApi,
  getCheckin,
  editCheckin as editCheckinApi,
  deleteCheckinApi
} from './api'
import { GET_CHECKIN } from './constants'

export const useCheckin = userId => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch: getCheckinData
  } = useQuery(GET_CHECKIN, () => getCheckin(userId))

  const {
    mutate: createCheckin,
    error: createCheckinError
  } = useMutation(createCheckinApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_CHECKIN)
  })

  const {
    mutate: editCheckin,
    error: editCheckinError
  } = useMutation(editCheckinApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_CHECKIN)
  })

  const { mutate: deleteCheckin } = useMutation(
    deleteCheckinApi,
    {
      onSuccess: () => queryClient.invalidateQueries(GET_CHECKIN)
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,
    getCheckinData,

    createCheckin,
    createCheckinError,

    editCheckin,
    editCheckinError,

    deleteCheckin
  }
}
