import React from 'react'
import PropTypes from 'prop-types'
import { Button as BSButton } from 'react-bootstrap'

import styles from './styles.module.scss'

const Button = ({
  children,
  className,
  onClick,
  type = 'button',
  smallPadding,
  mediumPadding,
  ...rest
}) => {
  const classes = [`${styles.button}`]
  if (className) classes.push(className)
  if (smallPadding) classes.push(`${styles.smallPadding}`)
  if (mediumPadding) classes.push(`${styles.mediumPadding}`)

  return (
    <BSButton
      className={classes.join(' ')}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {children}
    </BSButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string
}

export default Button
