import * as yup from 'yup'

import React, { useEffect, useState } from 'react'
import { ReactComponent as IconHelp } from 'assets/icons/help.svg'
import { useParams } from 'react-router-dom'

import { Form, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Modal from 'components/Modal'
import Tooltip from 'components/Tooltip'
import FormControl from 'components/Form/FormControl'
import AddButton from 'components/Button/AddButton'

import { useStakeholderEngagement } from '../hooks'

import styles from './styles.module.scss'
import { ENROLLED_TOOLTIP } from './messages'

const stakeholderFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required(`Please enter stakeholder's email`),
  name: yup.string().required(`Please enter stakeholder's name`),
  relationship: yup
    .string()
    .required('Please enter your relationship with stakeholder')
})

const AddStakeholder = ({ showModal, setShowModal }) => {
  const { userId } = useParams()
  const [showAddError, setShowAddError] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(stakeholderFormSchema)
  })
  const {
    addStakeholder,
    addStakeholderLoading,
    addStakeholderError,
    addStakeholderSuccess,
    message
  } = useStakeholderEngagement(userId)

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  const formSubmit = ({ name, email, relationship }) => {
    addStakeholder({
      userId,
      payload: { name, email, relationship }
    })
  }

  useEffect(() => {
    if (addStakeholderError) {
      setShowAddError(true)
    }
    if (addStakeholderSuccess) {
      setShowModal(false)
    }
  }, [setShowModal, addStakeholderError, addStakeholderSuccess])

  return (
    <>
      <div className="d-flex justify-content-between justify-content-md-end w-100">
        <div className="d-flex d-md-none align-items-center">
          <p className="mb-0 mr-10">
            What do you mean by{' '}
            <span className="font-weight-bold">enrolled</span>?
          </p>
          <Tooltip tip={ENROLLED_TOOLTIP}>
            <IconHelp />
          </Tooltip>
        </div>
        <AddButton
          label="Stakeholder"
          disabled={addStakeholderLoading}
          loading={addStakeholderLoading}
          onClick={openModal}
        />
      </div>
      <Modal
        mimicNative
        bodyClassName={styles.addStakeholder}
        show={showModal}
        title="Add Stakeholder"
        closeModal={closeModal}
      >
        <p className={`${styles.modalTitle} d-none d-sm-block`}>
          Add Stakeholder
        </p>
        <p className="text-gray-dark mb-20">
          Stakeholders are colleagues who the client will share
          their goals with, get their suggestions, and check-in
          with them on the client&apos;s progress monthly.
        </p>
        {showAddError && message && (
          <Alert variant="danger" dismissible>
            {message}
          </Alert>
        )}
        <Form
          onSubmit={handleSubmit(formSubmit)}
          className="mb-40"
        >
          <FormControl
            inputRef={register}
            type="string"
            name="name"
            placeholder="Name"
            error={errors.name}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="email"
            placeholder="Email"
            error={errors.email}
          />

          <FormControl
            inputRef={register}
            type="string"
            name="relationship"
            placeholder="Relationship"
            error={errors.relationship}
            as="select"
          >
            <option>Peer</option>
            <option>Direct Report</option>
            <option>Manager</option>
            <option>Others</option>
          </FormControl>
        </Form>
        <div className="d-flex justify-content-end mb-20">
          <Button
            className="d-none d-sm-block"
            variant="link"
            smallPadding
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="form__button--submit"
            variant="secondary"
            smallPadding
            onClick={handleSubmit(formSubmit)}
          >
            Add Stakeholder
          </Button>
        </div>
      </Modal>
    </>
  )
}

AddStakeholder.propTypes = {}

export default AddStakeholder
