import React, { useRef, useState } from 'react'

import { isValidEmail } from 'utils/regex'

import Autocomplete from 'components/Form/Autocomplete'
import Button from 'components/Button'
import Modal from 'components/Modal'
import TagsInput from 'components/TagsInput'

import styles from './styles.module.scss'

const InvitationModal = ({
  show,
  toggleModal,
  onSubmit,
  coachModal,
  companies
}) => {
  const [emails, setEmails] = useState([])
  const companySelectRef = useRef(null)
  const companySelectOptions = companies?.map(
    company => company.name
  )

  const handleAddEmail = e => {
    if (!isValidEmail(e.target.value)) return

    setEmails(currEmails => [...currEmails, e.target.value])
  }

  const handleRemoveEmail = tag => {
    setEmails(currEmails =>
      currEmails.filter(currTag => currTag !== tag)
    )
  }

  const handleInviteClients = () => {
    const payload = {
      company: companySelectRef.current?.value,
      emails
    }

    onSubmit(payload)
    toggleModal()
    setEmails([])
  }

  return (
    <Modal show={show} closeModal={toggleModal}>
      <fieldset>
        <p className={styles.modalTitle}>
          Invite {coachModal ? 'Coach' : 'Users'}
        </p>
        {!coachModal && (
          <Autocomplete
            inputRef={companySelectRef}
            placeholder="Select Company"
            options={companySelectOptions}
          />
        )}
        <div className="mb-40">
          <TagsInput
            tags={emails}
            onAddTag={handleAddEmail}
            onRemoveTag={handleRemoveEmail}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            variant="link"
            smallPadding
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            smallPadding
            type="submit"
            onClick={handleInviteClients}
          >
            Invite User
          </Button>
        </div>
      </fieldset>
    </Modal>
  )
}

export default InvitationModal
