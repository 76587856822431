import { getRequest, postRequest, putRequest } from 'utils/http'

export const getCheckin = userId => {
  return getRequest(
    `/stakeholders/user/${userId}/profiles/checkin`
  )
}

export const createCheckin = ({ userId, id, payload }) => {
  return postRequest(
    `/stakeholders/user/${userId}/profiles/${id}/checkin`,
    payload
  )
}

export const editCheckin = ({ userId, id, payload }) => {
  return putRequest(
    `/stakeholders/user/${userId}/checkin/${id}`,
    payload
  )
}

export const deleteCheckinApi = payload => {
  return postRequest(`/stakeholders/delete/checkin`, payload)
}
