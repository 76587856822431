import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import {
  createCategoryApi,
  createResourceApi,
  deleteResourceApi,
  editResourceApi,
  getAllResourceCategoriesApi,
  getAllResourcesApi,
  recommendResourceApi
} from './api'
import {
  GET_RESOURCES,
  GET_RESOURCE_CATEGORIES
} from './constants'

export const useResourceLibrary = () => {
  const {
    data: resourceLibraryData,
    refetch: getAllResources,
    isLoading: resourceLibraryLoading
  } = useQuery(GET_RESOURCES, getAllResourcesApi)

  const {
    data: resourceCategories,
    refetch: getAllResourceCategories
  } = useQuery(
    GET_RESOURCE_CATEGORIES,
    getAllResourceCategoriesApi,
    {
      enabled: false
    }
  )

  const { mutate: createCategory } = useMutation(
    createCategoryApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_RESOURCE_CATEGORIES, {
          refetchInactive: true
        })
      }
    }
  )

  const { mutate: createResource } = useMutation(
    createResourceApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_RESOURCES, {
          refetchInactive: true
        })
      }
    }
  )

  const { mutate: editResource } = useMutation(editResourceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_RESOURCES, {
        refetchInactive: true
      })
    }
  })

  const { mutate: deleteResource } = useMutation(
    deleteResourceApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_RESOURCES, {
          refetchInactive: true
        })
      }
    }
  )

  const { mutate: recommendResource } = useMutation(
    recommendResourceApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_RESOURCES, {
          refetchInactive: true
        })
      }
    }
  )

  return {
    getAllResources,
    resourceLibraryData,
    resourceLibraryLoading,

    resourceCategories,
    getAllResourceCategories,

    createCategory,

    createResource,

    editResource,

    deleteResource,

    recommendResource
  }
}
