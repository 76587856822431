import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getDevPlanApi = userId => {
  return getRequest(
    `/development-plans/user/${userId}?filter=this-week
    `
  )
}

export const getStats = query => {
  const { queryKey } = query
  return getRequest(
    `/development-plans/statistics/${
      queryKey[1].userId
    }?filter=${queryKey[1].filterData || 'this-week'}`
  )
}

export const editStepsApi = ({ userId, payload }) => {
  return putRequest(`/development-plans/user/${userId}`, payload)
}

export const deletePlanGoalApi = ({ planId, userId }) => {
  return deleteRequest(
    `/development-plans/user/${userId}/goals/${planId}`
  )
}

export const addPlanGoalApi = ({ userId, payload }) => {
  return postRequest(
    `/development-plans/user/${userId}/goals`,
    payload
  )
}

export const editStepRateApi = ({
  userId,
  planId,
  stepId,
  payload
}) => {
  const { rate } = payload
  return putRequest(
    `/development-plans/user/${userId}/goals/${planId}/action-steps/${stepId}/rate`,
    { rate: Number(rate) }
  )
}

export const editPlanGoalApi = async ({
  userId,
  planId,
  payload
}) => {
  const {
    title,
    addedSteps,
    editedSteps,
    deletedSteps
  } = payload
  const addRequestPayload = {
    actionSteps: addedSteps.map(step => step.description)
  }

  const editRequest = editedSteps.forEach(step => {
    return putRequest(
      `/development-plans/user/${userId}/goals/${planId}/action-steps/${step.id}`,
      {
        description: step.description
      }
    )
  })

  const deleteRequest = deletedSteps.forEach(step => {
    return patchRequest(
      `/development-plans/user/${userId}/goals/${planId}/action-steps/${step.id}/delete`
    )
  })

  await Promise.all([editRequest, deleteRequest])
  await putRequest(
    `/development-plans/user/${userId}/goals/${planId}`,
    {
      title
    }
  )
  return postRequest(
    `/development-plans/user/${userId}/goals/${planId}/action-steps/`,
    addRequestPayload
  )
}
