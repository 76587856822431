import * as yup from 'yup'

import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import AddButton from 'components/Button/AddButton'
import Button from 'components/Button'
import DashboardWrapper from 'components/DashboardWrapper'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'

import memberStyles from '../styles.module.scss'

import OKRTable from './OKRTable'
import styles from './styles.module.scss'
import { useOkr } from './hooks'

const okrFormSchema = yup.object().shape({
  title: yup
    .string()
    .required(`This field is required`)
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  url: yup.string().required(`This field is required`)
})

const OKR = () => {
  useHeaderTitle('Objective and Key Results')
  const {
    data,
    isLoading,
    createOkr,
    editOkr,
    deleteOkr
  } = useOkr()
  const [showAddModal, setShowAddModal] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(okrFormSchema)
  })

  const closeModal = () => setShowAddModal(false)

  const handleCreateOkr = async payload => {
    await createOkr({ payload })
    closeModal()
  }

  const handleEditOkr = (id, payload) => {
    editOkr({ id, payload })
  }

  const handleDeleteOkr = id => {
    deleteOkr({ id })
  }

  return (
    <DashboardWrapper>
      <div>
        <div className={`${memberStyles.titleWrapper} mb-10`}>
          <p className={`${memberStyles.sectionTitle} mb-0`}>
            All Links
          </p>
          <AddButton
            label="Link"
            onClick={() => setShowAddModal(true)}
          />
        </div>
        <OKRTable
          data={data}
          isLoading={isLoading}
          handleEditOkr={handleEditOkr}
          handleDeleteOkr={handleDeleteOkr}
        />
      </div>
      <Modal
        mimicNative
        title="Add Link"
        show={showAddModal}
        closeModal={closeModal}
      >
        <p className={`${styles.modalTitle} d-none d-sm-block`}>
          Add Link
        </p>
        <p className="text-gray-dark mb-20">
          Include a link to your company or individual objectives
          and key results.
        </p>
        <Form>
          <FormControl
            inputRef={register}
            type="string"
            name="title"
            as="textarea"
            rows={2}
            placeholder="Title"
            error={errors.title}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="url"
            placeholder="URL"
            error={errors.url}
          />
        </Form>
        <div className="d-flex justify-content-end mb-20">
          <Button
            className="d-none d-sm-block"
            variant="link"
            smallPadding
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="form__button--submit"
            variant="secondary"
            smallPadding
            onClick={handleSubmit(handleCreateOkr)}
          >
            Add Link
          </Button>
        </div>
      </Modal>
    </DashboardWrapper>
  )
}

OKR.propTypes = {}

export default OKR
