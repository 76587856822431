import { useQuery, useMutation } from 'react-query'
import { queryClient } from 'store/state'
import {
  editProgressCheckApi,
  getProgressCheck,
  getStakeholder,
  postSendSurvey
} from './api'
import {
  GET_STAKEHOLDER_PROGRESS,
  GET_STAKEHOLDERS,
  POST_SURVEY_INVITE
} from './constants'

export const useProgressCheckin = id => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch
  } = useQuery(
    GET_STAKEHOLDER_PROGRESS,
    () => getProgressCheck(id),
    { enabled: false }
  )

  const {
    data: stakeholderList,
    isLoading: stakeholderIsLoading,
    isSuccess: stakeholderisSuccess,
    error: stakeholdererror,
    refetch: stakeholderRefetch
  } = useQuery(GET_STAKEHOLDERS, () => getStakeholder(id), {
    enabled: false
  })

  const { mutate: postSurvey } = useMutation(postSendSurvey, {
    onSuccess: () =>
      queryClient.invalidateQueries(POST_SURVEY_INVITE)
  })

  const { mutate: editProgressCheck } = useMutation(
    editProgressCheckApi,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_STAKEHOLDER_PROGRESS)
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,
    refetch,

    stakeholderList,
    stakeholderIsLoading,
    stakeholderisSuccess,
    stakeholdererror,
    stakeholderRefetch,

    postSurvey,

    editProgressCheck
  }
}
