import * as yup from 'yup'

import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useRightSidebar } from 'global/hooks/useRightSidebar'
import { useUserWorkshop } from 'global/hooks/useUserWorkshop'

import { openLink } from 'utils/html/window'

import TouchableOpacity from 'components/TouchableOpacity'
import FormCheck from 'components/Form/FormCheck'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'

import styles from './styles.module.scss'

import { useSession } from './CoachingSessions/hooks'

const okrFormSchema = yup.object().shape({
  title: yup
    .string()
    .required(`This field is required`)
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  url: yup.string().required(`This field is required`)
})

const RightSidebar = ({ userId, addOkr }) => {
  const [showAddModal, setShowAddModal] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(okrFormSchema)
  })
  const { sideBarData, getRightSideBarData } = useRightSidebar(
    userId
  )
  const { editSession } = useSession(userId)
  const { editUserWorkshop } = useUserWorkshop(userId)

  const { sessions, objectives } = sideBarData || {}

  const handleEditCheck = ({ id, type, index }) => {
    const newSteps = sessions
      .find(x => x.type === type)
      .steps.map((step, stepIndex) => ({
        ...step,
        status: stepIndex === index ? !step.status : step.status
      }))

    if (type === 'coachingSession') {
      return editSession({
        id,
        userId,
        payload: {
          steps: newSteps
        }
      })
    }

    return editUserWorkshop({
      workshopId: id,
      userId,
      payload: {
        actionSteps: newSteps
      }
    })
  }

  const openModal = () => setShowAddModal(true)
  const closeModal = () => setShowAddModal(false)

  const handleCreateOkr = async payload => {
    await addOkr(payload)
    closeModal()
  }

  useEffect(() => {
    getRightSideBarData()
  }, [userId, getRightSideBarData])

  return (
    <div>
      {sessions && (
        <div>
          <div className="mb-5" style={{ fontSize: 18 }}>
            Action Steps
          </div>
          {sessions?.map(
            ({ steps, title, id, type }, parentIndex) => {
              const classes = ['pb-10 mb-20']
              classes.push('border-bottom')

              return (
                <div
                  key={parentIndex}
                  className={classes.join(' ')}
                >
                  <p className="font-weight-bold text-primary mb-15">
                    {title}
                  </p>
                  {steps.length ? (
                    steps.map(
                      ({ description, status }, index) => (
                        <FormCheck
                          controlId={`actionStep[${parentIndex}][${index}]`}
                          key={`actionStep[${parentIndex}][${index}]`}
                          label={description}
                          defaultChecked={status}
                          onChange={() =>
                            handleEditCheck({ id, type, index })
                          }
                        />
                      )
                    )
                  ) : (
                    <p className="text-center">No data</p>
                  )}
                </div>
              )
            }
          )}
          <div className="mb-5" style={{ fontSize: 18 }}>
            Objectives and Key Results
          </div>
          {objectives.length ? (
            objectives?.map(okr => {
              return (
                <TouchableOpacity
                  key={okr.url}
                  onClick={() =>
                    openLink({
                      url: okr.url,
                      isExternalLink: true,
                      newTab: true
                    })
                  }
                  className="d-flex align-items-center mb-10"
                >
                  <span>{okr.title}</span>
                </TouchableOpacity>
              )
            })
          ) : (
            <p className="text-center">No data</p>
          )}
          <TouchableOpacity
            onClick={openModal}
            className="mt-10 d-flex justify-content-center align-items-center"
          >
            <p className="link">+ Add OKR</p>
          </TouchableOpacity>
        </div>
      )}
      <Modal
        mimicNative
        title="Add Link"
        show={showAddModal}
        closeModal={closeModal}
      >
        <p className={`${styles.modalTitle} d-none d-sm-block`}>
          Add Link
        </p>
        <p className="text-gray-dark mb-20">
          Sed ut perspiciatis unde omnis iste natus error sit
          voluptatem
        </p>
        <Form>
          <FormControl
            inputRef={register}
            type="string"
            name="title"
            as="textarea"
            rows="2"
            placeholder="Title"
            error={errors.title}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="url"
            placeholder="URL"
            error={errors.url}
          />
        </Form>
        <div className="d-flex justify-content-end mb-20">
          <Button
            className="d-none d-sm-block"
            variant="link"
            smallPadding
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="form__button--submit"
            variant="secondary"
            smallPadding
            onClick={handleSubmit(handleCreateOkr)}
          >
            Add Link
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default RightSidebar
