import React from 'react'
import { isEmpty } from 'lodash'

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import tableStyles from 'global/table.module.scss'

import { openLink } from 'utils/html/window'

import Table from 'components/Table'
import TouchableOpacity from 'components/TouchableOpacity'

import { Spinner } from 'react-bootstrap'
import memberStyles from '../styles.module.scss'

const DesktopTable = ({
  data,
  isLoading,
  editLink,
  deleteLink
}) => {
  const emptyData = isEmpty(data)

  return (
    <Table className="mb-30">
      <thead>
        <tr>
          <th>Name</th>
          <th>URL</th>
          <th>Date Added</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data?.map(okr => {
          return (
            <tr key={okr.id}>
              <td className="position-relative">
                <span className={memberStyles.urlTitle}>
                  {okr.title}
                </span>
              </td>
              <td>
                <TouchableOpacity
                  onClick={() => {
                    openLink({
                      url: okr.url,
                      isExternalLink: true,
                      newTab: true
                    })
                  }}
                  className={`${tableStyles.link} link`}
                >
                  {okr.url}
                </TouchableOpacity>
              </td>
              <td>{okr.date}</td>
              <td>
                <div className="d-flex justify-content-center">
                  <TouchableOpacity
                    className="mr-10"
                    onClick={() =>
                      editLink({
                        id: okr.id,
                        title: okr.title,
                        url: okr.url
                      })
                    }
                  >
                    <IconEdit />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onClick={() => deleteLink(okr.id)}
                  >
                    <IconDelete />
                  </TouchableOpacity>
                </div>
              </td>
            </tr>
          )
        })}
        {emptyData && (
          <tr>
            <td colSpan={4} className="text-center">
              {isLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                'No data'
              )}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default DesktopTable
