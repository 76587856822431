import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'

import CardCollapse from 'components/CardCollapse'
import FormCheck from 'components/Form/FormCheck'
import FormControl from 'components/Form/FormControl'
import TouchableOpacity from 'components/TouchableOpacity'
import Button from 'components/Button'
import FileDisplay from 'components/FileDisplay'
import Upload from 'components/Upload'

import { workshopSchema } from 'pages/Coach/Workshops/constants'

import TextEditor from 'components/Form/TextEditor/TextEditor'
import CollapseHeader from './CollapseHeader'

const Add = ({ addToggle, handleCreateWorkshop, companies }) => {
  const [agendas, setAgendas] = useState([
    {
      id: 1,
      description: ''
    }
  ])
  const [actionSteps, setActionSteps] = useState([
    {
      id: 1,
      description: '',
      status: false
    }
  ])
  const [uploadedData, setUploadedData] = useState([])
  const [actionStepError, setActionStepError] = useState(false)
  const [formatedNotes, setFormatedNotes] = useState('')

  const uploadRef = useRef(null)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(workshopSchema)
  })

  const addActionStep = () => {
    setActionStepError(null)
    const lastStepId = actionSteps.length
      ? actionSteps[actionSteps.length - 1].id
      : 0
    setActionSteps([
      ...actionSteps,
      { id: lastStepId + 1, description: '', status: false }
    ])
  }

  const removeActionStep = id => {
    const hasOneActionStep = actionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = actionSteps.filter(step => step.id !== id)
      setActionSteps(output)
    } else {
      setActionStepError(
        'At least one agenda and action step is required'
      )
    }
  }

  const actionStepOnChange = (id, newValue) => {
    const index = actionSteps.findIndex(step => step.id === id)
    actionSteps[index].description = newValue
    setActionSteps([...actionSteps])
  }

  const actionStepToggle = editStep => {
    const { id, description, status } = editStep
    const filteredActionSteps = actionSteps.filter(
      step => step.id !== id
    )
    const newActionSteps = [
      ...filteredActionSteps,
      {
        id,
        description,
        status: !status
      }
    ].sort((a, b) => a.id - b.id)
    setActionSteps(newActionSteps)
  }

  const addAgenda = () => {
    setActionStepError(null)
    const lastStepId = agendas.length
      ? agendas[agendas.length - 1].id
      : 0
    setAgendas([
      ...agendas,
      { id: lastStepId + 1, description: '' }
    ])
  }

  const removeAgenda = id => {
    const hasOneAgenda = agendas?.length === 1

    if (!hasOneAgenda) {
      const output = agendas.filter(step => step.id !== id)
      setAgendas(output)
    } else {
      setActionStepError(
        'At least one agenda and action step is required'
      )
    }
  }

  const agendaOnChange = (id, newValue) => {
    const index = agendas.findIndex(step => step.id === id)
    agendas[index].description = newValue
    setAgendas(agendas)
  }

  const saveHandler = async ({ company, title, date }) => {
    const validSteps = actionSteps.filter(
      step => !isEmpty(step.description)
    )
    const validAgendas = agendas.filter(
      agenda => !isEmpty(agenda.description)
    )
    const validStepsLength = actionSteps.map(
      step => step.description.length < 256
    )
    const validAgendasLength = agendas.map(
      agenda => agenda.description.length < 256
    )

    const isValid =
      actionSteps.length === validSteps.length &&
      validStepsLength[0] &&
      agendas.length === validAgendas.length &&
      validAgendasLength[0]
    if (isValid) {
      setActionStepError(null)
      const payload = {
        companyId: company,
        title,
        notes: formatedNotes,
        agenda: agendas.map(x => x.description),
        todo: actionSteps,
        date,
        files: uploadedData
      }
      await handleCreateWorkshop(payload)
      addToggle()
    } else {
      setActionStepError(
        'All agendas and action steps require a description of max 255 char'
      )
    }
  }

  const onFileChange = e => {
    if (e.target.files.length) {
      const fileArray = [
        ...uploadedData,
        {
          id: uploadedData.length + 1,
          file: e.target.files[0]
        }
      ]
      setUploadedData(fileArray)
    }
  }

  const removeFile = (fileId = null) => {
    const filteredData = uploadedData.filter(
      x => x.id !== fileId
    )
    return setUploadedData(filteredData)
  }

  return (
    <CardCollapse
      headerComponent={
        <CollapseHeader
          inputRegister={register}
          inputErrors={errors}
          companies={companies}
        />
      }
      isOpen
      className="py-10"
    >
      <div className="agendas mb-20">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Agenda:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addAgenda}
          >
            + Add agenda item
          </TouchableOpacity>
        </div>
        {agendas.map(agenda => (
          <Row key={`add-agenda-${agenda.id}`} className="mb-10">
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                placeholder="Agenda description"
                formGroupClass="flex-1 mb-0 mr-20"
                defaultValue={agenda.description}
                as="textarea"
                rows={1}
                onChange={e =>
                  agendaOnChange(agenda.id, e.target.value)
                }
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeAgenda(agenda.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <TextEditor setFormatedNotes={setFormatedNotes} />
      </div>
      <div className="action-steps mb-40">
        <div className="d-flex justify-content-between mb-10">
          <div className="font-weight-medium text-primary">
            Action Steps:
          </div>
          <TouchableOpacity
            className="text-secondary"
            onClick={addActionStep}
          >
            + Add step
          </TouchableOpacity>
        </div>
        {actionSteps.map(step => (
          <Row
            key={`add-actionStep-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <FormCheck
                controlId={`add-actionStep-${step.id}`}
                defaultChecked={step.status}
                onChange={() => actionStepToggle(step)}
              />
              <FormControl
                placeholder="Action step description"
                formGroupClass="flex-1 mb-0 mr-20"
                defaultValue={step.description}
                as="textarea"
                rows={1}
                onChange={e => {
                  actionStepOnChange(step.id, e.target.value)
                }}
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeActionStep(step.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
        {actionStepError && (
          <small className="text-danger">
            {actionStepError}
          </small>
        )}
      </div>
      <div className="files mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Add files:
        </div>
        <div>
          <Upload
            inputRef={uploadRef}
            onFileChange={onFileChange}
          />
          <div className="d-flex mb-20">
            <Button
              variant="outline-secondary"
              smallPadding
              onClick={() => uploadRef?.current.click()}
            >
              Upload Files
            </Button>
            <div className="text-gray-dark align-items-center d-flex ml-20">
              Must not exceed 5 MB
            </div>
          </div>
          <div className="d-flex">
            {uploadedData.map(x => (
              <div className="mr-20">
                <FileDisplay
                  remove={() => removeFile(x.id)}
                  fileName={x.file.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button variant="link" smallPadding onClick={addToggle}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          smallPadding
          onClick={handleSubmit(saveHandler)}
        >
          Save Changes
        </Button>
      </div>
    </CardCollapse>
  )
}

Add.propTypes = {
  addToggle: PropTypes.func
}

export default Add
