import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { getFileLink } from 'utils/helpers/file'
import { openLink } from 'utils/html/window'
import { getUserImage } from 'utils/helpers/component'

import CardCollapse from 'components/CardCollapse'
import FormCheck from 'components/Form/FormCheck'
import { UnorderedList } from 'components/UnorderedList'
import FileDisplay from 'components/FileDisplay'
import ReactHtmlParser from 'react-html-parser'
import CollapseHeader from './CollapseHeader'
import styles from './styles.module.scss'

const Plain = ({
  files,
  title,
  date,
  company,
  agenda,
  notes,
  todo,
  isOpen,
  toggle,
  editToggle,
  attendees,
  handleDeleteWorkshop
}) => {
  const hasAgendas = !!agenda.length
  const hasActionSteps = !!todo.length

  return (
    <CardCollapse
      className="mb-10 mb-md-20"
      headerComponent={
        <CollapseHeader
          title={title}
          date={date}
          company={company}
          onDelete={handleDeleteWorkshop}
          onEdit={editToggle}
          toggle={toggle}
          isOpen={isOpen}
        />
      }
      isOpen={isOpen}
    >
      <div className="agendas mb-20">
        {hasAgendas && (
          <>
            <div className="font-weight-medium text-primary mb-10">
              Agenda:
            </div>
            <UnorderedList items={agenda} />
          </>
        )}
      </div>
      <div className="notes mb-20">
        <div className="font-weight-medium text-primary mb-10">
          Notes:
        </div>
        <div>{ReactHtmlParser(notes)}</div>
      </div>
      <div className="notes mb-40">
        {hasActionSteps && (
          <>
            <div className="font-weight-medium text-primary mb-10">
              Action Steps:
            </div>
            {todo.map((step, index) => (
              <FormCheck
                key={`actionStep-${index}`}
                controlId={`actionStep-${index}`}
                label={step.description}
                defaultChecked={step.status}
                disabled
              />
            ))}
          </>
        )}
      </div>
      <div className={styles.attendees}>
        <div className="font-weight-medium text-primary mb-10">
          Attendees:
        </div>
        <div className="attendess-wrapper d-flex">
          {attendees.map(attendee => {
            const tooltip = props => (
              <Tooltip
                id={`button-tooltip-${attendee.name}`}
                {...props}
                className="bg-primary"
              >
                {attendee.name}
              </Tooltip>
            )

            const userImage = {
              name: attendee.name,
              image: attendee.image
                ? getFileLink(attendee.image)
                : null
            }

            return (
              <OverlayTrigger placement="top" overlay={tooltip}>
                <div className="mr-5">
                  {getUserImage(userImage, 30)}
                </div>
              </OverlayTrigger>
            )
          })}
        </div>
      </div>
      <div className="files mb-20">
        {!!files.length && (
          <div className="font-weight-medium text-primary mb-10">
            Files:
          </div>
        )}
        <div className="d-flex">
          {files?.map(file => {
            const filepath = file.split('/')
            const fileName = filepath[filepath.length - 1]
            const fileLink = getFileLink(file)
            return (
              <div
                key={`company-${company}-${fileName}`}
                className="mr-20"
              >
                <FileDisplay
                  fileName={fileName}
                  onClick={() =>
                    openLink({ url: fileLink, newTab: true })
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    </CardCollapse>
  )
}

Plain.propTypes = {
  title: PropTypes.string,
  company: PropTypes.string,
  agenda: PropTypes.array,
  notes: PropTypes.string,
  todo: PropTypes.array,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default Plain
