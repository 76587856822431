import * as yup from 'yup'

import { messages } from './constants'

export const schema = yup.object().shape({
  title: yup.string().required(messages.required).max(255),
  type: yup.string().required(messages.required),
  url: yup
    .string()
    .url(messages.url)
    .when('type', {
      is: 'Link', // Dangerous, refactor to use function instead
      then: yup
        .string()
        .url(messages.url)
        .required(messages.required)
    }),
  sourceUrl: yup.string().url(messages.url),
  source: yup.string()
})
