import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import { REMINDER_DEFAULT_SETTING } from 'global/constants'
import { useUserSettings } from 'global/hooks/useUserSettings'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import DashboardWrapper from 'components/DashboardWrapper'
import Card from 'components/Card'
import Switch from 'components/Switch'
import memberStyles from '../styles.module.scss'

import styles from './styles.module.scss'

const validateAndTransformPayload = async (
  data,
  toggledReminderType
) => {
  const {
    email,
    push,
    development,
    actionSteps,
    stakeholdersCheckin
  } = data

  const hasNotifActive = email || push
  const hasMemberNotif =
    development || actionSteps || stakeholdersCheckin

  if (hasNotifActive && !hasMemberNotif && toggledReminderType) {
    return REMINDER_DEFAULT_SETTING
  }

  if (!hasNotifActive && !toggledReminderType) {
    return REMINDER_DEFAULT_SETTING
  }

  if (!hasNotifActive && hasMemberNotif && toggledReminderType) {
    return { ...data, email: true }
  }

  if (hasNotifActive && !hasMemberNotif) {
    return { ...data, development: true }
  }

  return data
}

const Settings = () => {
  useHeaderTitle('Settings')
  const {
    fetchSettings,
    data,
    updateSettings
  } = useUserSettings()

  const {
    email,
    push,
    development,
    actionSteps,
    stakeholdersCheckin
  } = data || {}

  const onSubmit = async (name, value, toggledReminderType) => {
    const temp = data || REMINDER_DEFAULT_SETTING
    delete temp.id
    temp[name] = value

    const payload = await validateAndTransformPayload(
      temp,
      toggledReminderType
    )
    updateSettings(payload)
  }

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  return (
    <DashboardWrapper>
      <div className={`${memberStyles.titleWrapper} mb-10`}>
        <p className={`${memberStyles.sectionTitle} mb-0`}>
          Notification Settings
        </p>
      </div>
      <Card className="p-30 mb-2">
        <div className="font-weight-medium text-primary mb-15">
          Receive Reminders:
        </div>
        <Row className="mb-20">
          <Col xs={10} lg={6}>
            Email Notifications
          </Col>
          <Col xs={2} lg={6}>
            <Switch
              checked={email}
              onChange={e => onSubmit('email', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={10} lg={6}>
            Push Notifications
          </Col>
          <Col xs={2} lg={6}>
            <Switch
              checked={push}
              onChange={e => onSubmit('push', e)}
            />
          </Col>
        </Row>
      </Card>
      <Card className="p-30 mb-2">
        <div className="font-weight-medium text-primary mb-15">
          Reminder Types:
        </div>
        <Row className="mb-20">
          <Col xs={10} lg={6}>
            <div>Development Plan</div>
            <div
              className={`${styles.reminderType} text-gray-dark`}
            >
              Weekly reminder of your goals and associated action
              items
            </div>
          </Col>
          <Col xs={2} lg={6}>
            <Switch
              checked={development}
              onChange={e => onSubmit('development', e, true)}
            />
          </Col>
        </Row>
        <Row className="mb-20">
          <Col xs={10} lg={6}>
            <div>Actions steps from last meeting</div>
            <div
              className={`${styles.reminderType} text-gray-dark`}
            >
              One-time reminder of action steps from the most
              recent coaching session or workshop
            </div>
          </Col>
          <Col xs={2} lg={6}>
            <Switch
              checked={actionSteps}
              onChange={e => onSubmit('actionSteps', e, true)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={10} lg={6}>
            <div>Stakeholders check-ins</div>
            <div
              className={`${styles.reminderType} text-gray-dark`}
            >
              Monthly reminder to collect input from your
              stakeholders
            </div>
          </Col>
          <Col xs={2} lg={6}>
            <Switch
              checked={stakeholdersCheckin}
              onChange={e =>
                onSubmit('stakeholdersCheckin', e, true)
              }
            />
          </Col>
        </Row>
      </Card>
    </DashboardWrapper>
  )
}

Settings.propTypes = {}

export default Settings
