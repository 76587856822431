import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Button from 'components/Button'

import styles from './styles.module.scss'

const AddButton = ({
  notResponsive = false,
  variant = 'secondary',
  disabled,
  onClick,
  className,
  label,
  loading
}) => {
  const classes = [`${styles.addButton}`]
  if (className) classes.push(className)

  if (notResponsive) {
    return (
      <Button
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        className={classes.join(' ')}
      >
        <FontAwesomeIcon icon={faPlus} />
        {loading ? (
          <span>Loading...</span>
        ) : (
          <span>{label}</span>
        )}
      </Button>
    )
  }

  classes.push(styles.responsive)

  return (
    <Button
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={classes.join(' ')}
    >
      <FontAwesomeIcon icon={faPlus} />
      {loading ? <span>Loading...</span> : <span>{label}</span>}
    </Button>
  )
}

export default AddButton
