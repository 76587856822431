import React, { useEffect, useState } from 'react'

import { useCompany } from 'global/hooks/useCompany'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import AddButton from 'components/Button/AddButton'
import DashboardWrapper from 'components/DashboardWrapper'
import Filters from 'components/Filters'
import Modal from 'components/Modal'

import { useWorkshop } from 'pages/Coach/Workshops/hooks'

import AddWorkshopCard from './SessionCard/Add'
import SessionCard from './SessionCard'

const Workshops = () => {
  useHeaderTitle('Workshops')
  const { isAtLeastSmallScreen } = useDeviceSize()
  const [isAdding, setIsAdding] = useState(false)
  const [filterCompany, setFilterCompany] = useState(null)
  const {
    workshopData,
    getWorkshopData,
    createWorkshop,
    editWorkshop,
    deleteWorkshop
  } = useWorkshop()
  const { companies } = useCompany()

  const filteredData = filterCompany
    ? workshopData?.filter(
        data => data.Company.id === filterCompany
      )
    : workshopData

  const filterCompanies =
    companies?.map(company => ({
      value: company.id,
      label: company.name
    })) || []

  const handleCreateWorkshop = payload => {
    createWorkshop(payload)
  }

  const handleEditWorkshop = (id, payload) => {
    editWorkshop({ id, payload })
  }

  const handleDeleteWorkshop = workshopId => {
    deleteWorkshop({ workshopId })
  }

  const filters = [
    {
      name: 'company',
      width: 100,
      options: [
        {
          value: null,
          label: 'Company'
        },
        ...filterCompanies
      ]
    }
  ]

  useEffect(() => {
    getWorkshopData()
  }, [getWorkshopData])

  return (
    <DashboardWrapper>
      <div className="d-flex aling-items-center justify-content-between mb-10">
        <Filters
          filters={filters}
          onFilterChange={(name, value) =>
            setFilterCompany(value)
          }
        />
        <AddButton
          label="Workshop"
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
        />
      </div>
      {isAdding && isAtLeastSmallScreen ? (
        <div className="mb-10">
          <AddWorkshopCard
            handleCreateWorkshop={handleCreateWorkshop}
            addToggle={() => setIsAdding(!isAdding)}
            companies={companies}
          />
        </div>
      ) : (
        <Modal
          show={isAdding}
          closeModal={() => setIsAdding(false)}
          mimicNative
          title="Add Workshop"
        >
          <AddWorkshopCard
            handleCreateWorkshop={handleCreateWorkshop}
            addToggle={() => setIsAdding(!isAdding)}
            companies={companies}
          />
        </Modal>
      )}
      {filteredData?.map(workshop => (
        <div key={workshop.id}>
          <SessionCard
            workshop={workshop}
            handleEditWorkshop={handleEditWorkshop}
            handleDeleteWorkshop={() =>
              handleDeleteWorkshop(workshop.id)
            }
            companies={companies}
          />
        </div>
      ))}
    </DashboardWrapper>
  )
}

Workshops.propTypes = {}

export default Workshops
