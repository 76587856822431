import { ReactComponent as DashboardIcon } from 'assets/icons/member-sidebar/dashboard-icon.svg'
import { ReactComponent as CoachingIcon } from 'assets/icons/member-sidebar/coaching-icon.svg'
import { ReactComponent as LibraryIcon } from 'assets/icons/member-sidebar/articles-icon.svg'
import { ReactComponent as WorkshopsIcon } from 'assets/icons/member-sidebar/workshops-icon.svg'
import { ReactComponent as ObjectiveIcon } from 'assets/icons/member-sidebar/objective-icon.svg'
import { ReactComponent as DashboardActiveIcon } from 'assets/icons/member-sidebar/dashboard-active.svg'
import { ReactComponent as CoachingActiveIcon } from 'assets/icons/member-sidebar/coaching-active.svg'
import { ReactComponent as LibraryActiveIcon } from 'assets/icons/member-sidebar/articles-active.svg'
import { ReactComponent as WorkshopsActiveIcon } from 'assets/icons/member-sidebar/workshops-active.svg'
import { ReactComponent as ObjectiveActiveIcon } from 'assets/icons/member-sidebar/objective-active.svg'
import { Routes } from 'global/routes'

import { ROUTES } from 'pages/Member/constants'

const iconLibrary = {
  [Routes.DASHBOARD]: {
    inactive: DashboardIcon,
    active: DashboardActiveIcon
  },
  [Routes.COACHING_SESSIONS]: {
    inactive: CoachingIcon,
    active: CoachingActiveIcon
  },
  [Routes.RESOURCE_LIBRARY]: {
    inactive: LibraryIcon,
    active: LibraryActiveIcon
  },
  [Routes.WORKSHOPS]: {
    inactive: WorkshopsIcon,
    active: WorkshopsActiveIcon
  },
  [Routes.OBJECTIVES_AND_KEY_RESULTS]: {
    inactive: ObjectiveIcon,
    active: ObjectiveActiveIcon
  }
}

function withIcon(data) {
  return data.map(item => ({
    ...item,
    icon: {
      inactive: iconLibrary[item.to].inactive,
      active: iconLibrary[item.to].active
    }
  }))
}

export default withIcon(ROUTES)
