import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getUserImage } from 'utils/helpers/component'
import { getFileLink } from 'utils/helpers/file'
import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import Modal from 'components/Modal'

import Plain from './Plain'
import Edit from './Edit'

const SessionCard = ({
  handleDeleteSession,
  handleEditSession,
  session,
  index,
  refetch
}) => {
  const {
    title,
    date,
    agendas,
    notes,
    actionSteps,
    id,
    coachNotes,
    creator
  } = session
  const userImage = getUserImage(
    creator.image
      ? { ...creator, image: getFileLink(creator.image) }
      : creator
  )
  const [isOpen, setIsOpen] = useState(index === 0)
  const [isEditing, setIsEditing] = useState(false)
  const { isAtLeastSmallScreen } = useDeviceSize()

  const toggleCollapse = () => setIsOpen(open => !open)

  const toggleEditMode = () => {
    setIsEditing(editing => !editing)
    if (!isOpen) toggleCollapse()
  }

  const props = {
    sessionId: id,
    title,
    date,
    agendas,
    notes,
    actionSteps,
    isOpen,
    editToggle: toggleEditMode,
    handleDeleteSession,
    handleEditSession,
    coachNotes,
    creator,
    refetch
  }

  if (isAtLeastSmallScreen)
    return isEditing ? (
      <Edit {...props} />
    ) : (
      <Plain
        userImage={userImage}
        toggle={toggleCollapse}
        {...props}
      />
    )

  return (
    <>
      <Plain
        {...props}
        userImage={userImage}
        toggle={toggleCollapse}
      />
      <Modal
        show={isEditing}
        closeModal={toggleEditMode}
        mimicNative
        title="Edit Session"
      >
        <Edit {...props} />
      </Modal>
    </>
  )
}

SessionCard.propTypes = {
  session: PropTypes.object,
  index: PropTypes.number
}

export default SessionCard
