import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import {
  addPlanGoalApi,
  deletePlanGoalApi,
  editPlanGoalApi,
  editStepRateApi,
  editStepsApi,
  getDevPlanApi,
  getStats
} from './api'
import { GET_DEV_PLAN, GET_DEV_STATS } from './constants'

export const useDevPlan = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_DEV_PLAN,
    getDevPlanApi
  )

  const { mutate: editSteps } = useMutation(editStepsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_DEV_PLAN)
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  const {
    mutate: addPlanGoal,
    isLoading: addPlanGoalIsLoading
  } = useMutation(addPlanGoalApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const { mutate: editStepRate } = useMutation(editStepRateApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const {
    mutate: editPlanGoal,
    isLoading: editPlanGoalLoading
  } = useMutation(editPlanGoalApi, {
    onSuccess: () => queryClient.invalidateQueries(GET_DEV_PLAN)
  })

  const { mutate: deletePlanGoal } = useMutation(
    deletePlanGoalApi,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_DEV_PLAN)
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,

    editSteps,

    addPlanGoal,
    addPlanGoalIsLoading,

    editStepRate,

    editPlanGoal,
    editPlanGoalLoading,

    deletePlanGoal
  }
}

export const useStatistics = filterData => {
  const {
    data: statistics,
    isLoading: statIsLoading,
    isSuccess: statusIsSuccess,
    error: statError,
    refetch: statFetch
  } = useQuery([GET_DEV_STATS, { filterData }], getStats)

  return {
    statistics,
    statIsLoading,
    statusIsSuccess,
    statError,
    statFetch
  }
}
