import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

import { ReactComponent as ImagePdf } from 'assets/images/pdf-empty.svg'

import { DEFAULT_360_STEPS } from 'utils/helpers/course-component'
import { getFileLink } from 'utils/helpers/file'
import { getPercentage } from 'utils/helpers'

import Card from 'components/Card'
import PdfViewer from 'components/PdfViewer'
import Switch from 'components/Switch'
import Button from 'components/Button'
import Upload from 'components/Upload'

import singleUserStyles from 'pages/Coach/UserManagement/SingleUser/styles.module.scss'

import ProgressCard from 'components/ProgressCard'
import { usePillar } from './hooks'

const ThreeSixty = () => {
  const { userId } = useParams()
  const {
    userPillar,
    getUserPillar,
    updatePillarPdf,
    updatePillarPdfLoading,
    updatePillar
  } = usePillar(userId)
  const [mutableSteps, setMutableSteps] = useState([])
  const [shareToClient, setShareToClient] = useState(null)
  const fileRef = useRef(null)

  const hasFile = userPillar && userPillar.file

  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
    updatePillar({
      userId,
      payload: { steps: sortedSteps }
    })
  }

  const onFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      const formData = new FormData()

      formData.append('pdfDocument', file)
      updatePillarPdf({
        userId,
        filePayload: formData,
        isDelete: false
      })
    } else {
      updatePillarPdf({
        userId,
        isDelete: true
      })
    }
  }

  const onSwitchChange = value => {
    setShareToClient(value)
    updatePillar({
      userId,
      payload: {
        sharedToClient: value
      }
    })
  }

  const removeFile = () =>
    updatePillarPdf({
      userId,
      editPayload: {
        sharedToClient: false
      },
      isDelete: true
    })

  useEffect(() => {
    const steps =
      userPillar && !isEmpty(userPillar.steps)
        ? userPillar.steps
        : DEFAULT_360_STEPS

    setMutableSteps(steps)
    setShareToClient(userPillar?.sharedToClient)
  }, [userPillar])

  useEffect(() => {
    getUserPillar()
  }, [getUserPillar])

  useEffect(() => {
    getUserPillar()
  }, [userId, getUserPillar])

  return (
    <div className={singleUserStyles.userManagement}>
      <Row className="mb-40">
        <Col xs={6} className={singleUserStyles.courseProgress}>
          <ProgressCard
            steps={mutableSteps}
            progress={progress}
            onChange={stepOnChange}
          />
        </Col>
        <Col xs={6}>
          <Card outlined className="h-100">
            This is a coworker evaluation which provides feedback
            on 40 competencies as well as comments on each
            dimension, providing 30-50 pages of feedback and
            recommendations. This tool helps inform the goal
            setting process.
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-between align-items-center mb-10">
            <p
              className={`${singleUserStyles.sectionTitle} mb-0`}
            >
              Three Pillars of Leadership 360
            </p>
            {hasFile && (
              <div className="d-flex align-items-center">
                <div className="d-flex mr-30 align-items-center">
                  <p className="mr-10 mb-0">Share to Client</p>
                  <Switch
                    checked={shareToClient}
                    onChange={onSwitchChange}
                  />
                </div>
                <Button variant="secondary" onClick={removeFile}>
                  Remove File
                </Button>
              </div>
            )}
          </div>
          {hasFile ? (
            <PdfViewer src={getFileLink(userPillar.file)} />
          ) : (
            <Card
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ minHeight: 350 }}
            >
              <ImagePdf className="mb-20" />
              <div className="text-gray-dark">
                Looks like you have not sent through the Three
                Pillars of Leadership 360 PDF. Upload
              </div>
              <div className="text-gray-dark mb-20">
                and send the file here to help the user get
                started on their journey.
              </div>
              <Upload
                inputRef={fileRef}
                onFileChange={onFileChange}
              />
              <Button
                variant="secondary"
                smallPadding
                onClick={() => fileRef?.current.click()}
                disabled={updatePillarPdfLoading}
              >
                {updatePillarPdfLoading
                  ? 'Loading'
                  : 'Upload PDF'}
              </Button>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  )
}

ThreeSixty.propTypes = {}

export default ThreeSixty
