import { useQuery } from 'react-query'

import { GET_ACTIVITY_FEED } from './constants'
import { getActivityFeedApi } from './api'

export const useActivityFeed = () => {
  const { data: activityFeedData } = useQuery(
    GET_ACTIVITY_FEED,
    getActivityFeedApi
  )

  return { activityFeedData }
}
