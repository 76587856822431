import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import {
  deleteUserApi,
  editUserCompanyApi,
  getAllClientsApi,
  getAllCoachesApi,
  getAllCompanyApi,
  giveUserAccessApi,
  inviteUserApi,
  removeUserAccessApi
} from './api'
import {
  GET_ALL_CLIENTS,
  GET_ALL_COACHES,
  GET_ALL_COMPANY
} from './constants'

export const useUserManagement = () => {
  const [inviteUserError, setInviteUserError] = useState(null)
  const { data: allClients, refetch: getAllClients } = useQuery(
    GET_ALL_CLIENTS,
    getAllClientsApi
  )

  const { data: allCompany, refetch: getAllCompany } = useQuery(
    GET_ALL_COMPANY,
    getAllCompanyApi
  )

  const { data: allCoaches, refetch: getAllCoaches } = useQuery(
    GET_ALL_COACHES,
    getAllCoachesApi
  )

  const { mutate: editUserCompany } = useMutation(
    editUserCompanyApi,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_ALL_CLIENTS)
    }
  )

  const { mutate: giveUserAccess } = useMutation(
    giveUserAccessApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ALL_CLIENTS)
        queryClient.invalidateQueries(GET_ALL_COACHES)
      }
    }
  )

  const { mutate: removeUserAccess } = useMutation(
    removeUserAccessApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ALL_CLIENTS)
        queryClient.invalidateQueries(GET_ALL_COACHES)
      }
    }
  )

  const { mutate: inviteUser } = useMutation(inviteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ALL_CLIENTS)
      queryClient.invalidateQueries(GET_ALL_COACHES)
    },
    onError: error => {
      setInviteUserError(error.message)
    }
  })

  const { mutate: deleteUser } = useMutation(deleteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ALL_CLIENTS)
      queryClient.invalidateQueries(GET_ALL_COACHES)
    }
  })

  return {
    getAllClients,
    allClients,

    getAllCoaches,
    allCoaches,

    editUserCompany,

    giveUserAccess,
    removeUserAccess,

    inviteUser,
    inviteUserError,
    setInviteUserError,

    deleteUser,

    allCompany,
    getAllCompany
  }
}
