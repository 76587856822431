import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getWorkshopsApi = () => {
  return getRequest(`/workshops`)
}

export const createWorkshopApi = async payload => {
  const { files } = payload
  const filePaths = []

  await Promise.all(
    files.map(async data => {
      const formData = new FormData()

      formData.append('pdfDocument', data.file)
      const response = await postRequest(
        '/workshops/file',
        formData
      )

      return filePaths.push(response.filepath)
    })
  )
  const workshopPayload = { ...payload, files: filePaths }

  return postRequest('/workshops', workshopPayload)
}

export const editWorkshopApi = async ({ id, payload }) => {
  const { files } = payload
  const oldFiles = files.filter(x => x.oldFile).map(y => y.file)
  const newFiles = files.filter(x => !x.oldFile)
  const newFilePaths = []

  await Promise.all(
    newFiles.map(async data => {
      const formData = new FormData()

      formData.append('pdfDocument', data.file)
      const response = await postRequest(
        '/workshops/file',
        formData
      )

      return newFilePaths.push(response.filepath)
    })
  )
  const workshopPayload = {
    ...payload,
    files: [...oldFiles, ...newFilePaths]
  }

  return putRequest(`/workshops/${id}`, workshopPayload)
}

export const deleteWorkshopApi = ({ workshopId }) => {
  return deleteRequest(`/workshops/${workshopId}`)
}
