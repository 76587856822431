import React, { useState } from 'react'
import { head } from 'lodash'

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import tableStyles from 'global/table.module.scss'

import { formatDate } from 'utils/helpers/date'

import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'
import StyledCheckbox from 'components/StyledCheckbox'
import TouchableOpacity from 'components/TouchableOpacity'

import EditStakeholder from './EditStakeholder'

const MobileTable = ({
  data,
  handleDeleteStakeholder,
  handleToggleEnrolled
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [modalData, setModalData] = useState({
    id: null,
    name: '',
    email: '',
    relationship: ''
  })
  return (
    <ul className={tableStyles.mobileTable}>
      {data?.map(stakeholder => {
        const initial = head(stakeholder.name)

        return (
          <li>
            <div className="d-flex align-items-center mb-10">
              <StyledCheckbox
                className="mb-0"
                controlId={`keyEnrolledId${stakeholder.id}`}
                checked={stakeholder.enrolled}
                onChange={() =>
                  handleToggleEnrolled(stakeholder.id, {
                    ...stakeholder,
                    enrolled: !stakeholder.enrolled
                  })
                }
              />
              {stakeholder.enrolled ? (
                <span>Enrolled</span>
              ) : (
                <span className="font-italic text-gray-dark">
                  Not Enrolled
                </span>
              )}
            </div>
            <div className="d-flex align-items-center mb-10">
              <ThumbnailPlaceholder initial={initial} />
              <div className="d-flex flex-column pl-15">
                <p className={tableStyles.name}>
                  {stakeholder.name}
                </p>
                <span className={tableStyles.subtext}>
                  {stakeholder.relationship} .{' '}
                  {stakeholder.email}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span>
                Date Added:{' '}
                {formatDate({
                  date: stakeholder.dateAdded,
                  format: 'MMM. DD, YYYY'
                })}
              </span>
              <span className="d-flex justify-content-center">
                <TouchableOpacity
                  className="mr-10"
                  onClick={() => {
                    setModalData(stakeholder)
                    setShowEditModal(true)
                  }}
                >
                  <IconEdit />
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() =>
                    handleDeleteStakeholder(stakeholder.id)
                  }
                >
                  <IconDelete />
                </TouchableOpacity>
              </span>
            </div>
          </li>
        )
      })}
      <EditStakeholder
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modalData={modalData}
      />
    </ul>
  )
}

export default MobileTable
