import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as ImageEmptyProgressCheck } from 'assets/images/progress-empty.svg'

import { switchFn } from 'utils/helpers'

import Card from 'components/Card'
import PieChart from 'components/PieChart'
import ColumnChart from 'components/ColumnChart'
import Gradients from 'components/Gradients'

import { useStakeholderContext } from '../constants'

import styles from './styles.module.scss'
import { useProgressCheckin } from './hooks'

const ProgressCheck = () => {
  const { userId } = useParams()

  const {
    refetch,
    data: progressCheckData
  } = useProgressCheckin(userId)

  const { setProgressCheckData } = useStakeholderContext()

  const data = progressCheckData?.surveyAnswers

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    refetch()
  }, [refetch, userId])

  useEffect(() => {
    setProgressCheckData(progressCheckData)
  }, [progressCheckData, setProgressCheckData])

  const MultipleBar = ({ data, needSpacing = false }) =>
    data.map(goal => (
      <div key={goal.title} className="mb-40">
        <div
          className={`${styles.chartTitle} font-weight-medium text-primary`}
        >
          {goal.title}
        </div>
        <ColumnChart
          data={goal.answers}
          needSpacing={needSpacing}
        />
      </div>
    ))

  const TextAnswer = ({ answers }) => (
    <div className="mt-20">
      {answers.map(data => (
        <div
          key={data.stakeholderId}
          className={`${styles.textAnswerWrapper} p-20 mb-10`}
        >
          {data.answer}
        </div>
      ))}
    </div>
  )
  return (
    <div className={styles.progressCheck}>
      <Gradients />
      {!data && (
        <Card
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ minHeight: 350 }}
        >
          <ImageEmptyProgressCheck className="mb-20" />
          <div className="text-gray-dark">
            Here you will find your progress ratings
          </div>
          <div className="text-gray-dark mb-20">
            from your stakeholders.
          </div>
        </Card>
      )}
      {data?.map((result, index) => {
        return (
          <div
            key={result.key}
            className={`${styles.questionWrapper} ml-15 pl-40 pb-10 position-relative fffff`}
          >
            <div className={styles.questionTag}>
              {`Q${index + 1}`}
            </div>
            <Card className="p-20 p-md-30">
              <div
                className={styles.question}
                style={{ marginBottom: result?.data && '14px' }}
              >
                {result.question}
              </div>
              {switchFn({
                Pie: <PieChart data={result.answers} />,
                Bar: <ColumnChart data={result.answers} />,
                MultipleBar: (
                  <MultipleBar
                    data={result.data}
                    needSpacing={Boolean(result?.data)}
                  />
                ),
                Text: <TextAnswer answers={result.answers} />
              })(null)(result.chartType)}
            </Card>
          </div>
        )
      })}
    </div>
  )
}

ProgressCheck.propTypes = {}

export default ProgressCheck
