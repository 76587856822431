import {
  RESOURCE_TYPE_DOCS,
  RESOURCE_TYPE_LINK
} from './constants'

export const resourceIsLink = type => {
  return type?.toLowerCase() === RESOURCE_TYPE_LINK
}

export const resourceIsDocs = type => {
  return type?.toLowerCase() === RESOURCE_TYPE_DOCS
}
