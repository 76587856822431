import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'

import { ReactComponent as ImageDev } from 'assets/images/development-empty.svg'

import useActionSteps from 'utils/hooks/useActionSteps'
import { DEFAULT_DEV_PLAN_STEPS } from 'utils/helpers/course-component'
import { getPercentage } from 'utils/helpers'
import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import memberStyles from 'pages/Member/styles.module.scss'
import Card from 'components/Card'
import Button from 'components/Button'
import PageLoader from 'components/PageLoader'
import ProgressCard from 'components/ProgressCard'
import Modal from 'components/Modal'
import LineChart from 'components/LineChart'
import Filters from 'components/Filters'

import { actionSteps } from '../data'

import DevPlanCard from './DevPlanCard'
import { chartYTickValues } from './constants'
import { useDevPlan, useStatistics } from './hooks'

import Add from './DevPlanCard/Add'

const filters = [
  {
    name: 'filter',
    options: [
      {
        value: 'this-week',
        label: 'This Week'
      },
      {
        value: 'past-week',
        label: 'Past Week'
      },
      {
        value: 'past-month',
        label: 'Past Month'
      }
    ]
  }
]

const DevelopmentPlan = () => {
  useActionSteps(actionSteps)
  const [addMode, setAddMode] = useState(false)
  const [filterData, setFilterData] = useState('this-week')
  const [mutableSteps, setMutableSteps] = useState([])
  const { isAtLeastSmallScreen } = useDeviceSize()
  const {
    addPlanGoal,
    addPlanGoalIsLoading,
    data,
    editSteps,
    editPlanGoalLoading
  } = useDevPlan()
  const { statistics, statFetch } = useStatistics(filterData)

  const planGoals =
    (data &&
      data.planGoals.sort((a, b) => b.planId - a.planId)) ||
    []
  const emptyState = !planGoals.length && !addMode
  const completedSteps = mutableSteps.filter(step => step.status)
  const progress = getPercentage({
    a: completedSteps.length,
    b: mutableSteps.length,
    noDemical: true
  })

  const toggleAddMode = () => setAddMode(!addMode)
  const stepOnChange = (stepId, value) => {
    const selectedStep = mutableSteps.find(
      step => step.id === stepId
    )
    const changedStep = {
      ...selectedStep,
      status: value
    }
    const filteredSteps = mutableSteps.filter(
      step => step.id !== stepId
    )
    const sortedSteps = [changedStep, ...filteredSteps].sort(
      (a, b) => a.id - b.id
    )
    setMutableSteps(sortedSteps)
    editSteps({ steps: sortedSteps })
  }
  const addGoalButtonClick = value => {
    const { title, actionSteps } = value
    const payload = {
      title,
      actionSteps: actionSteps.map(step => step.description)
    }
    addPlanGoal(payload)
    toggleAddMode()
  }

  useEffect(() => {
    const steps =
      data && !isEmpty(data.steps)
        ? data.steps
        : DEFAULT_DEV_PLAN_STEPS
    setMutableSteps(steps)
    statFetch()
  }, [data, statFetch])

  const statisticsActionSteps = statistics?.statistics
    ?.map(x => x.actionSteps.length)
    .filter(x => x !== 0)
  const hasActionSteps = statisticsActionSteps?.length > 0

  return (
    <PageLoader loader={editPlanGoalLoading}>
      <div>
        <Row className="mb-40">
          <Col
            xs={12}
            md={6}
            className={memberStyles.courseProgress}
          >
            <ProgressCard
              steps={mutableSteps}
              progress={progress}
              onChange={stepOnChange}
            />
          </Col>
          <Col xs={12} md={6}>
            <Card outlined className="h-100">
              Based on the Three Pillars of Leadership 360
              report, Personality and Leadership profile,
              informal feedback, and personal reflection, several
              leadership development goals are identified.
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Row className="mb-10">
              <Col xs={8} className="d-flex align-items-center">
                <p
                  className={`${memberStyles.sectionTitle} mb-0`}
                >
                  Development Plan
                </p>
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-end"
              >
                {!emptyState && (
                  <Button
                    variant="secondary"
                    className={memberStyles.addButton}
                    onClick={toggleAddMode}
                    disabled={addMode}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Goal</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {addMode && isAtLeastSmallScreen ? (
                  <Add
                    submit={addGoalButtonClick}
                    toggleAddMode={toggleAddMode}
                    isLoading={addPlanGoalIsLoading}
                  />
                ) : (
                  <Modal
                    show={addMode}
                    closeModal={toggleAddMode}
                    mimicNative
                    title="Add Goal"
                  >
                    <Add
                      submit={addGoalButtonClick}
                      toggleAddMode={toggleAddMode}
                      isLoading={addPlanGoalIsLoading}
                    />
                  </Modal>
                )}
                {planGoals.map(plan => (
                  <DevPlanCard
                    key={plan.planId}
                    planId={plan.planId}
                    title={plan.title}
                    actionSteps={plan.actionSteps}
                    date={plan.date}
                  />
                ))}
                {emptyState && (
                  <Card
                    className="d-flex align-items-center justify-content-center flex-column"
                    style={{ minHeight: 350 }}
                  >
                    <ImageDev className="mb-20" />
                    <div className="text-gray-dark">
                      Here you will find your goals for the day.
                    </div>
                    <div className="text-gray-dark mb-20">
                      Start planning your first set of goals now.
                    </div>
                    <Button
                      variant="secondary"
                      onClick={toggleAddMode}
                      disabled={addMode}
                    >
                      Start Planning
                    </Button>
                  </Card>
                )}
              </Col>
            </Row>
            {!!statistics?.statistics.length && !emptyState && (
              <>
                <Row className="mb-10 mt-20">
                  <Col xs={12} lg={6}>
                    <p
                      className={`${memberStyles.sectionTitle} mb-0`}
                    >
                      Track Progress
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="d-block d-lg-flex justify-content-end"
                  >
                    <p
                      className={`${memberStyles.sectionTitle} mb-0`}
                    >
                      <Filters
                        filterValue={filterData}
                        filters={filters}
                        onFilterChange={(name, value) => {
                          setFilterData(value)
                        }}
                      />
                    </p>
                  </Col>
                </Row>
                <Card>
                  <Row>
                    <Col lg={12}>
                      <LineChart
                        data={statistics.statistics}
                        yDomain={[1, 10]}
                        yTickValues={chartYTickValues}
                        hideXAxis={!hasActionSteps}
                      />
                    </Col>
                  </Row>
                </Card>
              </>
            )}
          </Col>
        </Row>
      </div>
    </PageLoader>
  )
}

DevelopmentPlan.propTypes = {}

export default DevelopmentPlan
