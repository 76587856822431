import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

// Session
export const getSessions = () => {
  return getRequest(`/coaching-sessions`)
}

export const createSession = ({ payload }) => {
  return postRequest(`/coaching-sessions`, payload)
}

export const editSession = ({ id, payload }) => {
  return putRequest(`/coaching-sessions/${id}`, payload)
}

export const deleteSession = ({ id }) => {
  return deleteRequest(`/coaching-sessions/${id}`)
}

// Coaching Documents
export const getCoachingDocs = () => {
  return getRequest(`/coaching-documents`)
}

export const createCoachingDoc = ({ payload }) => {
  return postRequest(`/coaching-documents`, payload)
}

export const editCoachingDoc = ({ id, payload }) => {
  return putRequest(`/coaching-documents/${id}`, payload)
}

export const deleteCoachingDoc = ({ id }) => {
  return deleteRequest(`/coaching-documents/${id}`)
}
