import { useEffect } from 'react'

import { useGlobalContext } from 'global/Context'

const useHeaderTitle = title => {
  const { setHeaderTitle } = useGlobalContext()

  useEffect(() => {
    setHeaderTitle(title)

    return () => setHeaderTitle(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useHeaderTitle
