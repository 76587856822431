import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import {
  getWorkshopsApi,
  createWorkshopApi,
  editWorkshopApi,
  deleteWorkshopApi
} from './api'
import { GET_WORKSHOPS } from './constants'

export const useWorkshop = () => {
  // Disable all useQuery to make the input type file working.
  // Use refetchInactive: true to invalidate queries with enabled: false
  const {
    data: workshopData,
    refetch: getWorkshopData
  } = useQuery(GET_WORKSHOPS, getWorkshopsApi, {
    enabled: false
  })
  const { mutate: createWorkshop } = useMutation(
    createWorkshopApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSHOPS, {
          refetchInactive: true
        })
      }
    }
  )

  const { mutate: editWorkshop } = useMutation(editWorkshopApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_WORKSHOPS, {
        refetchInactive: true
      })
    }
  })

  const { mutate: deleteWorkshop } = useMutation(
    deleteWorkshopApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSHOPS, {
          refetchInactive: true
        })
      }
    }
  )

  return {
    getWorkshopData,
    workshopData,

    createWorkshop,

    editWorkshop,

    deleteWorkshop
  }
}
