import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import styles from './styles.module.scss'

const FormCheck = ({
  controlId,
  error,
  inputRef,
  upperlabel,
  label = '',
  ...rest
}) => {
  const hasError = !!error
  return (
    <Form.Group
      controlId={controlId}
      className={upperlabel && styles.form}
    >
      {upperlabel && (
        <label htmlFor={controlId}>{upperlabel}</label>
      )}
      <Form.Check
        id={controlId}
        ref={inputRef}
        isInvalid={hasError}
        className={`${styles.formCheck} mb-10`}
        label={label}
        {...rest}
      />
      {hasError && (
        <small className="text-danger">{error.message}</small>
      )}
    </Form.Group>
  )
}

FormCheck.propTypes = {
  controlId: PropTypes.string.isRequired,
  error: PropTypes.object,
  inputRef: PropTypes.object,
  label: PropTypes.string
}

export default FormCheck
