import React, { createContext, useContext } from 'react'

const GlobalContext = createContext()

export const GlobalProvider = ({ value, children }) => (
  <GlobalContext.Provider value={value}>
    {children}
  </GlobalContext.Provider>
)

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export default GlobalContext
