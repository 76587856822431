/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'

function useMutationSuccess(success) {
  const [show, setShow] = useState(false)

  function toggle() {
    setShow(false)
  }

  function ToastComponent({ message }) {
    return (
      <Toast
        show={show}
        toggle={toggle}
        variant="primary"
        position="bottom-right"
        title="Success"
        titleClass="text-white font-weight-bold"
        delay={3000}
        autohide
      >
        <div className="text-white">{message}</div>
      </Toast>
    )
  }

  useEffect(() => {
    if (success) setShow(true)
  }, [success])

  return [ToastComponent]
}

export default useMutationSuccess
