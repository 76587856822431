import React, { useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

import { Routes } from 'global/routes'
import { GlobalProvider } from 'global/Context'

import memberMenuLinks from 'utils/helpers/memberMenuLinks'
import { CommonRoutes } from 'utils/helpers/commonRoutes'

import Header from 'components/DashboardWrapper/Header'
import LeftSideBar from 'components/DashboardWrapper/LeftNav'
import { DASHBOARD_BASE_URLS } from 'pages/Member/constants'

import Dashboard from './Dashboard'
import CoachingSessions from './CoachingSessions'
import OKR from './OKR'
import Workshops from './Workshops'
import ResourceLibrary from './ResourceLibrary'
import Settings from './Settings'
import styles from './styles.module.scss'

const {
  COACHING_SESSIONS,
  DASHBOARD,
  OBJECTIVES_AND_KEY_RESULTS,
  WORKSHOPS,
  RESOURCE_LIBRARY,
  SETTINGS
} = Routes

const SwitchRoutes = () => (
  <Switch>
    <Route path={DASHBOARD} component={Dashboard} />
    <Route
      exact
      path={COACHING_SESSIONS}
      component={CoachingSessions}
    />
    <Route
      exact
      path={OBJECTIVES_AND_KEY_RESULTS}
      component={OKR}
    />
    <Route exact path={WORKSHOPS} component={Workshops} />
    <Route
      exact
      path={RESOURCE_LIBRARY}
      component={ResourceLibrary}
    />
    <Route path={SETTINGS} component={Settings} />
    <CommonRoutes />
  </Switch>
)

const Member = () => {
  const { pathname } = useLocation()

  const [headerTitle, setHeaderTitle] = useState('')
  const [actionSteps, setActionSteps] = useState(null)
  const [menuLinks, setMenuLinks] = useState(memberMenuLinks)

  if (DASHBOARD_BASE_URLS.includes(pathname)) {
    return <Redirect to={`${DASHBOARD}/plp`} />
  }

  return (
    <GlobalProvider
      value={{
        setHeaderTitle,
        actionSteps,
        setActionSteps,
        setMenuLinks
      }}
    >
      <Container className={styles.container}>
        <Header title={headerTitle} />
        <Row className="h-100">
          <LeftSideBar links={menuLinks} />
          <Col md={10} xs={12} className="bg-gray p-0">
            <SwitchRoutes />
          </Col>
        </Row>
      </Container>
    </GlobalProvider>
  )
}

Member.propTypes = {}

export default Member
