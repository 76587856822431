import { useMutation, useQuery } from 'react-query'

import { queryClient } from 'store/state'

import { GET_SIDEBAR_CONTENT } from 'global/hooks/useRightSidebar/constants'

import { addUserOkrApi, getUserByIdApi } from './api'
import { GET_USER_BY_ID } from './constants'

export const useUserById = userId => {
  const {
    data: user,
    refetch: userRefetch
  } = useQuery(GET_USER_BY_ID, () => getUserByIdApi(userId))

  const { mutate: addUserOkr } = useMutation(addUserOkrApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
    }
  })

  return {
    user,
    userRefetch,
    addUserOkr
  }
}
