import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { includes } from 'lodash'

import { useCourseStatus } from 'global/hooks/useUserCouseStatus'

import { getLocationPathname } from 'utils/html/window'

import {
  CourseComponentNav,
  CourseComponentItem
} from 'components/CourseComponent'

import { COURSE_COMPONENTS } from '../constants'

const CourseNav = () => {
  const history = useHistory()
  const courseRef = useRef(null)
  const [currentStage, setCurrentStage] = useState(null)

  const { courseStatusData } = useCourseStatus()

  const findActiveStage = courseStatusData?.findIndex(
    course => course.status === false
  )

  const doneSteps = courseStatusData?.filter(
    course => course.status === true
  )

  const allStepsDone =
    doneSteps?.length === courseStatusData?.length

  // if allSteps are done, activeStageIndex should be larger than courseStatusData to show of the steps are done. Else, activeStepIndex should be 0
  const activeStageIndex =
    findActiveStage < 0
      ? allStepsDone
        ? courseStatusData?.length + 1
        : 0
      : findActiveStage

  const handleCourseClick = async (to, index) => {
    const scrollConfig = {
      behavior: 'smooth',
      block: 'end',
      inline: 'center'
    }

    const element = courseRef.current.children[0].children[index]
    await element.scrollIntoView(scrollConfig)
    history.push(to)
  }

  useEffect(() => {
    if (currentStage) {
      const { to, index } = currentStage
      handleCourseClick(to, index)
    }
    // eslint-disable-next-line
  }, [currentStage])

  return (
    <CourseComponentNav
      className="mb-12 mb-md-35"
      courseRef={courseRef}
    >
      {COURSE_COMPONENTS.map((component, index) => {
        const { title, to } = component
        const isFirst = index === 0
        const isLast = index === COURSE_COMPONENTS.length - 1
        const isOnCurrentLocation = includes(
          getLocationPathname(),
          to
        )

        const showStatus = index <= activeStageIndex

        const currentCourse = courseStatusData?.find(
          course => course.title === title
        )

        if (isOnCurrentLocation && !currentStage) {
          setCurrentStage({ to, index })
        }

        return (
          <CourseComponentItem
            key={title}
            isFirst={isFirst}
            isLast={isLast}
            isActive={isOnCurrentLocation}
            onClick={() => handleCourseClick(to, index)}
            showStatus={showStatus}
            isDone={currentCourse?.status}
          >
            {title}
          </CourseComponentItem>
        )
      })}
    </CourseComponentNav>
  )
}

export default CourseNav
