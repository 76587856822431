import { useMutation } from 'react-query'

import { changePassword } from './api'

export const useChangePassword = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    changePassword
  )

  return {
    changePw: mutate,
    isLoading,
    isSuccess,
    error
  }
}
