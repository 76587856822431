import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import Modal from 'components/Modal'
import Plain from './Plain'
import Edit from './Edit'

const SessionCard = ({
  handleDeleteWorkshop,
  handleEditWorkshop,
  workshop,
  index,
  companies
}) => {
  const {
    title,
    date,
    agenda,
    notes,
    todo,
    id,
    files,
    Company
  } = workshop
  const [isOpen, setIsOpen] = useState(index === 0)
  const [isEditing, setIsEditing] = useState(false)
  const { isAtLeastSmallScreen } = useDeviceSize()

  const toggleCollapse = () => setIsOpen(open => !open)

  const toggleEditMode = () => {
    setIsEditing(editing => !editing)
    if (!isOpen) toggleCollapse()
  }

  const { name: company, id: companyId } = Company
  const attendees = Company.Users.map(user => ({
    image: user.profileImage,
    name: `${user.firstName} ${user.lastName}`
  }))

  const props = {
    workshopId: id,
    files,
    title,
    date,
    agenda,
    notes,
    todo,
    isOpen,
    editToggle: toggleEditMode,
    handleDeleteWorkshop,
    handleEditWorkshop,
    company,
    attendees,
    companyId,
    companies
  }

  if (isAtLeastSmallScreen)
    return isEditing ? (
      <Edit {...props} />
    ) : (
      <Plain toggle={toggleCollapse} {...props} />
    )

  return (
    <>
      <Plain {...props} toggle={toggleCollapse} />
      <Modal
        show={isEditing}
        closeModal={toggleEditMode}
        mimicNative
        title="Edit Session"
      >
        <Edit {...props} />
      </Modal>
    </>
  )
}

SessionCard.propTypes = {
  workshop: PropTypes.object,
  index: PropTypes.number
}

export default SessionCard
