export const GET_PERSONALITY =
  'pages/member/dashboard/plp/getPersonality'

export const DEFAULT_STEPS = [
  {
    id: 1,
    step: 'Complete the PLP survey',
    status: false
  },
  {
    id: 2,
    step:
      'Take part in a coaching session to review your profile',
    status: false
  },
  {
    id: 3,
    step:
      'Review PLP on your own and note potential development themes',
    status: false
  }
]
