import React, { useRef } from 'react'

import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg'
import { noop } from 'utils/helpers'

import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'

const TagsInput = ({
  tags = [],
  onAddTag = noop,
  onRemoveTag = noop
}) => {
  const ref = useRef(null)

  async function handleAddTag(e) {
    const { value } = e.target
    if (!value) return
    if (tags.indexOf(value) !== -1) return

    await onAddTag(e)
    ref.current.value = ''
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleAddTag(e)
    }
  }

  return (
    <div className={styles.tagsContainer}>
      {tags.map(tag => (
        <div key={tag} className={styles.tag}>
          <span>{tag}</span>
          <TouchableOpacity onClick={() => onRemoveTag(tag)}>
            <RemoveIcon width={8} height={8} />
          </TouchableOpacity>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        ref={ref}
        className={[
          styles.input,
          ...(tags.length ? [styles.hasTags] : [])
        ].join(' ')}
        type="text"
        placeholder="Enter email addresses here"
        onBlur={handleAddTag}
      />
    </div>
  )
}

export default TagsInput
