import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { isEmpty, includes } from 'lodash'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import Card from 'components/Card'
import TouchableOpacity from 'components/TouchableOpacity'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'

import memberStyles from 'pages/Member/styles.module.scss'

import styles from '../styles.module.scss'
import { goalSchema } from '../constants'

const Add = ({ isLoading, submit, toggleAddMode }) => {
  const [actionSteps, setActionSteps] = useState([
    {
      id: 1,
      description: ''
    }
  ])
  const [actionStepError, setActionStepError] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(goalSchema)
  })

  const addActionStep = () => {
    setActionStepError(null)
    const lastStepId = actionSteps.length
      ? actionSteps[actionSteps.length - 1].id
      : 0
    setActionSteps([
      ...actionSteps,
      { id: lastStepId + 1, description: '' }
    ])
  }

  const removeActionStep = id => {
    const hasOneActionStep = actionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = actionSteps.filter(step => step.id !== id)
      setActionSteps(output)
    } else {
      setActionStepError('At least one action step is required')
    }
  }

  const actionStepOnChange = (id, newValue) => {
    const index = actionSteps.findIndex(step => step.id === id)
    actionSteps[index].description = newValue
  }

  const addGoalSubmit = ({ title }) => {
    const validSteps = actionSteps.filter(
      step => !isEmpty(step.description)
    )
    const isValid = actionSteps.length === validSteps.length
    if (
      includes(
        actionSteps.map(
          addStep => addStep.description.length < 295
        ),
        false
      )
    ) {
      setActionStepError(
        'Entry is above character maximum, please reduce the length before saving'
      )
      return
    }
    if (isValid) {
      setActionStepError(null)
      submit({
        title,
        actionSteps
      })
    } else {
      setActionStepError(
        'All action steps require a description'
      )
    }
  }
  return (
    <Card className={`${styles.devPlanCard} mb-20`}>
      <Row>
        <Col xs={9}>
          <FormControl
            placeholder="Plan title"
            formGroupClass="mb-8"
            as="textarea"
            rows="1"
            name="title"
            inputRef={register}
            error={errors.title}
          />
        </Col>
        <Col xs={3}>
          <FormControl
            placeholder="Plan date"
            formGroupClass="mb-8"
            as="datepicker"
            disabled
            icon={<IconCalendar />}
          />
        </Col>
      </Row>
      <div className="mb-20">
        <Col xs={12} className={memberStyles.dividerLine} />
      </div>
      <div className="d-flex justify-content-between mb-10">
        <div className="font-weight-medium text-primary">
          Action items:
        </div>
        <TouchableOpacity
          className="text-secondary"
          onClick={addActionStep}
        >
          + Add action item
        </TouchableOpacity>
      </div>
      <div className="mb-30">
        {actionSteps.map(step => (
          <Row
            key={`add-plan-action-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                placeholder="Action step description"
                formGroupClass={`${styles.actionItemInput} mb-0 mr-20`}
                as="textarea"
                rows="1"
                defaultValue={step.description}
                onChange={e => {
                  actionStepOnChange(step.id, e.target.value)
                }}
              />
              <TouchableOpacity
                className="d-flex align-items-center"
                onClick={() => removeActionStep(step.id)}
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
        {actionStepError && (
          <small className="text-danger">
            {actionStepError}
          </small>
        )}
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button
          variant="link"
          smallPadding
          onClick={toggleAddMode}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          smallPadding
          onClick={handleSubmit(addGoalSubmit)}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Add Goal'}
        </Button>
      </div>
    </Card>
  )
}

Add.propTypes = {
  isLoading: PropTypes.bool,
  submit: PropTypes.func,
  toggleAddMode: PropTypes.func
}

export default Add
