import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'

import styles from './styles.module.scss'

const Thumbnail = ({ size = 30, src }) => {
  return (
    <div
      className={styles.thumbnail}
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size
      }}
    >
      <Image
        src={src}
        className={styles.image}
        style={{
          minWidth: size,
          maxWidth: size,
          maxHeight: size,
          minHeight: size
        }}
      />
    </div>
  )
}

Thumbnail.propTypes = {
  src: PropTypes.string
}

export default Thumbnail
