import React, { useEffect } from 'react'
import { isArray, isEmpty } from 'lodash'

import { useRightSidebar } from 'global/hooks/useRightSidebar'
import { useUserWorkshop } from 'global/hooks/useUserWorkshop'
import { useGlobalContext } from 'global/Context'

import memberMenuLinks from 'utils/helpers/memberMenuLinks'

import FormCheck from 'components/Form/FormCheck'

import { useSession } from 'pages/Member/CoachingSessions/hooks'

const RightSidebar = () => {
  const { sideBarData, getRightSideBarData } = useRightSidebar()
  const { editSession } = useSession()
  const { editUserWorkshop } = useUserWorkshop()
  const { setMenuLinks } = useGlobalContext()

  const handleEditCheck = ({ id, type, index }) => {
    const newSteps = sideBarData
      .find(x => x.type === type)
      .steps.map((step, stepIndex) => ({
        ...step,
        status: stepIndex === index ? !step.status : step.status
      }))

    if (type === 'coachingSession') {
      return editSession({
        id,
        payload: {
          steps: newSteps
        }
      })
    }

    return editUserWorkshop({
      workshopId: id,
      payload: {
        actionSteps: newSteps
      }
    })
  }

  useEffect(() => {
    setMenuLinks(
      memberMenuLinks.filter(link => link.to !== '/workshops')
    )
    const loadData = async () => {
      await getRightSideBarData()

      if (isArray(sideBarData) && !isEmpty(sideBarData)) {
        const workshopData = sideBarData.find(
          x => x.type === 'workshop'
        )

        const hasNoWorkshop = workshopData?.steps.length === 0

        const leftMenuLinks = hasNoWorkshop
          ? memberMenuLinks.filter(
              link => link.to !== '/workshops'
            )
          : memberMenuLinks

        setMenuLinks(leftMenuLinks)
      }
    }

    loadData()
  }, [sideBarData, getRightSideBarData, setMenuLinks])

  return (
    <div>
      {isArray(sideBarData) && (
        <div>
          <div className="mb-5" style={{ fontSize: 18 }}>
            Action Steps
          </div>
          {sideBarData?.map(
            ({ steps, title, id, type }, parentIndex) => {
              const classes = ['pb-10 mb-20']
              if (sideBarData.length - 1 !== parentIndex) {
                classes.push('border-bottom')
              }

              if (type === 'workshop' && steps.length === 0) {
                return null
              }

              return (
                <div
                  key={parentIndex}
                  className={classes.join(' ')}
                >
                  <p className="font-weight-bold text-primary mb-15">
                    {title}
                  </p>
                  {steps.length ? (
                    steps.map(
                      ({ description, status }, index) => (
                        <FormCheck
                          onChange={() =>
                            handleEditCheck({ id, type, index })
                          }
                          controlId={`actionStep[${parentIndex}][${index}]`}
                          key={`actionStep[${parentIndex}][${index}]`}
                          label={description}
                          defaultChecked={status}
                        />
                      )
                    )
                  ) : (
                    <p className="text-center">No data.</p>
                  )}
                </div>
              )
            }
          )}
        </div>
      )}
    </div>
  )
}

export default RightSidebar
