export const actionSteps = [
  {
    title: 'From last coaching session ',
    steps: [
      {
        label: 'Consider hiring Chief of Staff',
        status: true
      },
      { label: 'Exercise at least 2x per week', status: true },
      {
        label: 'Write job description for visual designer',
        status: false
      }
    ]
  },
  {
    title: 'From last workshop',
    steps: [
      {
        label: 'Consider hiring Chief of Staff',
        status: true
      },
      { label: 'Exercise at least 2x per week', status: true },
      {
        label: 'Write job description for visual designer',
        status: false
      }
    ]
  }
]
