import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconRemove } from 'assets/icons/close-icon.svg'

import { getFileIcon } from 'utils/helpers/icon'

import styles from './styles.module.scss'

const FileDisplay = ({ fileName, remove, onClick, ...rest }) => {
  const FileIcon = fileName ? getFileIcon(fileName) : () => null

  return (
    <div
      className={styles.fileDisplay}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'unset' }}
      {...rest}
    >
      {remove && (
        <div className={styles.removeIcon} onClick={remove}>
          <IconRemove />
        </div>
      )}
      <div className={styles.iconWrapper}>
        <FileIcon />
      </div>
      <div className={styles.fileName}>{fileName}</div>
    </div>
  )
}

FileDisplay.propTypes = {
  url: PropTypes.string
}

export default FileDisplay
