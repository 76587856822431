export const GET_RESOURCES =
  'pages/coach/resourcesLibrary/getResources'
export const GET_RESOURCE_CATEGORIES =
  'pages/coach/resourcesLibrary/getResourceCategories'

// Non-redux initializations
export const RESOURCE_TYPE_LINK = 'link'
export const RESOURCE_TYPE_DOCS = 'document'

export const messages = {
  url: 'Please a enter a valid URL with protocol (https/http)',
  required: 'This field is required'
}
