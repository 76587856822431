import { getRequest, postRequest } from 'utils/http'

export const getProgressCheck = id => {
  return getRequest(`/stakeholders/progress-check/${id}`)
}

export const editProgressCheckApi = ({
  miniSurveyId,
  userId
}) => {
  return postRequest(
    `/stakeholders/progress-check/${userId}/${miniSurveyId}`
  )
}

export const getStakeholder = () => {
  return getRequest('/stakeholders/list/profiles')
}

export const postSendSurvey = payload => {
  return postRequest(
    `/stakeholders/user/${payload.id}/send-survey`,
    payload.data
  )
}
