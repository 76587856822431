import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import { getFileLink } from 'utils/helpers/file'

import DashboardWrapper from 'components/DashboardWrapper'

import { hasData } from 'utils/helpers'

import styles from './styles.module.scss'
import ResourceCard from './ResourceCard'
import { useResourceLibrary } from './hooks'
import { resourceTypes } from './constant'

const transformResourceData = data => {
  if (!data?.length) return data

  return data.map(resource => {
    const og = resource.ogContent || null
    const isLink = resource?.Resource.type === resourceTypes.LINK

    return {
      id: resource?.Resource.id,
      description: resource?.Resource.description,
      image: og?.ogImage,
      title: resource?.Resource.title,
      url: isLink
        ? resource?.Resource.url
        : getFileLink(resource?.Resource.url),
      sourceName: resource?.Resource.source,
      content: og?.ogDescription,
      siteName: og?.ogSiteName || og?.ogTitle,
      siteIcon: og?.ogFavicon,
      siteImage: og?.ogImage,
      type: resource?.Resource.type
    }
  })
}

const ResourceLibrary = () => {
  useHeaderTitle('Resource Library')

  const {
    resourceLibrary,
    resourceLibraryLoading
  } = useResourceLibrary()

  const recommendedResources = useMemo(
    () => transformResourceData(resourceLibrary?.recommended),
    [resourceLibrary?.recommended]
  )

  const isEmpty = useMemo(() => {
    return (
      !hasData(recommendedResources) && !resourceLibraryLoading
    )
  }, [recommendedResources, resourceLibraryLoading])

  return (
    <DashboardWrapper className={`${styles.resourceLibrary}`}>
      {resourceLibraryLoading && (
        <Spinner animation="border" variant="primary" />
      )}

      {hasData(recommendedResources) && (
        <div className={styles.recommendedResourcesWrapper}>
          {recommendedResources.map(({ id, title, ...rest }) => (
            <ResourceCard
              key={id}
              title={title}
              recommended
              {...rest}
            />
          ))}
        </div>
      )}

      {isEmpty && <p>No resources yet.</p>}
    </DashboardWrapper>
  )
}

ResourceLibrary.propTypes = {}

export default ResourceLibrary
