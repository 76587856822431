import * as yup from 'yup'

export const GET_DEV_PLAN =
  'pages/member/dashboard/developmentPlan/getDevPlan'

export const GET_DEV_STATS =
  'pages/member/dashboard/developmentPlan/getDevChart'

export const goalSchema = yup.object().shape({
  title: yup.string().required('Please enter goal title')
})

export const chartYTickValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
