export const PROFILE_ROUTES = [
  {
    title: 'My Profile',
    to: '/my-profile'
  },
  // {
  //   title: 'Reminders',
  //   to: '/settings'
  // },
  {
    title: 'Logout',
    to: '#'
  }
]
