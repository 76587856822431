import { isNil, reduce } from 'lodash'

const trimString = data => {
  if (!(typeof data === 'string')) return data
  return data.trim()
}
export const cleanPayload = payload => {
  if (!payload) return undefined

  const filterEmptyValues = (acc, curr, key) => {
    const trimmed = trimString(curr)
    if (isNil(trimmed)) return acc
    if (trimmed === '') return acc

    return {
      ...acc,
      [`${key}`]: trimmed
    }
  }

  return reduce(payload, filterEmptyValues, {})
}
