import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'
import { useState, useEffect } from 'react'

import { GET_SIDEBAR_CONTENT } from 'global/hooks/useRightSidebar/constants'

import {
  createSession as createSessionAPI,
  editSession as editSessionAPI,
  deleteSession as deleteSessionAPI,
  getSessions,
  createCoachingDoc as createCoachingDocAPI,
  editCoachingDoc as editCoachingDocAPI,
  deleteCoachingDoc as deleteCoachingDocAPI,
  getCoachingDocs
} from './api'
import {
  GET_COACHING_DOCUMENTS,
  GET_SESSIONS
} from './constants'

export const useSession = userId => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch
  } = useQuery(GET_SESSIONS, () => getSessions(userId))

  const [sessionData, setSessionData] = useState()

  useEffect(() => {
    if (data) {
      setSessionData(data)
    }
  }, [data])
  const { mutate: createSession } = useMutation(
    createSessionAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SESSIONS)
        queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
      }
    }
  )

  const { mutate: editSession } = useMutation(editSessionAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_SESSIONS)
      queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
    }
  })

  const { mutate: deleteSession } = useMutation(
    deleteSessionAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SESSIONS)
        queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
      }
    }
  )

  return {
    sessionData,
    isLoading,
    isSuccess,
    error,
    refetch,

    createSession,
    editSession,
    deleteSession
  }
}

export const useCoachingDocuments = userId => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch
  } = useQuery(GET_COACHING_DOCUMENTS, () =>
    getCoachingDocs(userId)
  )

  const { mutate: createCoachingDoc } = useMutation(
    createCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  const { mutate: editCoachingDoc } = useMutation(
    editCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  const { mutate: deleteCoachingDoc } = useMutation(
    deleteCoachingDocAPI,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_COACHING_DOCUMENTS)
    }
  )

  return {
    data,
    isLoading,
    isSuccess,
    error,

    refetch,
    getCoachingDocs,
    createCoachingDoc,
    editCoachingDoc,
    deleteCoachingDoc
  }
}
