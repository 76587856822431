import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Col, Row, Card } from 'react-bootstrap'

import { useQueryParams } from 'utils/html/queryParams'

import Container from 'components/Container'
import H1 from 'components/H1'
import H2 from 'components/H2'
import FormCheck from 'components/Form/FormCheckCustom'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'
import Toast from 'components/Toast'
import PageLoader from 'components/PageLoader'

import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import Logo from './logo'
import { useSurvey } from './hooks'

const Survey = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const token = queryParams.get('token')
  const {
    postsurvey,
    surveyLoading,
    showToast,
    setShowToast,
    message,
    getName,
    surveyDetails,
    getSurveyDetailsIsLoading
  } = useSurvey(token)

  const { profileName, userPlanGoals } = surveyDetails ?? {}

  const hasPlanGoals = userPlanGoals?.length

  const userName = profileName || ''

  const questionFourSchema = {}

  // eslint-disable-next-line
  const questionFourMap = userPlanGoals?.map((goal, index) => {
    questionFourSchema[`q4-${index}`] = yup.string().required()
    return index
  })

  const surveyFormSchema = yup.object().shape({
    q1: yup.string().required(),
    q2: yup.string().required(),
    q3: yup.string().required(),
    ...questionFourSchema,
    q5: yup.string().required(),
    q6: yup.string().required()
  })

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(surveyFormSchema)
  })

  const surveySave = async data => {
    await postsurvey({ ...data, token })
    history.push(`/stakeholders/survey/success?name=${userName}`)
  }

  useEffect(() => {
    getName()
  }, [getName])

  return (
    <PageLoader loader={getSurveyDetailsIsLoading}>
      <Container className={styles.surveyWrapper}>
        <Row className="justify-content-md-center">
          <Col xs lg="12" className="mb-20">
            <div className={styles.contentWrapper}>
              <Logo />
              <H1 className="text-primary font-weight-normal">
                Mini Survey for {userName}
              </H1>
              <p>
                Please complete the questions below honestly.
                Your response is anonymous and will be combined
                with others’ to maintain confidentiality. Thank
                you!
              </p>
            </div>
          </Col>
          <Col xs lg="12" className="mb-20">
            <Card className={styles.contentWrapper}>
              <div className={styles.questionWrapper}>
                <H2>
                  Has {userName} shared their leadership
                  developmental goals with you?
                </H2>
                <FormCheck
                  type="radio"
                  controlId="q1"
                  inputRef={register}
                  name="q1"
                  id={1}
                  label="Yes"
                  value="yes"
                />
                <FormCheck
                  type="radio"
                  controlId="q1"
                  inputRef={register}
                  name="q1"
                  id={2}
                  label="No"
                  value="no"
                />
                {errors && errors.q1 && (
                  <small className="text-danger">
                    Please select your answer!
                  </small>
                )}
              </div>
            </Card>
          </Col>
          <Col xs lg="12" className="mb-20">
            <Card className={styles.contentWrapper}>
              <div className={styles.questionWrapper}>
                <H2>
                  How often has {userName} followed up with you
                  concerning how they could improve?
                </H2>
                <FormCheck
                  controlId="q2"
                  type="radio"
                  inputRef={register}
                  name="q2"
                  id="q2-none"
                  label="None"
                  value="none"
                />
                <FormCheck
                  controlId="q2"
                  type="radio"
                  inputRef={register}
                  name="q2"
                  id="q2-once"
                  label="Once"
                  value="once"
                />
                <FormCheck
                  controlId="q2"
                  type="radio"
                  inputRef={register}
                  name="q2"
                  id="q2-twice"
                  label="Twice"
                  value="twice"
                />
                <FormCheck
                  controlId="q2"
                  type="radio"
                  name="q2"
                  inputRef={register}
                  id="q2-three"
                  label="Three or more times"
                  value="threeOrMore"
                />
                {errors && errors.q2 && (
                  <small className="text-danger">
                    Please select your answer!
                  </small>
                )}
              </div>
            </Card>
          </Col>
          <Col xs lg="12" className="mb-20">
            <Card className={styles.contentWrapper}>
              <div className={styles.questionWrapper}>
                <H2>
                  In the past several months, do you feel{' '}
                  {userName} has become more or less effective as
                  a leader?
                </H2>
                <Row>
                  <Col lg={5}>
                    <Row>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-1"
                          upperlabel="-3"
                          value="-3"
                        />
                        <p>Less Effective</p>
                      </Col>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-2"
                          upperlabel="-2"
                          value="-2"
                        />
                      </Col>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-3"
                          upperlabel="-1"
                          value="-1"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={2}>
                    <Row>
                      <Col lg={12}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-4"
                          upperlabel="0"
                          value="0"
                        />
                        <p>No Change</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5}>
                    <Row>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-5"
                          upperlabel="+1"
                          value="+1"
                        />
                      </Col>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-6"
                          upperlabel="+2"
                          value="+2"
                        />
                      </Col>
                      <Col lg={4}>
                        <FormCheck
                          controlId="q3"
                          inputRef={register}
                          type="radio"
                          name="q3"
                          id="q3-7"
                          upperlabel="+3"
                          value="+3"
                        />
                        <p>More Effective</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {errors && errors.q3 && (
                  <small className="text-danger">
                    Please select your answer!
                  </small>
                )}
              </div>
            </Card>
          </Col>
          {hasPlanGoals && (
            <Col xs lg="12" className="mb-20">
              <Card className={styles.contentWrapper}>
                <div className={styles.questionWrapper}>
                  <H2 style={{ marginBottom: 15 }}>
                    In the past several months, has {userName}{' '}
                    become more or less effective in the
                    following goal areas?
                  </H2>
                  {userPlanGoals?.map((goal, index) => {
                    const formId = `q4-${index}`
                    return (
                      <div key={formId}>
                        <h4>{goal.title}</h4>
                        <Row>
                          <Col lg={5}>
                            <Row>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-1`}
                                  upperlabel="-3"
                                  value="-3"
                                />
                                <p>Less Effective</p>
                              </Col>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-2`}
                                  upperlabel="-2"
                                  value="-2"
                                />
                              </Col>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-3`}
                                  upperlabel="-1"
                                  value="-1"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={2}>
                            <Row>
                              <Col lg={12}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-4`}
                                  upperlabel="0"
                                  value="0"
                                />
                                <p>No Change</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5}>
                            <Row>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-5`}
                                  upperlabel="+1"
                                  value="+1"
                                />
                              </Col>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-6`}
                                  upperlabel="+2"
                                  value="+2"
                                />
                              </Col>
                              <Col lg={4}>
                                <FormCheck
                                  controlId={formId}
                                  type="radio"
                                  name={formId}
                                  inputRef={register}
                                  id={`${formId}-7`}
                                  upperlabel="+3"
                                  value="+3"
                                />
                                <p>More Effective</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {errors && errors[formId] && (
                          <small className="text-danger">
                            Please select your answer!
                          </small>
                        )}
                      </div>
                    )
                  })}
                </div>
              </Card>
            </Col>
          )}
          <Col xs lg="12" className="mb-20">
            <Card className={styles.contentWrapper}>
              <div className={styles.questionWrapper}>
                <H2>
                  What has {userName} done in the past several
                  months that you have found particularly
                  effective?
                </H2>
                <FormControl
                  inputRef={register}
                  type="string"
                  name="q5"
                  labelClassName="font-weight-medium"
                  as="textarea"
                  rows={3}
                />
                {errors && errors.q5 && (
                  <small className="text-danger">
                    Please enter your answer!
                  </small>
                )}
              </div>
            </Card>
          </Col>
          <Col xs lg="12" className="mb-20">
            <Card className={styles.contentWrapper}>
              <div className={styles.questionWrapper}>
                <H2>
                  What can {userName} do to be even more
                  effective as a leader?
                </H2>
                <FormControl
                  inputRef={register}
                  type="string"
                  name="q6"
                  labelClassName="font-weight-medium"
                  as="textarea"
                  rows={3}
                />
                {errors && errors.q6 && (
                  <small className="text-danger">
                    Please enter your answer!
                  </small>
                )}
              </div>
            </Card>
          </Col>
          <Col lg={12} className={styles.button}>
            <Button
              variant="secondary"
              smallPadding
              type="submit"
              onClick={handleSubmit(surveySave)}
              disabled={surveyLoading}
            >
              {surveyLoading ? 'Submitting' : 'Submit'}
            </Button>
          </Col>
        </Row>
        <Toast
          show={showToast}
          toggle={() => setShowToast(false)}
          variant="danger"
          position="top-right"
          title="Error"
          titleClass="text-white font-weight-bold"
        >
          <div className="text-white">{message}</div>
        </Toast>
      </Container>
    </PageLoader>
  )
}

Survey.propTypes = {}

export default Survey
