import React from 'react'
import { Image } from 'react-bootstrap'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

import ImageProfilePlaceholder from 'assets/images/profile-placeholder.png'
import { ReactComponent as IconClose } from 'assets/icons/close-icon.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'

import { MEMBER_ROLE, COACH_ROLE } from 'global/constants'

import { useUser } from 'utils/hooks/useUser'
import { useLogout } from 'utils/hooks/useLogout'
import { getFileLink } from 'utils/helpers/file'

import menuLinks from 'pages/Member/menuLinks'
import profileLinks from 'pages/Member/profileLinks'
import { useProfile } from 'pages/Profile/hooks'
import { ROUTES as COACH_ROUTES } from 'pages/Coach/constants'

import DynamicLink from 'components/DynamicLink'
import IconToggler from 'components/IconToggler'
import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'
import { PROFILE_ROUTES } from './constant'

const memberMenu = [...menuLinks, ...profileLinks]
const coachMenu = [...COACH_ROUTES, ...PROFILE_ROUTES]

const MobileMenu = ({ isOpen, toggleMenu }) => {
  const classes = ['d-md-none', `${styles.mobileMenu}`]
  if (isOpen) classes.push(`${styles.isOpen}`)

  const { firstName, lastName, company, role } = useUser()
  const { data } = useProfile()
  const [logout] = useLogout()
  const { pathname } = useLocation()

  const profileUrl = data?.profileImage
    ? getFileLink(data?.profileImage)
    : ImageProfilePlaceholder

  const menu =
    {
      [MEMBER_ROLE]: memberMenu,
      [COACH_ROLE]: coachMenu
    }[role] || []

  return (
    <div className={classes.join(' ')}>
      <div className="mx-30">
        <div className={styles.profile}>
          <Image
            roundedCircle
            src={profileUrl}
            className="mr-15"
            style={{ maxWidth: 60, maxHeight: 60 }}
          />
          <div className={styles.summary}>
            <p className="mb-8">
              {firstName} {lastName}
            </p>
            <p className={styles.subtitle}>{company}</p>
          </div>
        </div>
        <div className={styles.closeIconWrapper}>
          <button onClick={toggleMenu}>
            <IconClose width={30} />
          </button>
        </div>
      </div>

      <ul className={styles.linksWrapper}>
        {menu.map(({ title, to, icon }) => {
          const linkClasses = [`${styles.link}`]
          const isActive = includes(pathname, to)
          if (isActive) linkClasses.push(`${styles.isActive}`)

          return (
            <li
              className={linkClasses.join(' ')}
              key={`${title}/${20}`}
            >
              <DynamicLink to={to}>
                <span className={styles.iconWrapper}>
                  <IconToggler icon={icon} active={isActive} />
                </span>
                <span>{title}</span>
              </DynamicLink>
            </li>
          )
        })}

        <li className={`${styles.link}`}>
          <TouchableOpacity onClick={logout}>
            <span className={styles.iconWrapper}>
              <LogoutIcon />
            </span>
            <span>Logout</span>
          </TouchableOpacity>
        </li>
      </ul>
    </div>
  )
}

export default MobileMenu
