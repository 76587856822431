import Card from 'components/Card'
import CircularProgress from 'components/CircularProgress'
import StyledCheckbox from 'components/StyledCheckbox'
import React from 'react'

const ProgressCard = ({ steps, progress, onChange }) => {
  let disableStep

  return (
    <Card
      className="h-100 d-flex align-items-center flex-column flex-lg-row"
      style={{ minHeight: 166 }}
    >
      <div
        style={{ maxWidth: 100 }}
        className="mb-20 mb-lg-0 mr-lg-20"
      >
        <CircularProgress
          value={progress}
          text={`${progress}%`}
        />
      </div>
      <div style={{ width: '100%' }}>
        <p className="text-primary font-weight-medium mb-10">
          Next Steps:
        </p>
        {steps.map((step, index) => {
          const key = `checkbox-${step.id}`
          const checked = step.status
          const disabled = !!disableStep

          const nextIndex = index + 1
          const nextStepIsChecked = steps[nextIndex]?.status
          const readOnly = nextStepIsChecked

          if (!checked) {
            disableStep = true
          }

          return (
            <StyledCheckbox
              key={key}
              controlId={key}
              disabled={disabled}
              checked={checked}
              label={step.step}
              onChange={() => {
                if (!readOnly) {
                  onChange(step.id, !checked)
                }
              }}
              readOnly={readOnly}
            />
          )
        })}
      </div>
    </Card>
  )
}

export default ProgressCard
