import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'

import Select from 'components/Select'

import styles from './styles.module.scss'

const Filters = ({ filters, filterValue, onFilterChange }) => {
  return (
    <div className={styles.filterWrapper}>
      <FilterIcon width={20} />
      <p>Filter by:</p>
      {filters.map(({ name, options, ...rest }) => (
        <Select
          key={name}
          name={name}
          options={options}
          dropdown
          onChange={e => onFilterChange(name, e.value)}
          filterValue={filterValue || options[0].value}
          {...rest}
        />
      ))}
    </div>
  )
}

Filters.propTypes = {
  filters: PropTypes.array.isRequired
}

export default Filters
