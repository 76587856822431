import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getDevPlanApi = () => {
  return getRequest(`/development-plans?filter=this-week`)
}

export const getStats = query => {
  const { queryKey } = query
  return getRequest(
    `/development-plans/statistics?filter=${
      queryKey[1].filterData || 'this-week'
    }`
  )
}

export const editStepsApi = payload => {
  return putRequest('/development-plans', payload)
}

export const addPlanGoalApi = payload => {
  return postRequest('/development-plans/goals', payload)
}

export const deletePlanGoalApi = planId => {
  return deleteRequest(`/development-plans/goals/${planId}`)
}

export const editStepRateApi = payload => {
  const { planId, stepId, rate } = payload
  return putRequest(
    `/development-plans/goals/${planId}/action-steps/${stepId}/rate`,
    { rate: Number(rate) }
  )
}

export const editPlanGoalApi = async payload => {
  const {
    title,
    planId,
    addedSteps,
    editedSteps,
    deletedSteps
  } = payload
  const addRequestPayload = {
    actionSteps: addedSteps.map(step => step.description)
  }

  const editRequest = editedSteps.forEach(step => {
    return putRequest(
      `/development-plans/goals/${planId}/action-steps/${step.id}`,
      {
        description: step.description
      }
    )
  })

  const deleteRequest = deletedSteps.forEach(step => {
    return patchRequest(
      `/development-plans/goals/${planId}/action-steps/${step.id}/delete`
    )
  })

  await Promise.all([editRequest, deleteRequest])
  await putRequest(`/development-plans/goals/${planId}`, {
    title
  })
  return postRequest(
    `/development-plans/goals/${planId}/action-steps/`,
    addRequestPayload
  )
}
