import React from 'react'
import { Col, Row, Card } from 'react-bootstrap'

import Container from 'components/Container'
import H2 from 'components/H2'
import Logo from '../logo'
import styles from './styles.module.scss'

const Survey = () => {
  return (
    <Container className={styles.surveyWrapper}>
      <Row className="justify-content-md-center">
        <Col xs lg="12" className="mb-20">
          <Card>
            <div className={styles.contentWrapper}>
              <Logo />
              <H2
                className={`${styles.title} danger text-primary font-weight-normal`}
              >
                Survey is already answered!
              </H2>
              <p>
                Looks like you have already submitted a response
                to this survey. If you believe this is in error,
                please send a message to
                help@peakleadershipinstitute.com.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Survey.propTypes = {}

export default Survey
