import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getOkrs = () => {
  return getRequest(`/okrs`)
}

export const createOkr = ({ payload }) => {
  return postRequest(`/okrs`, payload)
}

export const editOkr = ({ id, payload }) => {
  return putRequest(`/okrs/${id}`, payload)
}

export const deleteOkr = ({ id }) => {
  return deleteRequest(`/okrs/${id}`)
}
