import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import { GET_COURSE_STATUS } from 'global/hooks/useUserCouseStatus/constants'

import { editSteps, getPLP } from './api'
import { GET_PERSONALITY } from './constants'

export const usePersonality = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_PERSONALITY,
    getPLP
  )

  const { mutate } = useMutation(editSteps, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_PERSONALITY)
      queryClient.invalidateQueries(GET_COURSE_STATUS)
    }
  })

  return {
    data,
    isLoading,
    isSuccess,
    error,

    editSteps: mutate
  }
}
