import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const StyledCheckbox = ({
  className,
  checked,
  controlId,
  error,
  label = '',
  disabled,
  onChange,
  readOnly,
  ...rest
}) => {
  const hasError = !!error
  return (
    <div
      className={`${styles.formCheck} ${
        className || ''
      } d-flex flex-row`}
      {...rest}
    >
      <input
        type="checkbox"
        id={controlId}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={controlId}
        className={`${disabled ? `${styles.disabled}` : ''} ${
          readOnly ? `${styles.readOnly}` : ''
        } mb-0`}
      >
        {label}
      </label>
      {hasError && (
        <small className="text-danger">{error.message}</small>
      )}
    </div>
  )
}

StyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  controlId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default StyledCheckbox
