import React from 'react'

import { Route } from 'react-router-dom'

import { Routes } from 'global/routes'

import Profile from 'pages/Profile'

export const CommonRoutes = () => (
  <>
    <Route path={Routes.MY_PROFILE} component={Profile} />
  </>
)
