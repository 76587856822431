import React from 'react'
import {
  OverlayTrigger,
  Tooltip as BTTooltip
} from 'react-bootstrap'

const Tooltip = ({
  children,
  placement,
  id = 'tooltip',
  tip
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<BTTooltip id={id}>{tip}</BTTooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip
