import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  oldPassword: yup.string().required(`Please your old password`),
  newPassword: yup.string().required(`Please your new password`),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      'Passwords must match'
    )
})

const resolver = yupResolver(schema)

export { schema, resolver }
