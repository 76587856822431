import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryArea,
  VictoryScatter
} from 'victory'

import { $black } from 'utils/helpers/colors'

import styles from './styles.module.scss'

const LineChart = ({
  data,
  yDomain,
  yTickValues,
  hideXAxis
}) => {
  const [list, setList] = useState([])
  useEffect(() => {
    const temp = data
    for (let i = 0; i < temp.length; i++) {
      temp[i].color = `#${Math.random().toString(16).substr(-6)}`
    }
    setList(temp)
  }, [data])

  return (
    <Row>
      <Col lg={9}>
        <VictoryChart
          scale={{ x: 'time' }}
          height={350}
          width={800}
          style={{ parent: { height: 350 } }}
        >
          <VictoryAxis
            dependentAxis
            domain={yDomain}
            tickValues={yTickValues}
          />
          <VictoryAxis
            crossAxis
            style={{
              ticks: {
                stroke: hideXAxis ? 'transparent' : $black
              },
              tickLabels: {
                fill: hideXAxis ? 'transparent' : $black
              }
            }}
          />
          {list.map((x, i) => {
            return (
              <VictoryGroup
                key={i}
                style={{
                  data: {
                    strokeWidth: 3,
                    fillOpacity: 0.4
                  }
                }}
              >
                <VictoryArea
                  interpolation="catmullRom"
                  style={{
                    data: {
                      fill: x.color,
                      stroke: x.color
                    }
                  }}
                  data={x.actionSteps}
                />
                <VictoryScatter
                  data={x.actionSteps}
                  size={5}
                  style={{
                    data: {
                      fill: x.color,
                      stroke: x.color
                    }
                  }}
                />
              </VictoryGroup>
            )
          })}
        </VictoryChart>
      </Col>
      <Col lg={3}>
        <div className={styles.key}>
          <h6>KEY</h6>
          <ul className={styles.ul}>
            {list.map((x, i) => {
              return (
                <li
                  key={i}
                  style={{
                    color: x.color,
                    fontSize: '20px'
                  }}
                >
                  <span style={{ color: 'black' }}>
                    {x.title}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </Col>
    </Row>
  )
}

LineChart.propTypes = {
  data: PropTypes.array
}

export default LineChart
