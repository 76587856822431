import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Form } from 'react-bootstrap'

import { noop, switchFn } from 'utils/helpers'
import {
  getFirstDayOfCurrentMonth,
  dateToUTCISO
} from 'utils/helpers/date'

import styles from './styles.module.scss'
import { FORM_CONTROL_TYPES } from './constants'

import 'react-datepicker/dist/react-datepicker.css'

const FormControl = ({
  as,
  children,
  error,
  inputRef,
  label,
  labelClassName,
  className,
  formGroupClass,
  icon,
  defaultDate = new Date(),
  disableFutureDates,
  disablePreviousMonths,
  value,
  ...rest
}) => {
  const hasError = !!error
  const datePickerRef = useRef()
  const [dateValue, setDateValue] = useState(
    new Date(value || defaultDate)
  )
  const startOfMonth = getFirstDayOfCurrentMonth()

  const { SELECT, DATEPICKER } = FORM_CONTROL_TYPES

  const openDatePicker = () =>
    datePickerRef.current.setOpen(true)

  const renderFormControl = () => {
    return switchFn({
      [SELECT]: (
        <Form.Control
          as={as}
          ref={inputRef}
          isInvalid={hasError}
          className={`${styles.formControl} ${className || ''} ${
            icon ? styles.withIcon : ''
          }`}
          {...rest}
        >
          {children}
        </Form.Control>
      ),
      [DATEPICKER]: (
        <div>
          <Form.Control
            ref={inputRef}
            isInvalid={hasError}
            className={`${styles.formControl} ${
              className || ''
            } ${icon ? styles.withIcon : ''}`}
            {...rest}
            onFocus={openDatePicker}
            value={dateToUTCISO({
              date: dateValue,
              format: 'MMMM DD, YYYY'
            })}
            onChange={noop}
          />
          <DatePicker
            ref={datePickerRef}
            onChange={date => setDateValue(date)}
            selected={dateValue}
            maxDate={disableFutureDates ? new Date() : null}
            minDate={
              disablePreviousMonths
                ? new Date(startOfMonth)
                : null
            }
          />
        </div>
      )
    })(
      <Form.Control
        as={as}
        ref={inputRef}
        isInvalid={hasError}
        className={`${styles.formControl} ${className || ''} ${
          icon ? styles.withIcon : ''
        }`}
        {...rest}
      />
    )(as)
  }
  return (
    <Form.Group className={`${formGroupClass} flex-1`}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
        </Form.Label>
      )}
      <div className={styles.formControlWrapper}>
        <div className={styles.iconWrapper}>{icon || null}</div>
        {renderFormControl()}
      </div>
      {hasError && (
        <small className="text-danger">{error.message}</small>
      )}
    </Form.Group>
  )
}

FormControl.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  formGroupClass: PropTypes.string,
  icon: PropTypes.node
}

export default FormControl
