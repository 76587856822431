import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { Form, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'

import { useStakeholderEngagement } from '../hooks'

import styles from './styles.module.scss'

const stakeholderFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email')
    .required(`Please enter stakeholder's email`),
  name: yup.string().required(`Please enter stakeholder's name`),
  relationship: yup
    .string()
    .required('Please enter your relationship with stakeholder')
})

const EditStakeholder = ({
  modalData,
  showModal,
  setShowModal
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(stakeholderFormSchema)
  })
  const [showEditError, setShowEditError] = useState(false)
  const {
    id: stakeholderId,
    name,
    email,
    relationship
  } = modalData
  const {
    editStakeholder,
    editStakeholderSuccess,
    editStakeholderError,
    message
  } = useStakeholderEngagement()

  const formSubmit = ({ name, email, relationship }) => {
    editStakeholder({
      stakeholderId,
      payload: { name, email, relationship, stakeholderId }
    })
  }

  useEffect(() => {
    if (editStakeholderError) {
      setShowEditError(true)
    }
    if (editStakeholderSuccess) {
      setShowModal(false)
    }
  }, [
    setShowModal,
    editStakeholderError,
    editStakeholderSuccess
  ])

  return (
    <Modal
      mimicNative
      bodyClassName={styles.editStakeholder}
      show={showModal}
      title="Add Stakeholder"
      closeModal={() => setShowModal(false)}
    >
      <p className={`${styles.modalTitle} d-none d-sm-block`}>
        Edit Stakeholder
      </p>
      {showEditError && message && (
        <Alert variant="danger" dismissible>
          {message}
        </Alert>
      )}
      <Form
        onSubmit={handleSubmit(formSubmit)}
        className="mb-40"
      >
        <FormControl
          inputRef={register}
          type="string"
          name="name"
          placeholder="Name"
          error={errors.name}
          defaultValue={name}
        />
        <FormControl
          inputRef={register}
          type="string"
          name="email"
          placeholder="Email"
          error={errors.email}
          defaultValue={email}
        />

        <FormControl
          inputRef={register}
          type="string"
          name="relationship"
          placeholder="Relationship"
          error={errors.relationship}
          as="select"
          defaultValue={relationship}
        >
          <option>Peer</option>
          <option>Direct Report</option>
          <option>Manager</option>
          <option>Others</option>
        </FormControl>
      </Form>
      <div className="d-flex justify-content-end mb-20">
        <Button
          className="d-none d-sm-block"
          variant="link"
          smallPadding
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="form__button--submit"
          variant="secondary"
          smallPadding
          onClick={handleSubmit(formSubmit)}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

EditStakeholder.propTypes = {}

export default EditStakeholder
