import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'
import { isNil } from 'lodash'

import Card from 'components/Card'
import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'

const CardCollapse = ({
  children,
  isOpen,
  toggle,
  headerComponent,
  className,
  headerClass
}) => {
  const classes = [`${styles.sessionCard} p-0`]
  if (className) classes.push(className)

  const headerClasses = [
    `${styles.toggleComponent} d-flex align-items-center`
  ]
  if (headerClass) headerClasses.push(headerClass)
  if (isOpen) headerClasses.push(`${styles.cardTitle}`)

  const hasToggle = !isNil(toggle)

  const toggleComponent = hasToggle ? (
    <TouchableOpacity
      className={headerClasses.join(' ')}
      onClick={toggle}
    >
      {headerComponent}
    </TouchableOpacity>
  ) : (
    <div className={headerClasses.join(' ')}>
      {headerComponent}
    </div>
  )

  return (
    <Card className={classes.join(' ')}>
      {toggleComponent}
      <Collapse in={isOpen}>
        <div className={styles.collapse}>{children}</div>
      </Collapse>
    </Card>
  )
}

CardCollapse.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  headerComponent: PropTypes.node
}

export default CardCollapse
