import React from 'react'
import PropTypes from 'prop-types'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { Routes } from 'global/routes'

import Anchor from 'components/Anchor'
import IconToggler from 'components/IconToggler'

import styles from './styles.module.scss'

const { DASHBOARD } = Routes

const NavLink = ({ icon, title, to }) => {
  const classes = [
    `${styles.navLink}`,
    'd-block text-white px-25 py-12'
  ]
  const { pathname } = useLocation()

  const isActive = includes(pathname, to)
  if (isActive) classes.push(`${styles.active}`)

  const checkedHref = to === DASHBOARD ? `${DASHBOARD}/plp` : to

  return (
    <Anchor href={checkedHref} className={classes.join(' ')}>
      <div className="d-flex flex-column text-center text-lg-left flex-lg-row">
        <div
          className={`${styles.iconWrapper} mb-10 mb-lg-0 mr-lg-15`}
        >
          <IconToggler icon={icon} active={isActive} />
        </div>
        <div>{title}</div>
      </div>
    </Anchor>
  )
}

NavLink.propTypes = {
  icon: PropTypes.shape({
    active: PropTypes.object,
    inactive: PropTypes.object
  }).isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

const LeftNav = ({ links }) => {
  return (
    <Col
      xs={2}
      className={`${styles.leftMenu} d-none d-md-block bg-primary pt-15 px-0`}
    >
      {links.map(link => (
        <NavLink
          key={link.to}
          to={link.to}
          title={link.title}
          icon={link?.icon}
        />
      ))}
    </Col>
  )
}

LeftNav.propTypes = {
  links: PropTypes.array.isRequired
}

export default LeftNav
