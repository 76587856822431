import { getRequest, postRequest } from 'utils/http'

export const getUserByIdApi = id => {
  return getRequest(`/users/${id}`)
}

export const getStakeholderSurveys = id => {
  return getRequest(`/stakeholders/mini-surveys/${id}`)
}

export const addUserOkrApi = ({ userId, payload }) => {
  return postRequest(`/okrs/user/${userId}`, payload)
}
