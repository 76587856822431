import { useQuery } from 'react-query'

import { getCompaniesApi } from './api'
import { GET_COMPANIES } from './constants'

export const useCompany = () => {
  const { data: companies } = useQuery(
    GET_COMPANIES,
    getCompaniesApi
  )

  return { companies }
}
