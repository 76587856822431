import { getRequest, putRequest } from 'utils/http'

export const getUserWorkshopApi = () => {
  return getRequest('/user-workshops')
}

export const getCoachUserWorkshopApi = userId => {
  return getRequest(`/user-workshops/${userId}`)
}

export const editUserWorkshopApi = ({
  userId,
  workshopId,
  payload
}) => {
  if (userId) {
    return putRequest(
      `/user-workshops/workshop/${workshopId}/${userId}`,
      payload
    )
  }

  return putRequest(
    `/user-workshops/workshop/${workshopId}`,
    payload
  )
}
