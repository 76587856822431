import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import FormCheck from 'components/Form/FormCheck'
import Modal from 'components/Modal'
import TouchableOpacity from 'components/TouchableOpacity'
import Upload from 'components/Upload'
import { cleanPayload } from 'utils/helpers/payload'
import { resourceIsDocs, resourceIsLink } from '../typeValidator'
import { schema } from '../schema'
import InputWithDropdown from './InputWithDropdown'
import styles from './styles.module.scss'

const initErrors = {
  file: false,
  category: false
}

const ResourceModal = ({
  show,
  toggleModal,
  createResource,
  resourceCategories,
  createCategory
}) => {
  const categoryInputRef = useRef(null)
  const addCategoryRef = useRef(null)
  const uploadDataRef = useRef(null)
  const [type, setType] = useState('Link')
  const [categoryValue, setCategoryValue] = useState('')
  const [categoryError, setCategoryError] = useState(null)
  const [uploadedData, setUploadedData] = useState(null)
  const [stateErrors, setStateErrors] = useState(initErrors)
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema)
  })

  const setSingleError = (key, value) => {
    setStateErrors(currState => ({
      ...currState,
      [key]: value
    }))
  }

  const resetForm = useCallback(() => {
    reset({
      title: '',
      url: '',
      source: '',
      sourceUrl: ''
    })
  }, [reset])

  const resetState = () => {
    setStateErrors(initErrors)
    setUploadedData(null)
    setCategoryValue('')
  }

  useEffect(() => {
    resetForm()
    resetState()
  }, [resetForm, type, show])

  const categoryOptions = resourceCategories?.map(
    category => category.name
  )

  const onFileUpload = e => {
    if (e.target.files.length) {
      setSingleError('file', false)
      return setUploadedData(e.target.files[0])
    }

    return setUploadedData(null)
  }

  const formSubmit = data => {
    if (resourceIsDocs(data.type) && !uploadedData) {
      setSingleError('file', true)
      return
    }

    const selectedCategory = resourceCategories.find(
      x => x.name === categoryInputRef.current?.value
    )

    if (!selectedCategory) {
      setSingleError('category', true)
      return
    }

    const payload = {
      ...data,
      category: selectedCategory?.id,
      type: type.toLowerCase()
    }

    createResource({
      ...cleanPayload(payload),
      file: uploadedData
    })

    toggleModal()
  }

  const handleAddCategory = () => {
    if (categoryValue.length) {
      createCategory({ name: categoryValue })
      setCategoryError(null)
    } else {
      setCategoryError({ message: 'Category is empty' })
    }
  }

  const categoryOnChange = e => {
    setCategoryValue(e.target.value)
    if (e.target.value.length) {
      return setCategoryError(null)
    }

    return setCategoryError({ message: 'Category is empty' })
  }

  return (
    <Modal show={show} closeModal={toggleModal}>
      <fieldset>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <p className={styles.modalTitle}>Add Content</p>
                <p className="text-gray-dark mb-10">
                  Choose content below to recommend to a client.
                </p>
              </Col>
            </div>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <FormControl
                  placeholder="Content Title"
                  formGroupClass="mb-8"
                  name="title"
                  as="textarea"
                  rows={1}
                  inputRef={register}
                  error={errors.title}
                />
              </Col>
            </div>
            <div className="d-flex align-items-center mb-10">
              <Col xs={12}>
                <InputWithDropdown
                  inputRef={categoryInputRef}
                  placeholder="Select Category"
                  options={categoryOptions}
                  hasError={stateErrors.category}
                >
                  <div className="d-flex px-20 py-15 align-items-center">
                    <TouchableOpacity
                      className="text-secondary mr-10"
                      onClick={handleAddCategory}
                    >
                      + Category
                    </TouchableOpacity>
                    <FormControl
                      inputRef={addCategoryRef}
                      error={categoryError}
                      name="addCategory"
                      formGroupClass="mb-0"
                      className={styles.categoryInput}
                      onChange={categoryOnChange}
                      value={categoryValue}
                    />
                  </div>
                </InputWithDropdown>
              </Col>
            </div>
            <Col lg={12}>
              <hr />
            </Col>
            <div className="d-flex align-items-center mb-10 ">
              <Col lg={4}>
                <h6>Content Type: </h6>
              </Col>
              {['Link', 'Document'].map((val, i) => {
                return (
                  <Col lg="2" key={i}>
                    <FormCheck
                      inputRef={register}
                      error={errors.type}
                      custom
                      controlId={val}
                      type="radio"
                      name="type"
                      checked={val === type}
                      onChange={e => setType(e.target.id)}
                      id={val}
                      label={val}
                      value={type}
                    />
                  </Col>
                )
              })}
            </div>
            {resourceIsLink(type) && (
              <>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Paste URL here"
                      formGroupClass="mb-8"
                      name="url"
                      type="url"
                      inputRef={register}
                      error={errors.url}
                    />
                  </Col>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Source name"
                      formGroupClass="mb-8"
                      name="source"
                      type="url"
                      inputRef={register}
                      error={errors.source}
                    />
                  </Col>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Description"
                      formGroupClass="mb-8"
                      as="textarea"
                      rows={3}
                      name="description"
                      inputRef={register}
                      error={errors.description}
                    />
                  </Col>
                </div>
              </>
            )}

            {resourceIsDocs(type) && (
              <>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Source URL"
                      formGroupClass="mb-8"
                      name="sourceUrl"
                      type="url"
                      inputRef={register}
                      error={errors.sourceUrl}
                    />
                  </Col>
                </div>
                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Source name"
                      formGroupClass="mb-8"
                      name="source"
                      type="url"
                      inputRef={register}
                      error={errors.source}
                    />
                  </Col>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <Col xs={12}>
                    <FormControl
                      placeholder="Description"
                      formGroupClass="mb-8"
                      as="textarea"
                      rows={3}
                      name="description"
                      inputRef={register}
                      error={errors.description}
                    />
                  </Col>
                </div>

                <div className="align-items-center mb-10">
                  <Col xs={12}>
                    <Upload
                      fileRestriction="application/pdf"
                      inputRef={uploadDataRef}
                      onFileChange={onFileUpload}
                    />
                    <Button
                      className={styles.seeMoreBtn}
                      variant="outline-secondary"
                      id="document"
                      onClick={() =>
                        uploadDataRef?.current.click()
                      }
                    >
                      Upload File
                    </Button>
                    <label className={styles.label}>
                      Must not exceed 5 MB
                    </label>
                  </Col>
                  {stateErrors.file && (
                    <Col xs={12} className="text-danger">
                      <small>Document is required</small>
                    </Col>
                  )}

                  {uploadedData && (
                    <Col
                      xs={12}
                      className="mt-10 text-gray-dark"
                    >
                      File: {uploadedData.name}
                    </Col>
                  )}
                </div>
              </>
            )}

            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="link"
                smallPadding
                onClick={toggleModal}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                smallPadding
                onClick={handleSubmit(formSubmit)}
              >
                Add Content
              </Button>
            </div>
          </Col>
        </Row>
      </fieldset>
    </Modal>
  )
}

export default ResourceModal
