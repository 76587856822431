import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

const IconToggler = ({ icon, active }) => {
  if (isEmpty(icon)) return null
  return active ? <icon.active /> : <icon.inactive />
}

IconToggler.propTypes = {
  icon: PropTypes.shape({
    inactive: PropTypes.object,
    active: PropTypes.object
  })
}

export default IconToggler
