import { getRequest, postRequest, putRequest } from 'utils/http'

export const getUserPersonalityApi = id => {
  return getRequest(`/personality/user/${id}`)
}

export const updatePersonalityPdfApi = async ({
  userId,
  filePayload,
  editPayload,
  isDelete
}) => {
  if (!isDelete) {
    const uploadedFile = await postRequest(
      `/personality/${userId}/file`,
      filePayload
    )

    return putRequest(`/personality/user/${userId}`, {
      ...editPayload,
      file: uploadedFile.filepath
    })
  }

  return putRequest(`/personality/user/${userId}`, {
    ...editPayload,
    file: null
  })
}

export const updatePersonalityApi = ({ userId, payload }) => {
  return putRequest(`/personality/user/${userId}`, payload)
}
