export const GET_360 = 'pages/member/dashboard/360/get360'

export const DEFAULT_STEPS = [
  {
    id: 1,
    step: 'Complete the 360 survey',
    status: false
  },
  {
    id: 2,
    step:
      'Take part in a coaching session to review your profile',
    status: false
  },
  {
    id: 3,
    step:
      'Review 360 on your own and note potential development themes',
    status: false
  }
]
