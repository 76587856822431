import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import { head } from 'lodash'

import tblStyles from 'global/table.module.scss'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'
import { getFileLink } from 'utils/helpers/file'
import { dateToRelativeTime } from 'utils/helpers/date'

import DashboardWrapper from 'components/DashboardWrapper'
import Table from 'components/Table'
import Filters from 'components/Filters'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'

import styles from './styles.module.scss'
import { useActivityFeed } from './hooks'

const filterOptions = [
  {
    value: '',
    label: 'All Activities'
  },
  {
    value: 'coachingSessions',
    label: 'Coaching Sessions'
  },
  {
    value: 'monthlyCheckIns',
    label: 'Monthly Check-ins'
  },
  {
    value: 'developmentPlans',
    label: 'Development Plan Added'
  }
]

const ActivityFeed = () => {
  useHeaderTitle('Activity Feed')
  const [filter, setFilter] = useState('')
  const { activityFeedData } = useActivityFeed()
  const transformedData = activityFeedData?.map(activity => ({
    name: `${activity.User.firstName} ${activity.User.lastName}`,
    thumb: activity.User.profileImage
      ? getFileLink(activity.User.profileImage)
      : null,
    company: activity.User.Company.name,
    recentActivity: activity.activity,
    date: dateToRelativeTime({
      date: activity.createdAt
    }),
    type: activity.activityFrom
  }))

  const filteredData = transformedData?.filter(
    data => data.type === filter
  )

  const tableData = filter ? filteredData : transformedData

  return (
    <DashboardWrapper>
      <div>
        <div className="mb-20">
          <Filters
            filters={[
              {
                name: 'activityFilter',
                options: filterOptions,
                width: 200
              }
            ]}
            onFilterChange={(name, value) => setFilter(value)}
          />
        </div>
        <Table className={styles.customTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Recent Activity</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, index) => {
              const initial = head(item.name)

              return (
                <tr key={index}>
                  <td className="d-flex align-items-center">
                    <div
                      className={`${tblStyles.imageWrapper} mr-15`}
                    >
                      {item.thumb ? (
                        <Image src={item.thumb} roundedCircle />
                      ) : (
                        <ThumbnailPlaceholder
                          initial={initial}
                        />
                      )}
                    </div>
                    <span>{item.name}</span>
                  </td>
                  <td>{item.company}</td>
                  <td>{item.recentActivity}</td>
                  <td>{item.date}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </DashboardWrapper>
  )
}

ActivityFeed.propTypes = {}

export default ActivityFeed
