import { useMutation } from 'react-query'

import { signup, verifyToken } from './api'

export const useSignupToken = () => {
  const {
    data,
    mutate,
    isLoading,
    isSuccess,
    error
  } = useMutation(verifyToken)

  return {
    verifyToken: mutate,
    verifiedData: data,
    isLoading,
    isSuccess,
    error
  }
}

export const useSignup = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    signup
  )

  return {
    signup: mutate,
    isLoading,
    isSuccess,
    error
  }
}
