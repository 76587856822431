import React, { useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

import { Routes } from 'global/routes'
import { GlobalProvider } from 'global/Context'

import { CommonRoutes } from 'utils/helpers/commonRoutes'

import { DASHBOARD_BASE_URLS } from 'pages/Member/constants'
import LeftSideBar from 'components/DashboardWrapper/LeftNav'
import Header from 'components/DashboardWrapper/Header'

import ActivityFeed from './ActivityFeed'
import UserManagement from './UserManagement'
import Workshops from './Workshops'
import ResourceLibrary from './ResourceLibrary'
import Settings from './Settings'
import styles from './styles.module.scss'
import menuLinks from './menuLinks'

const {
  COACH_ACTIVITY_FEED,
  COACH_USER_MANAGEMENT,
  COACH_WORKSHOPS,
  COACH_RESOURCE_LIBRARY,
  COACH_SETTINGS
} = Routes

const SwitchRoutes = () => (
  <Switch>
    <Route path={COACH_ACTIVITY_FEED} component={ActivityFeed} />
    <Route
      path={COACH_USER_MANAGEMENT}
      component={UserManagement}
    />
    <Route exact path={COACH_WORKSHOPS} component={Workshops} />
    <Route
      exact
      path={COACH_RESOURCE_LIBRARY}
      component={ResourceLibrary}
    />
    <Route exact path={COACH_SETTINGS} component={Settings} />
    <CommonRoutes />
  </Switch>
)

const Coach = () => {
  const { pathname } = useLocation()
  const [headerTitle, setHeaderTitle] = useState('')

  if (DASHBOARD_BASE_URLS.includes(pathname)) {
    return <Redirect to={COACH_ACTIVITY_FEED} />
  }

  return (
    <GlobalProvider value={{ setHeaderTitle }}>
      <Container className={styles.container}>
        <Header title={headerTitle} />
        <Row className={styles.contentWrapper}>
          <LeftSideBar links={menuLinks} />
          <Col md={10} xs={12} className="bg-gray p-0">
            <SwitchRoutes />
          </Col>
        </Row>
      </Container>
    </GlobalProvider>
  )
}

Coach.propTypes = {}

export default Coach
