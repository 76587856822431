import * as yup from 'yup'

export const GET_SESSIONS =
  'pages/member/coachingSessions/getSessions'
export const GET_COACHING_DOCUMENTS =
  'pages/member/coachingSessions/getCoachingDocuments'

export const coachingSessionSchema = yup.object().shape({
  title: yup
    .string()
    .required('This field is required')
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  agenda: yup
    .string()
    .required('This field is required')
    .max(
      300,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  step: yup
    .string()
    .required('This field is required')
    .max(
      300,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  // notes: yup
  //   .string()
  //   .required('This field is required')
  //   .min(10, 'Minimum 10 characters required')
  //   .max(
  //     2000,
  //     'Entry is above character maximum, please reduce the length before saving'
  //   ),
  coachNotes: yup
    .string()
    .max(
      2000,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  date: yup.string().required(`This field is required`)
})
