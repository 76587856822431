/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'

function useMutationError({
  error,
  callback,
  position = 'bottom-right'
}) {
  const [show, setShow] = useState(false)

  function toggle() {
    setShow(false)
    callback()
  }

  function ToastComponent({ message }) {
    return (
      <Toast
        show={show}
        toggle={toggle}
        variant="danger"
        position={position}
        title="Error"
        titleClass="text-white font-weight-bold"
        delay={3000}
        autohide
      >
        <div className="text-white">{message}</div>
      </Toast>
    )
  }

  useEffect(() => {
    if (error) setShow(true)
  }, [error])

  return [ToastComponent]
}

export default useMutationError
