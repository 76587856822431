import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { isNaN } from 'lodash'

import FormControl from 'components/Form/FormControl'

import styles from './styles.module.scss'

const RateBox = ({ editStepRate, stepId, value }) => {
  const { register, handleSubmit, setValue } = useForm()
  const [error, setError] = useState(false)

  const submitRate = data => {
    const hasRate = !!data.rate
    const isNumber = !isNaN(Number(data.rate))
    if (hasRate) {
      if (isNumber) {
        if (Number(data.rate) > 10 || Number(data.rate) < 1) {
          setError(true)
        } else {
          const formData = {
            stepId,
            rate: data.rate
          }
          editStepRate(formData)
          setError(false)
        }
      } else {
        setValue('rate', null)
      }
    } else {
      setError(true)
    }
  }

  return (
    <FormControl
      name="rate"
      type="number"
      error={error}
      inputRef={register}
      className={styles.rateBox}
      formGroupClass="mb-0"
      defaultValue={value}
      onBlur={handleSubmit(submitRate)}
    />
  )
}

RateBox.propTypes = {
  editStepRate: PropTypes.func,
  stepId: PropTypes.number,
  value: PropTypes.number
}

export default RateBox
