import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const H2 = ({ children, className, ...rest }) => {
  return (
    <h2 className={`${styles.h2} ${className || ''}`} {...rest}>
      {children}
    </h2>
  )
}

H2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default H2
