import React from 'react'

import Button from 'components/Button'
import Modal from 'components/Modal'

import styles from './styles.module.scss'

const ConfirmModal = ({
  show,
  icon,
  toggleModal,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  title = 'Are you sure?',
  onConfirm,
  description = ''
}) => {
  return (
    <Modal show={show} closeModal={toggleModal}>
      <div className={styles.modalWrapper}>
        {icon}
        <p className={styles.title}>{title}</p>
        {description && (
          <p className={styles.desc}>{description}</p>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Button
          variant="outline-secondary mr-20"
          smallPadding
          onClick={toggleModal}
        >
          {cancelText}
        </Button>
        <Button
          variant="secondary"
          smallPadding
          type="submit"
          onClick={() => {
            onConfirm()
            toggleModal()
          }}
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmModal
