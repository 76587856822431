import React from 'react'
import * as yup from 'yup'

import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import styles from 'global/modal.module.scss'

import Button from 'components/Button'
import Modal from 'components/Modal'
import FormControl from 'components/Form/FormControl'
import { STANDARD_URL } from 'utils/regex/patterns'

const addLinkFormSchema = yup.object().shape({
  title: yup
    .string()
    .required(`Please enter url title`)
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  url: yup
    .string()
    .matches(STANDARD_URL, {
      message: 'Please enter a valid URL'
    })
    .required('Please enter a URL')
})

const AddLink = ({ show, closeModal, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addLinkFormSchema)
  })

  const formSubmit = payload => {
    // eslint-disable-next-line no-console
    onSubmit(payload)
    closeModal()
  }

  return (
    <>
      <Modal
        mimicNative
        bodyClassName={styles.modal}
        show={show}
        title="Add Link"
        closeModal={closeModal}
      >
        <p className={`${styles.title} d-none d-sm-block`}>
          Add Link
        </p>
        <p className="text-gray-dark mb-20">
          Include a link that you&apos;d like to have easy access
          to related your coaching sessions.
        </p>
        <Form
          onSubmit={handleSubmit(formSubmit)}
          className="mb-40"
        >
          <FormControl
            inputRef={register}
            type="string"
            name="title"
            as="textarea"
            rows={2}
            placeholder="Link Title"
            error={errors.title}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="url"
            placeholder="Paste URL here"
            error={errors.url}
          />
        </Form>
        <div className="d-flex justify-content-end mb-20">
          <Button
            className="d-none d-sm-block"
            variant="url"
            smallPadding
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="form__button--submit"
            variant="secondary"
            smallPadding
            onClick={handleSubmit(formSubmit)}
          >
            Add Link
          </Button>
        </div>
      </Modal>
    </>
  )
}

AddLink.propTypes = {}

export default AddLink
