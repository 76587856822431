import { useQuery } from 'react-query'

import { getProgressCheck } from './api'
import { GET_STAKEHOLDER_PROGRESS } from './constants'

export const useProgressCheckin = () => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch
  } = useQuery(GET_STAKEHOLDER_PROGRESS, getProgressCheck)

  return {
    data,
    isLoading,
    isSuccess,
    error,
    refetch
  }
}
