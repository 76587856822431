import * as yup from 'yup'

import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'

import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import Modal from 'components/Modal'
import ConfirmModal from 'components/ConfirmModal'

import styles from '../styles.module.scss'

import DesktopTable from './DesktopTable'
import MobileTable from './MobileTable'

const okrFormSchema = yup.object().shape({
  title: yup
    .string()
    .required(`This field is required`)
    .max(
      150,
      'Entry is above character maximum, please reduce the length before saving'
    ),
  url: yup.string().required(`This field is reuired`)
})

const OKRTable = ({
  data,
  isLoading,
  handleDeleteOkr,
  handleEditOkr
}) => {
  const { isAtLeastMediumScreen } = useDeviceSize()
  const [editModal, setEditModal] = useState({
    show: false,
    id: null,
    title: '',
    url: ''
  })
  const [showDelete, setShowDelete] = useState(false)
  const [selectedId, setSelectedId] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(okrFormSchema)
  })

  const closeModal = () => {
    setEditModal({ ...editModal, show: false })
  }

  const editLink = data => {
    setEditModal({
      show: true,
      ...data
    })
  }

  const toggleModal = setter => {
    return () => setter(show => !show)
  }

  const saveLink = async data => {
    await handleEditOkr(editModal?.id, data)
    closeModal()
  }

  const deleteLink = id => {
    setShowDelete(true)
    setSelectedId(id)
  }

  const deleteHandler = () => {
    handleDeleteOkr(selectedId)
  }

  return (
    <>
      <Modal
        mimicNative
        title="Edit Link"
        show={editModal.show}
        closeModal={closeModal}
      >
        <p className={`${styles.modalTitle} d-none d-sm-block`}>
          Edit Link
        </p>
        <p className="text-gray-dark mb-20">
          Include a link to your company or individual objectives
          and key results.
        </p>
        <Form>
          <FormControl
            inputRef={register}
            type="string"
            name="title"
            as="textarea"
            rows={2}
            placeholder="Title"
            error={errors.title}
            defaultValue={editModal.title}
          />
          <FormControl
            inputRef={register}
            type="string"
            name="url"
            placeholder="URL"
            error={errors.url}
            defaultValue={editModal.url}
          />
        </Form>
        <div className="d-flex justify-content-end mb-20">
          <Button
            className="d-none d-sm-block"
            variant="link"
            smallPadding
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="form__button--submit"
            variant="secondary"
            smallPadding
            onClick={handleSubmit(saveLink)}
          >
            Save
          </Button>
        </div>
      </Modal>
      {isAtLeastMediumScreen ? (
        <DesktopTable
          data={data}
          isLoading={isLoading}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ) : (
        <MobileTable
          data={data}
          isLoading={isLoading}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      )}
      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDelete}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={toggleModal(setShowDelete)}
        title="Are you sure you want to delete this link?"
        // description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. "
        onConfirm={deleteHandler}
      />
    </>
  )
}

export default OKRTable
