import React from 'react'
import { Overlay, Popover } from 'react-bootstrap'

import TouchableOpacity from 'components/TouchableOpacity'

const ProfileMenu = ({ menu, show, target, container }) => {
  return (
    <Overlay
      show={show}
      target={target}
      container={container}
      placement="bottom"
    >
      <Popover>
        <Popover.Content className="p-20">
          {menu.map((setting, index) => {
            const lastItem = index === menu.length - 1
            return (
              <TouchableOpacity
                key={setting.title}
                onClick={setting.click}
                className={`d-flex ${!lastItem ? 'mb-15' : ''}`}
              >
                {setting.icon}
                <div className="ml-10">{setting.title}</div>
              </TouchableOpacity>
            )
          })}
        </Popover.Content>
      </Popover>
    </Overlay>
  )
}

export default ProfileMenu
