import { useMutation, useQuery } from 'react-query'

import { queryClient } from 'store/state'

import { GET_SIDEBAR_CONTENT } from '../useRightSidebar/constants'

import {
  editUserWorkshopApi,
  getCoachUserWorkshopApi,
  getUserWorkshopApi
} from './api'
import { GET_USER_WORKSHOP } from './constants'

export const useUserWorkshop = (userId = null) => {
  const { data: workshopData } = useQuery(
    GET_USER_WORKSHOP,
    userId
      ? () => getCoachUserWorkshopApi(userId)
      : getUserWorkshopApi
  )

  const { mutate: editUserWorkshop } = useMutation(
    editUserWorkshopApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_WORKSHOP)
        queryClient.invalidateQueries(GET_SIDEBAR_CONTENT)
      }
    }
  )

  return {
    workshopData,
    editUserWorkshop
  }
}
