import React, { useRef } from 'react'
import { Image } from 'react-bootstrap'
import { head } from 'lodash'

import {
  COACH_BASE_URL,
  getCoachCourseComponents,
  getUserManagementCourseComponentLink
} from 'utils/helpers/course-component'

import Autocomplete from 'components/Form/Autocomplete'
import Button from 'components/Button'
import Modal from 'components/Modal'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'

import CourseNav from '../SingleUser/CourseNav'

import styles from './styles.module.scss'

const EditResource = ({
  companies,
  show,
  toggleModal,
  currUser,
  onSubmit
}) => {
  const companySelectRef = useRef(null)
  const companySelectOptions = companies?.map(
    company => company.name
  )
  if (!currUser) return null

  const {
    id,
    thumb,
    firstName,
    lastName,
    email,
    Company,
    stage
  } = currUser
  const name = `${firstName} ${lastName}`
  const hasThumb = !!thumb
  const initial = head(name)

  const handleSubmit = () => {
    const payload = {
      companyName: companySelectRef.current?.value
    }

    onSubmit(payload)
    toggleModal()
  }

  const customPathName = getUserManagementCourseComponentLink(
    stage,
    id
  )

  const courseComponents = getCoachCourseComponents(id)

  return (
    <Modal show={show} closeModal={toggleModal}>
      <fieldset>
        <p className={styles.modalTitle}>Edit User</p>
        <div
          className={`${styles.modalUserSummary} d-flex align-items-center mb-20`}
        >
          {hasThumb ? (
            <Image roundedCircle src={thumb} />
          ) : (
            <ThumbnailPlaceholder initial={initial} />
          )}
          <div className="d-flex flex-column ml-15">
            <p className="m-0">{name}</p>
            <p className="m-0">{email}</p>
          </div>
        </div>

        <Autocomplete
          inputRef={companySelectRef}
          placeholder="Select Company"
          options={companySelectOptions}
          defaultValue={Company.name}
        />
        <div className={styles.courseNavWrapper}>
          <CourseNav
            disableChangeRoute
            baseUrl={`${COACH_BASE_URL}/`}
            courseComponents={courseComponents}
            currStage={stage}
            customPathName={customPathName}
            customUserId={id}
          />
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <Button
            variant="link"
            smallPadding
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            smallPadding
            type="submit"
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </div>
      </fieldset>
    </Modal>
  )
}

export default EditResource
