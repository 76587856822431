import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { head, includes } from 'lodash'
import { useHistory } from 'react-router-dom'

import { COACH_BASE_URL } from 'utils/helpers/course-component'
import { getFileLink } from 'utils/helpers/file'
import { getLocationPathname } from 'utils/html/window'

import Modal from 'components/Modal'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'
import TouchableOpacity from 'components/TouchableOpacity'

import styles from './styles.module.scss'

const ChangeClientModal = ({
  show,
  closeModal,
  currentUserId,
  clients
}) => {
  const clientsWithoutCurrentUser = clients?.filter(
    client => Number(client.id) !== Number(currentUserId)
  )
  const history = useHistory()
  const pathname = getLocationPathname()
  const pathnameSplit = pathname.split('/')
  let courseStage = pathnameSplit[pathnameSplit.length - 1]

  const innerCourses = ['monthly-checkins', 'progress-check']

  if (includes(innerCourses, courseStage)) {
    courseStage = `stakeholder-engagement/${courseStage}`
  }

  const changeClient = userId => {
    history.push(`${COACH_BASE_URL}/${userId}/${courseStage}`)
  }
  return (
    <Modal show={show} closeModal={closeModal}>
      <Row>
        <Col xs={12}>
          <div className="d-flex align-items-center mb-20">
            <div>
              <p className={styles.modalTitle}>Change Client</p>
              <p className="text-gray-dark">
                Sed ut perspiciatis unde omnis iste natus error
                sit voluptatem
              </p>
            </div>
          </div>
          <div>
            {clientsWithoutCurrentUser &&
              !clientsWithoutCurrentUser.length && (
                <div>No Available Users</div>
              )}
            {clientsWithoutCurrentUser?.map(client => {
              const {
                id,
                firstName,
                lastName,
                profileImage
              } = client
              const clientName = `${firstName} ${lastName}`
              const initial = head(clientName)
              return (
                <TouchableOpacity
                  key={`client-${id}`}
                  onClick={() => changeClient(id)}
                  className="d-flex align-items-center mb-10"
                >
                  {profileImage ? (
                    <Image
                      roundedCircle
                      src={getFileLink(profileImage)}
                      className={styles.clientImage}
                    />
                  ) : (
                    <ThumbnailPlaceholder initial={initial} />
                  )}
                  <span className="mx-10">{clientName}</span>
                </TouchableOpacity>
              )
            })}
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ChangeClientModal
