import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'store/state'

import {
  createOkr as createOkrAPI,
  editOkr as editOkrAPI,
  deleteOkr as deleteOkrAPI,
  getOkrs
} from './api'
import { GET_OKRS } from './constants'

export const useOkr = () => {
  const { data, isLoading, isSuccess, error } = useQuery(
    GET_OKRS,
    getOkrs
  )

  const { mutate: createOkr } = useMutation(createOkrAPI, {
    onSuccess: () => queryClient.invalidateQueries(GET_OKRS)
  })

  const { mutate: editOkr } = useMutation(editOkrAPI, {
    onSuccess: () => queryClient.invalidateQueries(GET_OKRS)
  })

  const { mutate: deleteOkr } = useMutation(deleteOkrAPI, {
    onSuccess: () => queryClient.invalidateQueries(GET_OKRS)
  })

  return {
    data,
    isLoading,
    isSuccess,
    error,

    createOkr,
    editOkr,
    deleteOkr
  }
}
