import { createContext, useContext } from 'react'

export const GET_STAKEHOLDER_ENGAGEMENT =
  'pages/coach/userManagement/singleUser/stakeholderEngagement/getStakeholderEngagement'

export const BASE_URL = '/coach/user-management/clients'

export const defaultStakeholderContextValue = {
  data: {
    progressCheckData: null
  },
  setters: {
    setProgressCheckData: () => null
  }
}

export const StakeholderContext = createContext(
  defaultStakeholderContextValue
)

export const useStakeholderContext = () => {
  const { data, setters } = useContext(StakeholderContext)

  return {
    ...data,
    ...setters
  }
}
