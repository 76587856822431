import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Spinner } from 'react-bootstrap'

import Container from 'components/Container'

const PageLoader = ({ loader: isLoading, children }) => {
  return isLoading ? (
    <Container style={{ height: '100%' }}>
      <Row style={{ height: '100%' }}>
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner animation="grow" variant="primary" />
        </Col>
      </Row>
    </Container>
  ) : (
    children
  )
}

PageLoader.propTypes = {
  loader: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default PageLoader
