import React, { useEffect, useState } from 'react'
import { includes, toLower } from 'lodash'

import { ReactComponent as ContextMenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'
import { ReactComponent as IconSearch } from 'assets/icons/search.svg'

import { useUserManagement } from 'global/hooks/useUserManagement'

import { formatDate } from 'utils/helpers/date'
import { getFileLink } from 'utils/helpers/file'
import { openLink } from 'utils/html/window'

import FormControl from 'components/Form/FormControl'
import Table from 'components/Table'
import Dropdown from 'components/Dropdown'
import TouchableOpacity from 'components/TouchableOpacity'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { NameWithThumb } from 'components/TableRenderers'

import { Spinner } from 'react-bootstrap'
import { useResourceLibrary } from '../hooks'

import ResourceModal from './ResourceModal'
import EditResourceModal from './EditResourceModal'
import styles from './styles.module.scss'
import RecommendModal from './RecommendModal'

const RLTable = () => {
  const [currResource, setCurrResource] = useState(null)
  const [showResourceModal, setResourceModal] = useState(false)
  const [
    showRecommendationModal,
    setRecommendationModal
  ] = useState(false)

  const [
    showEditResourceModal,
    setShowEditResourceModal
  ] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [filterTitle, setFilterTitle] = useState('')

  const toggleModal = setter => {
    return () => setter(show => !show)
  }
  const {
    getAllResources,
    resourceLibraryData,
    resourceLibraryLoading,
    createResource,
    editResource,
    deleteResource,
    recommendResource,
    resourceCategories,
    getAllResourceCategories,
    createCategory
  } = useResourceLibrary()

  const { allClients } = useUserManagement()

  const filteredClients = allClients?.filter(
    client => client.signedUp
  )

  const filteredResources = filterTitle.length
    ? resourceLibraryData.filter(x =>
        includes(toLower(x.title), toLower(filterTitle))
      )
    : resourceLibraryData

  useEffect(() => {
    getAllResources()
    getAllResourceCategories()
  }, [getAllResources, getAllResourceCategories])

  const handleDelete = () => {
    deleteResource(currResource.id)
  }
  const handleRecommend = data => {
    const clients = data.map(x => x.value)

    recommendResource({
      resourceId: currResource.id,
      payload: {
        clients
      }
    })
  }

  const contextMenu = [
    {
      component: (
        <TouchableOpacity
          onClick={toggleModal(setShowEditResourceModal)}
        >
          Edit
        </TouchableOpacity>
      )
    },
    {
      component: (
        <TouchableOpacity
          onClick={toggleModal(setRecommendationModal)}
        >
          {currResource?.recommended
            ? 'Unrecommend'
            : 'Recommend'}
        </TouchableOpacity>
      )
    },
    {
      component: (
        <TouchableOpacity onClick={toggleModal(setShowDelete)}>
          Delete Content
        </TouchableOpacity>
      )
    }
  ]

  return (
    <>
      <div>
        <div className="mb-20 d-flex justify-content-between">
          <FormControl
            placeholder="Search"
            onChange={e => setFilterTitle(e.target.value)}
            formGroupClass={`${styles.searchBar} mb-0`}
            icon={<IconSearch />}
          />
          <Button
            variant="secondary"
            onClick={toggleModal(setResourceModal)}
          >
            + Content
          </Button>
        </div>

        <Table className={styles.customTable}>
          <thead>
            <tr>
              <th style={{ minWidth: 250 }}>CONTENT TITLE</th>
              <th>CATEGORY</th>
              <th>TYPE</th>
              <th>URL</th>
              <th style={{ minWidth: 150 }}>DATE ADDED</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {resourceLibraryLoading && (
              <tr className="p-20 d-flex">
                <td>
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </td>
              </tr>
            )}
            {filteredResources?.map(item => {
              const dateAdded = formatDate({
                format: 'MMM DD, YYYY',
                date: item.createdAd
              })
              const itemUrl =
                item.type === 'document'
                  ? getFileLink(item.url)
                  : item.url

              return (
                <tr key={item.id}>
                  <td>
                    <NameWithThumb name={item.title} />
                  </td>
                  <td width="300">
                    {item.ResourceCategory.name}
                  </td>
                  <td className="text-capitalize">
                    {item.type}
                  </td>
                  <td>
                    <TouchableOpacity
                      onClick={() => {
                        openLink({
                          url: itemUrl,
                          isExternalLink: true,
                          newTab: true
                        })
                      }}
                      className={`${styles.resourceLink} link`}
                    >
                      {itemUrl}
                    </TouchableOpacity>
                  </td>
                  <td>{dateAdded}</td>
                  <td className={styles.dropdownTd}>
                    <Dropdown
                      label={
                        <TouchableOpacity
                          onClick={() => setCurrResource(item)}
                        >
                          <ContextMenuIcon width={30} />
                        </TouchableOpacity>
                      }
                      menu={contextMenu}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <ResourceModal
        show={showResourceModal}
        toggleModal={toggleModal(setResourceModal)}
        createResource={createResource}
        resourceCategories={resourceCategories}
        createCategory={createCategory}
      />
      <EditResourceModal
        show={showEditResourceModal}
        toggleModal={toggleModal(setShowEditResourceModal)}
        currResource={currResource}
        editResource={editResource}
        resourceCategories={resourceCategories}
        createCategory={createCategory}
      />
      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDelete}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={toggleModal(setShowDelete)}
        title="Are you sure you want to delete this content?"
        description="This action is irreversible and should be done carefully."
        onConfirm={handleDelete}
      />
      <RecommendModal
        show={showRecommendationModal}
        toggleModal={toggleModal(setRecommendationModal)}
        clients={filteredClients}
        recomendedResources={
          currResource?.UserRecommendedResources
        }
        onConfirm={handleRecommend}
      />
    </>
  )
}

export default RLTable
