import { useMutation } from 'react-query'

import { sendEmail } from './api'

export const useSendEmail = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    sendEmail
  )

  return {
    sendMail: mutate,
    isLoading,
    isSuccess,
    error
  }
}
