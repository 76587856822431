import { includes } from 'lodash'

export const getLocationPathname = () => {
  return window.location.pathname
}

export const getExternalUrl = url => {
  if (includes(url, 'http')) {
    return url
  }
  if (includes(url, 'www.')) {
    return `https://${url}`
  }
  return url.match(/^https?:/) ? url : `//${url}`
}

export const openLink = ({
  url,
  newTab = false,
  isExternalLink = false
}) => {
  const finalUrl = isExternalLink ? getExternalUrl(url) : url
  return window.open(finalUrl, newTab ? '_blank' : '_self')
}
