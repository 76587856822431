import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'

import Card from 'components/Card'

import styles from './styles.module.scss'

import './dropdown.css'

const Autocomplete = ({
  inputRef,
  icon,
  label,
  formGroupClass,
  labelClassName,
  className,
  hasError,
  options,
  defaultValue,
  ...rest
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [inputValue, setInputValue] = useState(
    defaultValue || ''
  )
  const [preventBlur, setPreventBlur] = useState(false)

  const onInputFocus = () => {
    setShowDropdown(true)
  }

  const onInputBlur = () => {
    if (!preventBlur) {
      setShowDropdown(false)
      inputRef?.current.blur()
    }
  }

  const onSelect = value => {
    setInputValue(value)
    setShowDropdown(false)
    inputRef?.current.blur()
  }
  return (
    <Form.Group className={`${formGroupClass} flex-1`}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
        </Form.Label>
      )}
      <div className={styles.formControlWrapper}>
        <div className={styles.iconWrapper}>{icon || null}</div>
        <Form.Control
          ref={inputRef}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          isInvalid={hasError}
          className={`${styles.formControl} ${className || ''} ${
            icon ? styles.withIcon : ''
          }`}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          {...rest}
        />
        <Dropdown
          className="autocomplete"
          show={showDropdown}
          onMouseEnter={() => setPreventBlur(true)}
          onMouseLeave={() => setPreventBlur(false)}
          onSelect={key => onSelect(key)}
        >
          <Card className={styles.dropdownCard}>
            {options?.map(option => (
              <Dropdown.Item
                key={option}
                eventKey={option}
                className={styles.dropdownItem}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Card>
        </Dropdown>
      </div>
    </Form.Group>
  )
}

export default Autocomplete
