/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Image, Row } from 'react-bootstrap'

import ImageProfilePlaceholder from 'assets/images/profile-placeholder.png'
import PeakLogo from 'assets/images/peak-logo-black.png'
import { ReactComponent as PeakLogoOnly } from 'assets/icons/peak-logo.svg'
// import { ReactComponent as IconNotification } from 'assets/icons/notifications.svg'
// import { ReactComponent as IconNotificationEmpty } from 'assets/icons/notifications-empty.svg'
import { ReactComponent as IconDropdown } from 'assets/icons/dropdown.svg'
import { ReactComponent as IconMyProfile } from 'assets/icons/my-profile.svg'
// import { ReactComponent as IconSettings } from 'assets/icons/settings.svg'
import { ReactComponent as IconLogout } from 'assets/icons/logout.svg'
import { ReactComponent as BurgerMenuIcon } from 'assets/icons/burger-menu.svg'

import { MEMBER_ROLE } from 'global/constants'
// import { Routes } from 'global/routes'

import { useLogout } from 'utils/hooks/useLogout'
import { getFileLink } from 'utils/helpers/file'

import TouchableOpacity from 'components/TouchableOpacity'

import { useProfile } from 'pages/Profile/hooks'

import styles from './styles.module.scss'
// import Notification from './Notification'
import ProfileMenu from './ProfileMenu'
import MobileMenu from './MobileMenu'
import { PROFILE_ROUTES } from './constant'

// import { useNotifications } from './query/hooks'

// const { HOME_PAGE } = Routes

const Header = ({ title }) => {
  const { pathname } = useLocation()
  const [logout] = useLogout()
  const accountSettingRef = useRef()
  const notificationRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const { data } = useProfile()
  // const {
  //   refetch,
  //   data: notifData,
  //   editNotifStatus
  // } = useNotifications()

  function toggleMenu() {
    setIsOpen(open => !open)
  }

  useEffect(() => {
    document.body.classList.toggle('overflow--hidden', isOpen)
  }, [isOpen])

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  const [showAccountMenu, setShowAccountMenu] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const routerHistory = useHistory()
  const { role, firstName, lastName } = data || {}
  const profileUrl = data?.profileImage
    ? getFileLink(data?.profileImage)
    : ImageProfilePlaceholder

  // const roleBasedRoutes =
  //   {
  //     [MEMBER_ROLE]: { settings: '/settings' },
  //     [COACH_ROLE]: { settings: '/coach/settings' }
  //   }[role] || HOME_PAGE

  const PROFILE_ADDITIONAL_PROPS = [
    {
      icon: <IconMyProfile />,
      click: () => routerHistory.push('/my-profile')
    },
    // {
    //   icon: <IconSettings />,
    //   click: () => routerHistory.push(roleBasedRoutes.settings)
    // },
    {
      icon: <IconLogout />,
      click: () => {
        logout()
      }
    }
  ]

  const accountSettings = PROFILE_ROUTES.map((route, index) => ({
    ...route,
    ...PROFILE_ADDITIONAL_PROPS[index]
  }))

  const closeAccountDropdown = () => {
    setShowAccountMenu(false)
  }

  useEffect(() => {
    const handleOverlayClick = e => {
      if (!showNotification) return
      if (!notificationRef.current.contains(e.target)) {
        setShowNotification(false)
      }
    }

    document.addEventListener('click', handleOverlayClick)

    return () => {
      document.removeEventListener('click', handleOverlayClick)
    }
  }, [showNotification])

  return (
    <Row
      className={`${styles.header} py-15 px-md-15 align-items-center`}
    >
      <Col
        xs={2}
        className="d-none d-md-flex justify-content-center"
      >
        <Image
          src={PeakLogo}
          onClick={() => routerHistory.push('/')}
          alt="peak-logo"
          style={{
            width: 155,
            maxWidth: '100%',
            objectFit: 'contain',
            cursor: 'pointer'
          }}
        />
      </Col>
      <Col
        xs={8}
        className={`${styles.headerTitle} font-weight-bold pl-20 pl-md-40`}
      >
        <PeakLogoOnly
          width={37}
          className="d-md-none mr-13"
          onClick={() => routerHistory.push('/')}
        />
        {title}
      </Col>
      <Col
        xs={4}
        md={2}
        className="d-flex align-items-center justify-content-end justify-content-md-space-between"
      >
        {role === MEMBER_ROLE && (
          <>
            {/* <TouchableOpacity
              componentRef={notificationRef}
              onClick={() =>
                setShowNotification(!showNotification)
              }
            >
              {notifData?.length ? (
                <IconNotification />
              ) : (
                <IconNotificationEmpty />
              )}
            </TouchableOpacity> */}
            {/* <Notification
              show={showNotification}
              target={notificationRef.current}
              container={notificationRef.current}
              refetch={refetch}
              data={notifData}
              editNotifStatus={editNotifStatus}
            /> */}
          </>
        )}

        <button
          className="d-md-none ml-25 mr-5"
          onClick={toggleMenu}
        >
          <BurgerMenuIcon
            width={20}
            style={{
              // Asset is slightly rotated for some reason
              transform: 'rotate(-1deg)'
            }}
          />
        </button>

        <TouchableOpacity
          componentRef={accountSettingRef}
          onClick={() => setShowAccountMenu(!showAccountMenu)}
          onBlur={closeAccountDropdown}
          className="ml-20 d-none d-md-flex align-items-center"
        >
          <Image
            roundedCircle
            src={profileUrl}
            className={`${styles.profileImage} mr-15`}
          />
          <div className={`${styles.userName} mr-10`}>
            {firstName} {lastName}
          </div>
          <IconDropdown />
          <ProfileMenu
            show={showAccountMenu}
            target={accountSettingRef.current}
            container={accountSettingRef.current}
            menu={accountSettings}
          />
        </TouchableOpacity>
      </Col>
      <MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} />
    </Row>
  )
}

Header.propTypes = {
  title: PropTypes.string
}

export default Header
