import * as yup from 'yup'

export const workshopSchema = yup.object().shape({
  title: yup
    .string()
    .required('This field is required')
    .max(255),
  // notes: yup
  //   .string()
  //   .min(10)
  //   .required('This field is required')
  //   .min(10),
  company: yup.number().required(`This field is required`)
})

export const GET_WORKSHOPS = 'pages/coach/workshop/getWorkshops'

export const GET_COMPANIES = 'pages/coach/workshop/getCompanies'
