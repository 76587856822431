import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

// Session
export const getSessions = id => {
  return getRequest(`/coaching-sessions/user/${id}`)
}

export const createSession = ({ userId, payload }) => {
  return postRequest(
    `/coaching-sessions/user/${userId}`,
    payload
  )
}

export const editSession = ({ userId, id, payload }) => {
  return putRequest(
    `/coaching-sessions/${id}/user/${userId}`,
    payload
  )
}

export const deleteSession = ({ userId, id }) => {
  return deleteRequest(`/coaching-sessions/${id}/user/${userId}`)
}

// Coaching Documents
export const getCoachingDocs = id => {
  return getRequest(`/coaching-documents/user/${id}`)
}

export const createCoachingDoc = ({ userId, payload }) => {
  return postRequest(
    `/coaching-documents/user/${userId}`,
    payload
  )
}

export const editCoachingDoc = ({ userId, id, payload }) => {
  return putRequest(
    `/coaching-documents/${id}/user/${userId}`,
    payload
  )
}

export const deleteCoachingDoc = ({ userId, id }) => {
  return deleteRequest(
    `/coaching-documents/${id}/user/${userId}`
  )
}
