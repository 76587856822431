import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/http'

export const getAllResourcesApi = () => {
  return getRequest('/resources')
}

export const getAllResourceCategoriesApi = () => {
  return getRequest('/resources/categories')
}

export const createCategoryApi = payload => {
  return postRequest('/resources/categories', payload)
}

export const createResourceApi = async payload => {
  const { type } = payload
  const response = await postRequest('/resources', payload)

  if (type === 'link') {
    return response
  }

  const formData = new FormData()

  formData.append('pdfDocument', payload.file)
  return postRequest(`/resources/${response.id}/file`, formData)
}

export const editResourceApi = async ({
  resourceId,
  payload
}) => {
  const { type } = payload
  const response = await putRequest(
    `/resources/${resourceId}`,
    payload
  )

  if (type === 'link') {
    return response
  }

  const formData = new FormData()

  formData.append('pdfDocument', payload.file)
  return postRequest(`/resources/${response.id}/file`, formData)
}

export const deleteResourceApi = id => {
  return deleteRequest(`/resources/${id}`)
}

export const recommendResourceApi = ({
  resourceId,
  payload
}) => {
  return postRequest(
    `/resources/recommend/${resourceId}`,
    payload
  )
}
