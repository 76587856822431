import { getRequest, postRequest, putRequest } from 'utils/http'

export const getProfile = () => {
  return getRequest(`/users/profile`)
}

export const editProfile = async ({
  payload,
  changeProfileImage,
  newProfileImage
}) => {
  if (changeProfileImage) {
    const formData = new FormData()
    formData.append('profileImage', newProfileImage)
    await postRequest(`/users/profile-image`, formData)
  }
  return putRequest(`/users/profile`, payload)
}

export const uploadPictureApi = payload => {
  return postRequest(`/users/profile-image`, payload)
}
