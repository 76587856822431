import { useQuery } from 'react-query'

import { getAllResourcesApi } from './api'
import { GET_RESOURCES } from './constant'

export const useResourceLibrary = () => {
  const {
    data: resourceLibrary,
    isLoading: resourceLibraryLoading,
    isSuccess: resourceLibrarySuccess,
    error: resourceLibraryError,
    refetch: getResourceLibrary
  } = useQuery(GET_RESOURCES, getAllResourcesApi)

  return {
    resourceLibrary,
    resourceLibraryLoading,
    resourceLibrarySuccess,
    resourceLibraryError,
    getResourceLibrary
  }
}
