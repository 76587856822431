import {
  RFC2822StandardEmail,
  URL_WITH_PROTOCOL
} from './patterns'

export const isValidEmail = email => {
  const regEx = RFC2822StandardEmail
  return regEx.test(email)
}

export const isValidUrlWithProtocol = url => {
  const regEx = URL_WITH_PROTOCOL
  return regEx.test(url)
}
