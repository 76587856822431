import { ReactComponent as MyProfileIcon } from 'assets/icons/my-profile.svg'
import { ReactComponent as MyProfileIconActive } from 'assets/icons/my-profile-active.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as SettingsIconActive } from 'assets/icons/settings-active.svg'

import { Routes } from 'global/routes'
import { PROFILE_ROUTES } from 'components/DashboardWrapper/constant'

const iconLibrary = {
  [Routes.MY_PROFILE]: {
    inactive: MyProfileIcon,
    active: MyProfileIconActive
  },
  [Routes.SETTINGS]: {
    inactive: SettingsIcon,
    active: SettingsIconActive
  }
}

function withIcon(data) {
  return data.map(item => ({
    ...item,
    icon: {
      inactive: iconLibrary[item.to].inactive,
      active: iconLibrary[item.to].active
    }
  }))
}

export default withIcon(
  PROFILE_ROUTES.filter(({ title }) => title !== 'Logout')
)
