import React from 'react'
import PropTypes from 'prop-types'

import { $primaryLight, $white } from 'utils/helpers/colors'

import styles from './styles.module.scss'

const ThumbnailPlaceholder = ({ className, initial, size }) => {
  return (
    <div
      className={`${styles.thumbnailPlaceholder} ${
        className || ''
      }`}
      style={{
        backgroundColor: $primaryLight,
        maxHeight: size,
        maxWidth: size,
        width: size,
        height: size,
        fontSize: `${size * 0.45}px`,
        color: $white
      }}
    >
      {initial}
    </div>
  )
}

ThumbnailPlaceholder.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.string
}

export default ThumbnailPlaceholder
