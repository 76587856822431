import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Plain from './Plain'
import Edit from './Edit'

const DevPlanCard = ({ actionSteps, title, planId, date }) => {
  const [editMode, setEditMode] = useState(false)
  const sortedSteps = actionSteps.sort((a, b) => a.id - b.id)

  const toggleEditMode = () => setEditMode(!editMode)
  return editMode ? (
    <Edit
      planId={planId}
      actionSteps={sortedSteps}
      title={title}
      toggleEditMode={toggleEditMode}
    />
  ) : (
    <Plain
      planId={planId}
      actionSteps={sortedSteps}
      title={title}
      toggleEditMode={toggleEditMode}
      date={date}
    />
  )
}

DevPlanCard.propTypes = {
  planId: PropTypes.number,
  actionSteps: PropTypes.array,
  title: PropTypes.string
}

export default DevPlanCard
