/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { head } from 'lodash'

import { getFileLink } from 'utils/helpers/file'

import Button from 'components/Button'
import Modal from 'components/Modal'
import MultiSelect from 'components/Form/MultiSelect'
import ThumbnailPlaceholder from 'components/ThumbnailPlaceholder'

import styles from './styles.module.scss'

const RecommendModal = ({
  show,
  toggleModal,
  clients,
  onConfirm,
  recomendedResources
}) => {
  const [selected, setSelected] = useState([])
  useMemo(() => {
    const temp =
      recomendedResources?.map(client => ({
        label: `${client?.User?.firstName} ${client?.User?.lastName}`,
        value: client.userId,
        initial: head(client?.User?.firstName),
        profileImage: client?.User?.profileImage
      })) || []
    setSelected(temp)
  }, [recomendedResources])

  const options =
    clients?.map(client => ({
      label: `${client.firstName} ${client.lastName}`,
      value: client.id,
      initial: head(client.firstName),
      profileImage: client.profileImage
    })) || []

  const optionItemRenderer = ({
    checked,
    disabled,
    onClick,
    option
  }) => {
    const { label, profileImage, initial } = option

    return (
      <div
        className={`item-renderer ${
          disabled && 'disabled'
        } d-flex align-items-center`}
      >
        {profileImage ? (
          <Image
            roundedCircle
            src={getFileLink(profileImage)}
            className={styles.clientImage}
          />
        ) : initial ? (
          <ThumbnailPlaceholder initial={initial} />
        ) : (
          <div
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
        )}
        <span className="mx-10">{label}</span>
        <input
          className="float-right"
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
      </div>
    )
  }

  async function handleRecommendationSubmit() {
    await onConfirm(selected)
    await toggleModal()

    setSelected([])
  }

  return (
    <Modal show={show} closeModal={toggleModal}>
      <Row>
        <Col xs={12}>
          <div className="d-flex align-items-center mb-10">
            <Col xs={12}>
              <p className={styles.modalTitle}>
                Recommend Content
              </p>
            </Col>
          </div>
          <div className="d-flex align-items-center mb-20">
            <Col xs={12}>
              {selected?.map(({ profileImage, initial }) =>
                profileImage ? (
                  <Image
                    roundedCircle
                    src={getFileLink(profileImage)}
                    className={styles.clientImage}
                  />
                ) : (
                  <ThumbnailPlaceholder initial={initial} />
                )
              )}
            </Col>
          </div>
          <div className="d-flex align-items-center mb-30">
            <Col xs={12}>
              <MultiSelect
                value={selected}
                labelledBy="Select Client/s"
                options={options}
                ItemRenderer={optionItemRenderer}
                onChange={setSelected}
              />
            </Col>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="link"
              smallPadding
              onClick={toggleModal}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              smallPadding
              type="submit"
              onClick={handleRecommendationSubmit}
            >
              Send File
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default RecommendModal
