import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const Card = ({
  children,
  className,
  outlined,
  transparent,
  ...rest
}) => {
  const classes = [`${styles.card}`]
  if (className) classes.push(className)
  if (transparent) classes.push(`${styles.transparent}`)
  if (outlined) classes.push(`${styles.outlined}`)

  return (
    <div className={classes.join(' ')} {...rest}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  outlined: PropTypes.bool
}

export default Card
