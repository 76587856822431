export const STAKEHOLDER_ROUTES = [
  {
    title: 'Stakeholders',
    to: '/dashboard/stakeholder-engagement'
  },
  {
    title: 'Monthly Check-ins',
    to: '/dashboard/stakeholder-engagement/monthly-checkins'
  },
  {
    title: 'Progress Check',
    to: '/dashboard/stakeholder-engagement/progress-check'
  }
]

export const GET_STAKEHOLDER_ENGAGEMENT =
  'pages/member/dashboard/stakeholderEngagement/getStakeholderEngagement'

export const EDIT_STAKEHOLDER_ENGAGEMENT =
  'pages/member/dashboard/stakeholderEngagement/editStakeholderEngagement'

export const DEFAULT_STEPS = [
  {
    id: 1,
    step: 'Add your Stakeholders',
    status: false
  },
  {
    id: 2,
    step:
      'Meet with each colleague to ‘enroll’ them as a stakeholder',
    status: false
  },
  {
    id: 3,
    step: 'Add your monthly check-ins with each stakeholder',
    status: false
  },
  {
    id: 4,
    step:
      'Take part in a coaching session to review your survey results',
    status: false
  }
]
