import React from 'react'
import {
  ButtonGroup,
  Dropdown as BSDropdown
} from 'react-bootstrap'

import styles from './styles.module.scss'

const CustomToggle = React.forwardRef(
  ({ children, onClick }, ref) => (
    <span
      style={{ cursor: 'pointer' }}
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </span>
  )
)

const Dropdown = ({ label, menu }) => {
  return (
    <BSDropdown as={ButtonGroup}>
      <BSDropdown.Toggle as={CustomToggle}>
        {label}
      </BSDropdown.Toggle>
      <BSDropdown.Menu className={styles.dropdown}>
        {menu?.map((item, index) => (
          <BSDropdown.Item
            {...(item?.href ? { href: item.href } : {})}
            key={index}
            className={styles.dropdownItem}
          >
            {item?.label || item?.component}
          </BSDropdown.Item>
        ))}
      </BSDropdown.Menu>
    </BSDropdown>
  )
}

export default Dropdown
