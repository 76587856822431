import React, { useEffect } from 'react'
import * as yup from 'yup'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import BottomRightPeakLogo from 'assets/images/peak-logo-bottom-right.png'
import PeakLogo from 'assets/images/peak-logo-black.png'

import { useQueryParams } from 'utils/html/queryParams'

import Button from 'components/Button'
import FormControl from 'components/Form/FormControl'
import Container from 'components/Container'
import H1 from 'components/H1'
import H2 from 'components/H2'
import PageLoader from 'components/PageLoader'

import styles from './styles.module.scss'
import { useChangePassword } from './hooks'

const changePwFormSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})

const SendEmail = () => {
  const history = useHistory()
  const queryParams = useQueryParams()

  const {
    changePw,
    isSuccess: changePwSuccess,
    isLoading: changePwLoading
  } = useChangePassword()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(changePwFormSchema)
  })

  const token = queryParams.get('token')

  const sendMailFormSubmit = data => {
    const { password, confirmPassword } = data
    const payload = {
      token,
      password,
      confirmPassword
    }
    changePw(payload)
  }

  useEffect(() => {
    if (changePwSuccess) {
      history.push('/')
    }
  }, [changePwSuccess, history])

  return (
    <PageLoader>
      <Container className={styles.sendMailWrapper}>
        <Row className={styles.row}>
          <Col xs="7-5" className={styles.leftWrapper}>
            <div className={styles.contentWrapper}>
              <Image src={PeakLogo} className="mb-15" />
              <H1 className="text-primary font-weight-normal mb-40">
                Leadership Development Portal
              </H1>
              <H2 className="mb-20">Change Password</H2>
              <Form
                className={styles.form}
                onSubmit={handleSubmit(sendMailFormSubmit)}
              >
                <FormControl
                  inputRef={register}
                  type="password"
                  name="password"
                  placeholder="Password"
                  error={errors.password}
                />
                <FormControl
                  inputRef={register}
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  error={errors.confirmPassword}
                />
                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    type="submit"
                    variant="secondary"
                    className="text-white"
                  >
                    {changePwLoading
                      ? 'Loading'
                      : 'Change Password'}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col
            xs="4-5"
            className={`${styles.rightWrapper} bg-primary-dark text-white`}
          >
            <Image
              src={BottomRightPeakLogo}
              className={styles.bottomRightImage}
            />
            <div className={styles.contentWrapper}>
              <H1 className="mb-10">
                Let&apos;s start your journey
              </H1>
              <div className={styles.divider} />
              <p>
                Leadership Effectiveness Drives Every Measure of
                Organizational Success
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLoader>
  )
}

SendEmail.propTypes = {}

export default SendEmail
