import React from 'react'
import PropTypes from 'prop-types'
import { Toast as BSToast } from 'react-bootstrap'

import { ReactComponent as IconClose } from 'assets/icons/delete-modal.svg'

import { $danger, $primary, $white } from 'utils/helpers/colors'

import TouchableOpacity from 'components/TouchableOpacity'

import {
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  DANGER,
  PRIMARY,
  TOP_LEFT,
  TOP_RIGHT
} from './constants'

const Toast = ({
  children,
  show,
  title,
  titleClass,
  toggle,
  position = 'bottom-right',
  variant,
  ...rest
}) => {
  const buildStyle = () => {
    let defaultStyle = {
      position: 'fixed',
      padding: 10,
      opacity: 0.7,
      minWidth: 200
    }

    if (position === BOTTOM_RIGHT) {
      defaultStyle = { ...defaultStyle, bottom: 10, right: 10 }
    } else if (position === TOP_RIGHT) {
      defaultStyle = { ...defaultStyle, top: 10, right: 10 }
    } else if (position === BOTTOM_LEFT) {
      defaultStyle = { ...defaultStyle, bottom: 10, left: 10 }
    } else if (position === TOP_LEFT) {
      defaultStyle = { ...defaultStyle, top: 10, left: 10 }
    }

    if (variant === PRIMARY) {
      defaultStyle = {
        ...defaultStyle,
        backgroundColor: $primary
      }
    } else if (variant === DANGER) {
      defaultStyle = {
        ...defaultStyle,
        backgroundColor: $danger,
        color: $white
      }
    }

    return defaultStyle
  }
  return (
    <BSToast
      show={show}
      onClose={toggle}
      style={buildStyle()}
      {...rest}
    >
      <BSToast.Body>
        <div className="d-flex justify-content-between mb-10">
          <div className={titleClass} style={{ fontSize: 16 }}>
            {title}
          </div>
          <TouchableOpacity onClick={toggle}>
            <IconClose />
          </TouchableOpacity>
        </div>
        {children}
      </BSToast.Body>
    </BSToast>
  )
}

Toast.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  toggle: PropTypes.func,
  position: PropTypes.string,
  variant: PropTypes.string
}

export default Toast
