export const ROUTES = [
  {
    title: 'Activity Feed',
    to: '/coach/activity-feed'
  },
  {
    title: 'User Management',
    to: '/coach/user-management'
  },
  {
    title: 'Workshops',
    to: '/coach/workshops'
  },
  {
    title: 'Resource Library',
    to: '/coach/resource-library'
  }
]
