import React from 'react'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import DesktopTable from './DesktopTable'
import MobileTable from './MobileTable'

const CoachingDocuments = ({
  data,
  isLoading,
  onEdit,
  onDelete
}) => {
  const { isAtLeastMediumScreen } = useDeviceSize()

  return isAtLeastMediumScreen ? (
    <DesktopTable
      data={data}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ) : (
    <MobileTable
      data={data}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  )
}

export default CoachingDocuments
