import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import { useDeviceSize } from 'utils/hooks/useDeviceSize'
import Plain from './Plain'
import Edit from './Edit'

const DevPlanCard = ({ actionSteps, date, title, planId }) => {
  const [editMode, setEditMode] = useState(false)
  const { isAtLeastSmallScreen } = useDeviceSize()
  const sortedSteps = actionSteps.sort((a, b) => a.id - b.id)
  const toggleEditMode = () => setEditMode(!editMode)

  const props = {
    planId,
    actionSteps: sortedSteps,
    title,
    toggleEditMode,
    date
  }

  if (isAtLeastSmallScreen)
    return editMode ? <Edit {...props} /> : <Plain {...props} />

  return (
    <>
      <Plain {...props} />
      <Modal
        show={editMode}
        closeModal={toggleEditMode}
        mimicNative
        title="Edit Goal"
      >
        <Edit {...props} />
      </Modal>
    </>
  )
}

DevPlanCard.propTypes = {
  planId: PropTypes.number,
  actionSteps: PropTypes.array,
  title: PropTypes.string
}

export default DevPlanCard
