import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import ImageProfilePlaceholder from 'assets/images/profile-placeholder.png'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'

import Card from 'components/Card'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'
import TouchableOpacity from 'components/TouchableOpacity'
import Upload from 'components/Upload'

import useMutationSuccess from 'utils/hooks/useMutationSuccess'
import { getFileLink } from 'utils/helpers/file'

import ErrorBanner from 'components/ErrorBanner'
import styles from './styles.module.scss'
import { useProfile } from './hooks'
import { resolver } from './profileSchema'

const PersonalInformation = () => {
  const uploadRef = useRef(null)
  const [isDirty, setIsDirty] = useState(false)
  const [uploadedData, setUploadedData] = useState({
    touched: false,
    file: null,
    fileURL: null
  })

  const {
    data,
    editProfile,
    editProfileSuccess,
    editProfileLoading,
    editProfileError
  } = useProfile()

  const profileUrl = data?.profileImage
    ? getFileLink(data?.profileImage)
    : ImageProfilePlaceholder

  const [SuccessToast] = useMutationSuccess(editProfileSuccess)
  const {
    register,
    formState,
    reset,
    handleSubmit,
    errors
  } = useForm({ resolver })

  const formSubmit = fields => {
    if (!isDirty) return null

    const { file, touched } = uploadedData

    return editProfile({
      payload: fields,
      changeProfileImage: touched,
      newProfileImage: file
    })
  }

  const onImageUpload = e => {
    if (e.target.files.length) {
      const file = e.target.files[0]
      return setUploadedData({
        file,
        touched: true,
        fileURL: URL.createObjectURL(file)
      })
    }
    return setUploadedData({
      file: null,
      touched: true,
      fileURL: null
    })
  }

  useEffect(() => {
    if (!isDirty) {
      setIsDirty(formState.isDirty)
    }
    if (uploadedData.touched) {
      setIsDirty(true)
    }
  }, [formState, isDirty, uploadedData])

  useEffect(() => {
    if (data) {
      reset({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      })
    }
  }, [data, reset])

  return (
    <>
      <div>
        <Card>
          {data ? (
            <Form onSubmit={handleSubmit(formSubmit)}>
              <fieldset disabled={editProfileLoading}>
                <Col className="p-0 px-lg-20">
                  <Row>
                    <Col xs={12} lg={{ span: 6, order: 2 }}>
                      <div
                        className={`${styles.profilePictureWrapper} mx-auto mb-30 ml-lg-0`}
                      >
                        <Upload
                          id="image-upload"
                          inputRef={uploadRef}
                          onFileChange={onImageUpload}
                        />
                        <Image
                          src={
                            uploadedData.touched
                              ? uploadedData.fileURL ||
                                ImageProfilePlaceholder
                              : profileUrl
                          }
                          className={styles.profilePicture}
                        />
                        <TouchableOpacity
                          className={styles.editProfile}
                          onClick={() =>
                            uploadRef?.current.click()
                          }
                        >
                          <IconCamera />
                        </TouchableOpacity>
                      </div>
                    </Col>
                    <Col xs={12} lg={6}>
                      <ErrorBanner
                        errorMessage={editProfileError}
                      />
                      <Row className="d-flex align-items-center mb-10">
                        <Col
                          xs={12}
                          lg={4}
                          className="text-gray-dark mb-5 mb-lg-0"
                        >
                          First Name
                        </Col>
                        <Col xs={12} lg={8}>
                          <FormControl
                            name="firstName"
                            inputRef={register}
                            formGroupClass="mb-0"
                            placeholder="First Name"
                            error={errors.firstName}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mb-10">
                        <Col
                          xs={12}
                          lg={4}
                          className="text-gray-dark mb-5 mb-lg-0"
                        >
                          Last Name
                        </Col>
                        <Col xs={12} lg={8}>
                          <FormControl
                            name="lastName"
                            inputRef={register}
                            formGroupClass="mb-0"
                            placeholder="Last Name"
                            error={errors.lastName}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mb-20">
                        <Col
                          xs={12}
                          lg={4}
                          className="text-gray-dark mb-5 mb-lg-0"
                        >
                          Email
                        </Col>
                        <Col xs={12} lg={8}>
                          <FormControl
                            name="email"
                            inputRef={register}
                            formGroupClass="mb-0"
                            placeholder="Email"
                            error={errors.email}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mb-20">
                        <Col
                          xs={6}
                          lg={4}
                          className="text-gray-dark"
                        >
                          Company
                        </Col>
                        <Col
                          xs={6}
                          lg={8}
                          className="text-right text-lg-left"
                        >
                          {data.company || 'N/A'}
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mb-lg-20">
                        <Col
                          xs={4}
                          className="d-none d-lg-block"
                        />
                        <Col xs={12} lg={8}>
                          <Button
                            smallPadding
                            variant="secondary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Save Changes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </fieldset>
            </Form>
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </div>
      <SuccessToast message="Profile has been saved!" />
    </>
  )
}

PersonalInformation.propTypes = {}

export default PersonalInformation
