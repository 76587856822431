export const MEMBER_ROLE = 'member'
export const COACH_ROLE = 'coach'

export const REMINDER_DEFAULT_SETTING = {
  email: false,
  push: false,
  development: false,
  actionSteps: false,
  stakeholdersCheckin: false,
  clientAddsGoal: false,
  clientAddsStakeholder: false,
  clientAddsCheckin: false,
  clientAddsOrUpdateCoachingSession: false
}
