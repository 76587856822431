import React from 'react'
import { Col } from 'react-bootstrap'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import FormControl from 'components/Form/FormControl'

const CollapseHeader = ({
  companies,
  inputErrors,
  inputRegister
}) => {
  return (
    <>
      <div className="d-flex flex-column flex-md-row w-100">
        <Col xs={6} className="font-weight-medium pl-0">
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.title}
            type="text"
            name="title"
            as="textarea"
            rows={1}
            formGroupClass="mb-0"
            placeholder="Workshop Title"
          />
        </Col>
        <Col xs={3} className="d-flex justify-content-end">
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.company}
            name="company"
            as="select"
            formGroupClass="mb-0 flex-1"
          >
            <option value={null}>Select Company</option>
            {companies?.map(company => (
              <option value={company.id}>{company.name}</option>
            ))}
          </FormControl>
        </Col>
        <Col xs={3} className="font-weight-medium pr-0">
          <FormControl
            inputRef={inputRegister}
            error={inputErrors.date}
            name="date"
            as="datepicker"
            formGroupClass="mb-0"
            icon={<IconCalendar />}
            placeholder="Session date"
          />
        </Col>
      </div>
    </>
  )
}

export default CollapseHeader
