import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import { useUserManagement } from 'global/hooks/useUserManagement'

import useHeaderTitle from 'utils/hooks/useHeaderTitle'

import DashboardWrapper from 'components/DashboardWrapper'
import SimpleTopNavbar from 'components/SimpleTopNavbar'

import SingleUser from './SingleUser'

import Clients from './AllUsers/Clients'
import Coaches from './AllUsers/Coaches'

const UserManagement = () => {
  useHeaderTitle('User Management')
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  const {
    allClients,
    allCoaches,
    editUserCompany,
    giveUserAccess,
    removeUserAccess,
    inviteUser,
    inviteUserError,
    setInviteUserError,
    deleteUser
  } = useUserManagement()

  const CLIENT_URL = `${path}/clients`
  const COACHES_URL = `${path}/coaches`
  const SINGLE_CLIENT_URL = `${path}/clients/:userId`

  const USER_MANAGEMENT_ROUTES = [
    {
      title: 'Clients',
      to: CLIENT_URL
    },
    {
      title: 'Coaches',
      to: COACHES_URL
    }
  ]

  if (pathname === path) {
    return <Redirect to={CLIENT_URL} />
  }

  return (
    <Switch>
      <Route exact path={CLIENT_URL}>
        <DashboardWrapper>
          <div className="mb-20">
            <SimpleTopNavbar routes={USER_MANAGEMENT_ROUTES} />
          </div>
          <Clients
            data={allClients}
            editUserCompany={editUserCompany}
            giveUserAccess={giveUserAccess}
            removeUserAccess={removeUserAccess}
            inviteUser={inviteUser}
            inviteUserError={inviteUserError}
            setInviteUserError={setInviteUserError}
            deleteUser={deleteUser}
          />
        </DashboardWrapper>
      </Route>
      <Route exact path={COACHES_URL}>
        <DashboardWrapper>
          <div className="mb-20">
            <SimpleTopNavbar routes={USER_MANAGEMENT_ROUTES} />
          </div>
          <Coaches
            data={allCoaches}
            inviteUser={inviteUser}
            inviteUserError={inviteUserError}
            setInviteUserError={setInviteUserError}
            giveUserAccess={giveUserAccess}
            removeUserAccess={removeUserAccess}
            deleteUser={deleteUser}
          />
        </DashboardWrapper>
      </Route>
      <Route path={SINGLE_CLIENT_URL}>
        <SingleUser />
      </Route>
    </Switch>
  )
}

UserManagement.propTypes = {}

export default UserManagement
