import React from 'react'

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'

import { openLink } from 'utils/html/window'

import TouchableOpacity from 'components/TouchableOpacity'

import tableStyles from 'global/table.module.scss'

const MobileTable = ({ data, onEdit, onDelete }) => {
  return (
    <ul className={tableStyles.mobileTable}>
      {data?.map(document => {
        return (
          <li>
            <div className="d-flex align-items-center mb-10">
              <div className="d-flex flex-column overflow-hidden">
                <p className={tableStyles.name}>
                  {document.title}
                </p>
                <TouchableOpacity
                  onClick={() =>
                    openLink({
                      url: document.url,
                      isExternalLink: true,
                      newTab: true
                    })
                  }
                  className={tableStyles.link}
                >
                  {document.url}
                </TouchableOpacity>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between text-gray-dark">
              <span>Date Added: {document.date}</span>
              <span className="d-flex justify-content-center">
                <TouchableOpacity
                  className="mr-10"
                  onClick={() => onEdit(document)}
                >
                  <IconEdit />
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => onDelete(document)}
                >
                  <IconDelete />
                </TouchableOpacity>
              </span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default MobileTable
