import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { find, includes, isNil } from 'lodash'
import { useParams } from 'react-router-dom'

import IconDelete from 'assets/icons/delete.svg'
import IconBullet from 'assets/icons/bullet-point.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'

import Card from 'components/Card'
import TouchableOpacity from 'components/TouchableOpacity'
import FormControl from 'components/Form/FormControl'
import Button from 'components/Button'

import memberStyles from 'pages/Member/styles.module.scss'

import styles from '../styles.module.scss'
import { goalSchema } from '../constants'
import { useDevPlan } from '../hooks'

const Edit = ({
  planId,
  actionSteps,
  title,
  toggleEditMode
}) => {
  const { userId } = useParams()
  const [mutableActionSteps, setMutableActionSteps] = useState(
    actionSteps.map(step => ({ ...step }))
  )
  const [actionStepError, setActionStepError] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(goalSchema)
  })
  const { editPlanGoal } = useDevPlan(userId)

  const addActionStep = () => {
    setActionStepError(null)
    const lastStepId = mutableActionSteps.length
      ? mutableActionSteps[mutableActionSteps.length - 1].id
      : 0
    setMutableActionSteps([
      ...mutableActionSteps,
      { id: lastStepId + 1, description: '' }
    ])
  }
  const removeActionStep = id => {
    const hasOneActionStep = mutableActionSteps?.length === 1

    if (!hasOneActionStep) {
      const output = mutableActionSteps.filter(
        step => step.id !== id
      )
      setMutableActionSteps(output)
    } else {
      setActionStepError('At least one action step is required')
    }
  }
  const actionStepOnChange = (id, newValue) => {
    const index = mutableActionSteps.findIndex(
      step => step.id === id
    )
    mutableActionSteps[index].description = newValue
  }

  const validateData = async (addSteps, editedSteps) => {
    let type = 'empty'
    let addedValid = true

    if (addSteps.length) {
      if (
        includes(
          addSteps.map(addStep => !!addStep.description.length),
          false
        )
      )
        addedValid = false
      else if (
        includes(
          addSteps.map(
            addStep => addStep.description.length < 295
          ),
          false
        )
      ) {
        addedValid = false
        type = 'overflow'
      }
    }
    let editedValid = true
    if (editedSteps.length) {
      if (
        includes(
          editedSteps.map(
            editedStep => !!editedStep.description.length
          ),
          false
        )
      )
        editedValid = false
      else if (
        includes(
          editedSteps.map(
            editedStep => editedStep.description.length < 295
          ),
          false
        )
      ) {
        editedValid = false
        type = 'overflow'
      }
    }

    return { isValid: addedValid && editedValid, type }
  }

  const saveChanges = async ({ title }) => {
    const addedSteps = []
    const editedSteps = []
    const deletedSteps = []

    // FIND ADDED AND EDITED
    const findAddedAndEdited = mutableActionSteps.forEach(
      mutedStep => {
        const originalStep = find(
          actionSteps,
          step => step.id === mutedStep.id
        )
        const isAdded = isNil(originalStep)
        if (isAdded) {
          return addedSteps.push(mutedStep)
        }
        if (mutedStep.description !== originalStep.description) {
          return editedSteps.push(mutedStep)
        }
        return null
      }
    )
    // FIND DELETED
    const findDeleted = actionSteps.forEach(originalStep => {
      const mutedSteps = find(
        mutableActionSteps,
        step => step.id === originalStep.id
      )
      const isDeleted = isNil(mutedSteps)
      if (isDeleted) {
        return deletedSteps.push(originalStep)
      }
      return null
    })

    await Promise.all([findAddedAndEdited, findDeleted])

    const { isValid, type } = await validateData(
      addedSteps,
      editedSteps
    )
    if (isValid) {
      editPlanGoal({
        userId,
        planId,
        payload: {
          title,
          addedSteps,
          editedSteps,
          deletedSteps
        }
      })
      setActionStepError(null)
      toggleEditMode()
    } else if (type === 'empty') {
      setActionStepError(
        'All action steps require a description'
      )
    } else if (type === 'overflow') {
      setActionStepError(
        'Entry is above character maximum, please reduce the length before saving'
      )
    }
  }

  return (
    <Card className={`${styles.devPlanCard} mb-20`}>
      <Row>
        <Col xs={9}>
          <FormControl
            defaultValue={title}
            placeholder="Plan title"
            as="textarea"
            rows="1"
            name="title"
            formGroupClass="mb-8"
            inputRef={register}
            error={errors.title}
          />
        </Col>
        <Col xs={3}>
          <FormControl
            placeholder="Plan date"
            formGroupClass="mb-8"
            as="datepicker"
            disabled
            icon={<IconCalendar />}
          />
        </Col>
      </Row>
      <div className="mb-20">
        <Col xs={12} className={memberStyles.dividerLine} />
      </div>
      <div className="d-flex justify-content-between mb-10">
        <div className="font-weight-medium text-primary">
          Action items:
        </div>
        <TouchableOpacity
          onClick={addActionStep}
          className="text-secondary"
        >
          + Add action item
        </TouchableOpacity>
      </div>
      <div className="mb-30">
        {mutableActionSteps?.map(step => (
          <Row
            key={`edit-plan-action-${step.id}`}
            className="mb-10"
          >
            <Col xs={12} className="d-flex">
              <Image src={IconBullet} className="mr-10" />
              <FormControl
                placeholder="Action step description"
                formGroupClass={`${styles.actionItemInput} mb-0 mr-20`}
                as="textarea"
                rows="1"
                defaultValue={step.description}
                onChange={e =>
                  actionStepOnChange(step.id, e.target.value)
                }
              />
              <TouchableOpacity
                onClick={() => removeActionStep(step.id)}
                className="d-flex align-items-center"
              >
                <Image src={IconDelete} />
              </TouchableOpacity>
            </Col>
          </Row>
        ))}
        {actionStepError && (
          <small className="text-danger">
            {actionStepError}
          </small>
        )}
      </div>
      <div className="d-flex justify-content-end mb-20">
        <Button
          variant="link"
          smallPadding
          onClick={toggleEditMode}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          smallPadding
          onClick={handleSubmit(saveChanges)}
        >
          Save Changes
        </Button>
      </div>
    </Card>
  )
}

Edit.propTypes = {
  actionSteps: PropTypes.array,
  planId: PropTypes.number,
  title: PropTypes.string,
  toggleEditMode: PropTypes.func
}

export default Edit
