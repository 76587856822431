import { ReactComponent as IconPdf } from 'assets/icons/pdf.svg'
import { ReactComponent as IconDoc } from 'assets/icons/docs.svg'

import { switchFn } from './index'

export const fileTypes = {
  PDF: 'pdf',
  DOC: 'doc',
  DOCX: 'docx'
}

export const getFileIcon = fileName => {
  const splittedFileName = fileName.split('.')
  const fileExtension =
    splittedFileName[splittedFileName.length - 1]

  const icon = switchFn({
    [fileTypes.PDF]: IconPdf,
    [fileTypes.DOC]: IconDoc,
    [fileTypes.DOCX]: IconDoc
  })(null)(fileExtension)

  return icon
}
