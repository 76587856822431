export const $primaryLight = '#2974c1'
export const $primary = '#195477'
export const $primaryDark = '#0f3e5c'
export const $bluishGray = '#d8dde8'
export const $danger = '#f00'
export const $secondary = '#f88801'
export const $gray = '#f8f8fa'
export const $grayDark = '#9c9c9c'
export const $white = '#ffffff'
export const $black = '#333333'
export const $solidBlack = '#000000'

// Gradients component should be used for these colors to work
export const $gradientBlue = 'url(#blue)'
export const $gradientOrange = 'url(#orange)'
export const $gradientGreen = 'url(#green)'
export const $gradientPurple = 'url(#purple)'
export const $gradientYellow = 'url(#yellow)'
