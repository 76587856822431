import React, { useState } from 'react'

import { ReactComponent as ImageEmptyStakeholders } from 'assets/images/stakeholders-empty.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg'

import { useDeviceSize } from 'utils/hooks/useDeviceSize'

import AddButton from 'components/Button/AddButton'
import Card from 'components/Card'
import ConfirmModal from 'components/ConfirmModal'

import { useStakeholderEngagement } from '../hooks'

import MobileTable from './MobileTable'
import DesktopTable from './DesktopTable'

const Stakeholders = ({ setShowAddStakeholderModal }) => {
  const { isAtLeastLargeScreen } = useDeviceSize()
  const {
    data,
    deleteStakeholder,
    editStakeholder
  } = useStakeholderEngagement()

  const [showDelete, setShowDelete] = useState(false)
  const [selectedId, setSelectedId] = useState(false)

  const toggleModal = setter => {
    return () => setter(show => !show)
  }

  const handleDeleteStakeholder = id => {
    setShowDelete(true)
    setSelectedId(id)
  }

  const deleteHandler = () => {
    deleteStakeholder(selectedId)
  }

  const handleToggleEnrolled = (stakeholderId, payload) => {
    editStakeholder({
      stakeholderId,
      payload
    })
  }

  const { stakeholderProfiles = [] } = data || {}

  if (!stakeholderProfiles.length) {
    return (
      <Card
        className="d-flex align-items-center justify-content-center flex-column"
        style={{ minHeight: 350 }}
      >
        <ImageEmptyStakeholders className="mb-20" />
        <div className="text-gray-dark text-center mb-20">
          Stakeholders are colleagues who you will share your
          goals with, get their suggestions, and check-in with
          them on your progress monthly.
        </div>
        <AddButton
          label="Stakeholder"
          onClick={() => setShowAddStakeholderModal(true)}
        />
      </Card>
    )
  }

  return (
    <>
      {isAtLeastLargeScreen ? (
        <DesktopTable
          data={stakeholderProfiles}
          handleDeleteStakeholder={handleDeleteStakeholder}
          handleToggleEnrolled={handleToggleEnrolled}
        />
      ) : (
        <MobileTable
          data={stakeholderProfiles}
          handleDeleteStakeholder={handleDeleteStakeholder}
          handleToggleEnrolled={handleToggleEnrolled}
        />
      )}
      <ConfirmModal
        icon={<TrashIcon width={77} />}
        show={showDelete}
        confirmText="Delete"
        cancelText="Cancel"
        toggleModal={toggleModal(setShowDelete)}
        title="Are you sure you want to delete this Stakeholder?"
        description="Deleting a stakeholder also removes any monthly check-ins or survey responses from them."
        onConfirm={deleteHandler}
      />
    </>
  )
}
export default Stakeholders
