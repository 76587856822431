import React from 'react'

import memberStyles from 'pages/Member/styles.module.scss'

const DashboardWrapper = ({ children, className }) => {
  const classes = [`${memberStyles.mainContent}`]
  if (className) classes.push(className)

  return <div className={classes.join(' ')}>{children}</div>
}

export default DashboardWrapper
